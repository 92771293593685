import {
  SET_ORGANIZATION_PENDING_SELECTION,
  SET_SELECTED_ORGANIZATION,
} from "../../constants";
import { Action } from "../commonTypes";

export type SelectedOrganization = {
  label: string;
  value: string;
  timezone: string;
  level?: number;
  index?: number;
};

export type SelectedOrganizationReducerInitialState = {
  selectedOrganization: SelectedOrganization;
  organizationPendingSelection: SelectedOrganization;
};

export type SelectedOrganizationReducerType = (
  state: SelectedOrganizationReducerInitialState | undefined,
  action: Action
) => SelectedOrganizationReducerInitialState;

export const initialSelectedOrganizationState: SelectedOrganizationReducerInitialState =
  {
    selectedOrganization: { label: "", value: "", timezone: "" },
    organizationPendingSelection: { label: "", value: "", timezone: "" },
  };

export const setSelectedOrganization = (
  dispatch: React.Dispatch<Action>,
  organization: SelectedOrganization
) => {
  dispatch({
    type: SET_SELECTED_ORGANIZATION,
    payload: organization,
  });
};

export const setOrganizationPendingSelection = (
  dispatch: React.Dispatch<Action>,
  organization: SelectedOrganization
) => {
  dispatch({
    type: SET_ORGANIZATION_PENDING_SELECTION,
    payload: organization,
  });
};

export const resetOrganizationPendingSelection = (
  dispatch: React.Dispatch<Action>
) => {
  dispatch({
    type: SET_ORGANIZATION_PENDING_SELECTION,
    payload: initialSelectedOrganizationState.organizationPendingSelection,
  });
};

export const SelectedOrganizationReducer = (
  state = initialSelectedOrganizationState,
  action: Action
) => {
  switch (action.type) {
    case SET_SELECTED_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    case SET_ORGANIZATION_PENDING_SELECTION:
      return {
        ...state,
        organizationPendingSelection: action.payload,
      };
    default:
      return state;
  }
};
