export const DEVICE_CREATE_SUCCESS_PAYLOAD = {
  title: "Device Created Successfully!",
  text: "You can find the device in the table",
  severity: "success",
};

export const DEVICE_CREATE_FAILED_PAYLOAD = {
  title: "Device Creation Failed",
  text: "Something Went Wrong.",
  severity: "error",
};

export const DEVICE_UPDATE_SUCCESS_PAYLOAD = {
  title: "Device Updated Successfully!",
  text: "You can find the updated device in the table",
  severity: "success",
};

export const DEVICE_UPDATE_FAILED_PAYLOAD = {
  title: "Device Update Failed",
  text: "Something Went Wrong.",
  severity: "error",
};

export const DEVICE_LOAD_FAILED_PAYLOAD = {
  title: "Devices load failed!",
  text: "Something Went Wrong.",
  severity: "error",
};

export const DEVICE_EVENT_LOAD_FAILED_PAYLOAD = {
  title: "Devices event load failed!",
  text: "Something Went Wrong.",
  severity: "error",
};

export const DEVICE_DELETE_SUCCESS_PAYLOAD = {
  title: "Success!",
  text: "Device has been deactivated successfully!",
  severity: "success",
};

export const DEVICE_DELETE_FAILED_PAYLOAD = {
  title: "Device Deletion Failed",
  text: "Something Went Wrong.",
  severity: "error",
};

export const DEVICE_FORM_NO_CHANGES = {
  title: "No changes",
  text: "Please update data before submit",
  severity: "error",
};

export interface DeviceFormFieldType {
  name: string;
  id: string;
  label: string;
  type: string;
  dataType?: string;
  options?: any[]; // Consider making this more specific if possible
  optionsLimit?: number;
  required?: boolean;
}

export const DEVICE_FORM_FIELDS = {
  customerOrgIdInput: {
    name: "customer_orgs_id",
    testId: "customer_orgs_id",
    id: "customer_orgs_id",
    label: "Organization Name",
    type: "autocomplete",
    options: [],
    required: true,
  },
  imeiInput: {
    name: "imei",
    id: "imei",
    testId: "device-imei",
    label: "Device ID",
    type: "text",
    dataType: "text",
    required: true,
  },
  assetIdInput: {
    name: "assets_id",
    id: "assets_id",
    testId: "assets_id",
    label: "Asset ID",
    type: "autocomplete",
    options: [],
    optionsLimit: 500,
  },
  boxIdInput: {
    name: "box_id",
    id: "box_id",
    label: "Order Group",
    type: "text",
  },
  tagsInput: {
    name: "tags",
    id: "tags",
    label: "Device Tags",
    type: "creatableautocomplete",
    options: [],
  },
  orderNumInput: {
    name: "order_num",
    id: "order_num",
    label: "Order #",
    type: "text",
    required: true,
  },
  simNumInput: {
    name: "sim_num",
    id: "sim_num",
    label: "SIM ID",
    type: "text",
  },
  packingListInput: {
    name: "packing_list",
    id: "packing_list",
    label: "Packing List",
    type: "text",
  },
};
