import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { AssetOs } from "../../../../../../../../graphql/operations";
import { parseTPMSSummary } from "../../../helpers";
import { DualImbalanceAxleTireData } from "../../../interfaces";
import TireDualImbalanceSummaryCard from "./TireDualImbalanceSummaryCard";

export interface TiresSummaryCardsProps {
  asset: AssetOs;
}

const TiresSummaryCards: FC<TiresSummaryCardsProps> = ({ asset }) => {
  const [sensors, setSensors] = useState<DualImbalanceAxleTireData[]>([]);

  useEffect(() => {
    if (asset?.sensors?.tpmsBeta && sensors.length === 0) {
      // Check If there are sensors that are not null
      const sensorsData = asset.sensors.tpmsBeta.data?.sensors ?? {};
      if (Object.values(sensorsData).some((sensor) => sensor !== null)) {
        const sensorsSummary = parseTPMSSummary(asset);

        setSensors(sensorsSummary);
      }
    }
  }, [asset, sensors]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: "secondary",
        width: "100%",
      }}
      data-testid="asset-dashboard--tires-tab--tires-cards-summary-section"
    >
      {sensors.map((sensor, index) => {
        return (
          <Box
            sx={{
              width: "100%",
              marginBottom: index !== sensors.length - 1 ? "16px" : "0px",
            }}
            key={`tires-cards-summary-section--card-${index}`}
          >
            <TireDualImbalanceSummaryCard axleData={sensor} />
          </Box>
        );
      })}
    </Box>
  );
};

export default TiresSummaryCards;
