import { useState } from "react";
import {
  Button as CancelButton,
  CircularProgress,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as LiftgateSensorDark } from "../../../../../assets/svgs/liftGateSensorDark.svg";
import { ReactComponent as LiftgateSensorLight } from "../../../../../assets/svgs/liftgateSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AssetOs,
  ProfileConfigProperty,
  useSetAssetSensorProfilesMutation,
  SetAssetSensorProfilesInput,
  AssetSensorProfilesResponseStatus,
  SetAssetSensorProfilesMutation,
  MergedProfileForAsset,
} from "../../../../../graphql/operations";
import { Button } from "../../../../../shared/components/Button";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractReversedRules,
  prepareReversedPayload,
  batteryStep,
  batteryLiftgateDefaultValues,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Spinner from "../../../../../shared/components/Spinner";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { SensorValueType } from "../../../../../shared/helpers/sensors.utils";
import { useUserPermission } from "../../../../../shared/hooks/useUserPermission";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";

interface BatterySettingsProps {
  asset: AssetOs;
  assetMergedSensorProfile: MergedProfileForAsset;
  isAssetRefetching: boolean;
}

export const LiftgateSettings: React.FC<BatterySettingsProps> = ({
  asset,
  assetMergedSensorProfile,
  isAssetRefetching,
}) => {
  const parsedLiftgateValues = extractReversedRules(
    assetMergedSensorProfile?.configuration?.liftgate?.match?.thresholds
  );

  const initialLiftgate = parsedLiftgateValues ?? batteryLiftgateDefaultValues;

  const initialLiftgateBattery =
    parsedLiftgateValues || batteryLiftgateDefaultValues;

  const [liftgateBatteryState, setLiftgateBatteryState] = useState(
    initialLiftgateBattery
  );

  const queryClient = useQueryClient();
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
  };
  const isUserAllowed = useUserPermission("assetManagement.editSettings");

  const { mutate, isLoading } = useSetAssetSensorProfilesMutation({
    onSuccess: async (data: SetAssetSensorProfilesMutation) => {
      await queryClient.invalidateQueries(["getAssetsForClustersOS"]);
      await queryClient.invalidateQueries(["getAssetsForTableOS"]);
      await queryClient.invalidateQueries(["getAssetsForListOS"]);
      await queryClient.invalidateQueries({ queryKey: ["FindAssetById"] });
      await queryClient.invalidateQueries(["getMergedProfileForAsset"]);

      const response = data?.setAssetSensorProfiles?.[0];
      if (response?.status === AssetSensorProfilesResponseStatus.Error) {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Settings Uploading Failed",
            text: mapServerErrorCodeToHumanReadableMessage(response?.message),
            severity: "error",
            onClose: () => {},
          },
        });
      } else {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Asset Liftgate Settings Updated",
            text: "Battery Liftgate Updated Successfully!",
            severity: "success",
          },
        });
      }
    },
  });

  const handleFormSubmit = async () => {
    if (!asset.imei) return;

    const input: SetAssetSensorProfilesInput = {
      selectedImeis: [asset.imei],
      orgId: asset.customer_orgs_id ?? "",
      sensors: {
        liftgate: {
          sensorType: SensorValueType.Voltage,
          ...prepareReversedPayload(
            ProfileConfigProperty.Liftgate,
            liftgateBatteryState,
            MinValuesBySensorType.liftgate,
            MaxValuesBySensorType.liftgate,
            batteryStep
          ),
        },
      },
    };
    mutate({ input });
  };

  const handleCancelClick = () => {
    setLiftgateBatteryState(initialLiftgateBattery);
  };

  const liftgateBatteryMarks = sliderMarks(
    MinValuesBySensorType.liftgate,
    MaxValuesBySensorType.liftgate,
    "V"
  );

  const isSliderPristine =
    liftgateBatteryState?.toString() === initialLiftgate.toString();
  const isDisabled = isLoading || isSliderPristine;

  return (
    <>
      <Grid container className="mb-12">
        <Grid item xs={12}>
          <Box data-testid="asset-settings-liftgate-slider">
            <Box className="mb-16">
              <Typography
                data-testid="liftgate-settings-slider-label"
                className="assetSettingsSectionTitle"
              >
                Liftgate Battery Settings
              </Typography>
              {isLightTheme ? (
                <LiftgateSensorDark
                  style={svgIconSettings}
                  data-testid="BatteryFullIcon"
                />
              ) : (
                <LiftgateSensorLight
                  style={svgIconSettings}
                  data-testid="BatteryFullIcon"
                />
              )}
            </Box>
            <SensorSlider
              values={liftgateBatteryState}
              min={MinValuesBySensorType.liftgate}
              max={MaxValuesBySensorType.liftgate}
              marks={liftgateBatteryMarks}
              disabled={isLoading}
              step={batteryStep}
              reversed
              onChange={setLiftgateBatteryState}
            />
          </Box>
        </Grid>
      </Grid>
      {isUserAllowed && (
        <Grid
          container
          spacing={8}
          className="items-baseline justify-center md:justify-end"
        >
          <Grid item>
            <CancelButton
              data-testid="settings-cancel"
              className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
              onClick={handleCancelClick}
              disabled={isDisabled}
            >
              Cancel
            </CancelButton>
          </Grid>
          <Grid item>
            <Button
              dataTestid="settings-submit"
              className="w-full"
              text={isLoading ? "Saving" : "Save"}
              size="medium"
              theme="blue"
              variant="default"
              type="button"
              onClick={handleFormSubmit}
              iconPosition="right"
              icon={
                isLoading && (
                  <CircularProgress size={15} style={{ color: "white" }} />
                )
              }
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
      )}
      <Spinner counter={Number(isAssetRefetching)} />
    </>
  );
};
