import { KeyboardEventHandler } from "react";
import { UseFormReturn } from "react-hook-form";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { AssetOs } from "../../../../../../graphql/operations";
import { NOMENCLATURE_NAMES, useNomenclatures } from "../../../hooks";
import type { Breakpoints } from "../AssetForm";
import {
  ASSET_OTHER_FORM_FIELDS,
  AssetFormSectionTitles,
  AssetOtherFields,
  yesNoOptions,
} from "../assetFormUtils";
import { AccordionWrapper } from "../components/AccordionWrapper";

interface OtherDetailsProps {
  showDetailsHeader?: boolean;
  form: UseFormReturn<Partial<AssetOs>>;
  breakpoints: Breakpoints;
  disableAllFields?: boolean;
  numberChangeHandler: KeyboardEventHandler<HTMLDivElement>;
}

export const OtherDetails: React.FC<OtherDetailsProps> = ({
  showDetailsHeader = false,
  form,
  breakpoints,
  disableAllFields = false,
  numberChangeHandler,
}) => {
  const aeroKitTypes = useNomenclatures(NOMENCLATURE_NAMES.aeroKitType);
  const doorLatchTypes = useNomenclatures(NOMENCLATURE_NAMES.doorLatchType);
  const gladhandTypes = useNomenclatures(NOMENCLATURE_NAMES.gladhandType);
  const noseboxMountTypeOptions = useNomenclatures(
    NOMENCLATURE_NAMES.noseboxMountType
  );
  const landingGearManufacturerOptions = useNomenclatures(
    NOMENCLATURE_NAMES.landingGearManufacturer
  );
  const suspensionTypeOptions = useNomenclatures(
    NOMENCLATURE_NAMES.suspensionType
  );
  const tireSizeOptions = useNomenclatures(NOMENCLATURE_NAMES.tireSize);
  const tireRimTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.tireRimType);

  const noseBodyTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.noseBodyType);

  return (
    <AccordionWrapper
      accordionSummaryTitle={AssetFormSectionTitles.Other}
      showDetailsHeader={showDetailsHeader}
      isDashboardSettings={showDetailsHeader}
    >
      <Grid
        container
        rowGap={showDetailsHeader ? 3 : 0}
        className={`!text-primary ${
          showDetailsHeader ? "formSection" : "secondDrawerSection"
        }`}
        spacing={6}
      >
        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LightWeight].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LightWeight].label}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.TTPairCapable].name}
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.TTPairCapable].label
            }
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.AeroKitType].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.AeroKitType].label}
            options={aeroKitTypes}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.BatteryCharger].name}
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.BatteryCharger].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.DoorLatchType].name}
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.DoorLatchType].label
            }
            options={doorLatchTypes}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.DoorLatchManufacturer]
                .name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.DoorLatchManufacturer]
                .label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[
                AssetOtherFields.ElectricalHarnessManufacturer
              ].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[
                AssetOtherFields.ElectricalHarnessManufacturer
              ].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.ElectronicLandingGear]
                .name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.ElectronicLandingGear]
                .label
            }
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.Etrack].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.Etrack].label}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.GladhandManufacturer]
                .name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.GladhandManufacturer]
                .label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.GladhandType].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.GladhandType].label}
            options={gladhandTypes}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.HowIsNoseboxMounted].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.HowIsNoseboxMounted]
                .label
            }
            options={noseboxMountTypeOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.InnerLinerAvailable].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.InnerLinerAvailable]
                .label
            }
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.NosePostDepth].name}
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.NosePostDepth].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[
                AssetOtherFields.InteriorDropDownCargoSystem
              ].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[
                AssetOtherFields.InteriorDropDownCargoSystem
              ].label
            }
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.KingpinOffset].name}
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.KingpinOffset].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LandingGearManufacturer]
                .name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LandingGearManufacturer]
                .label
            }
            options={landingGearManufacturerOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LandingGearModel].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LandingGearModel].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LandingGearModelRating]
                .name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LandingGearModelRating]
                .label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LightingManufacture].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LightingManufacture]
                .label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[
                AssetOtherFields.PreferredMidturnLightPartNumber
              ].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[
                AssetOtherFields.PreferredMidturnLightPartNumber
              ].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[
                AssetOtherFields.PreferredTailLightPartNumber
              ].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[
                AssetOtherFields.PreferredTailLightPartNumber
              ].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LogisticsPosts].name}
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.LogisticsPosts].label
            }
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.NoseTypeBody].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.NoseTypeBody].label}
            options={noseBodyTypeOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.NoseboxManufacturer].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.NoseboxManufacturer]
                .label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.NoseboxModel].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.NoseboxModel].label}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.Permalogic].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.Permalogic].label}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.RearAero].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.RearAero].label}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.RearAeroManufacturer]
                .name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.RearAeroManufacturer]
                .label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.RoadtrainAllowed].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.RoadtrainAllowed].label
            }
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.Skirt].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.Skirt].label}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.SkirtManufacturer].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.SkirtManufacturer].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.SuspensionManufacturer]
                .name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.SuspensionManufacturer]
                .label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.SuspensionModel].name
            }
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.SuspensionModel].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.SuspensionType].name}
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.SuspensionType].label
            }
            options={suspensionTypeOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.SuspensionYear].name}
            label={
              ASSET_OTHER_FORM_FIELDS[AssetOtherFields.SuspensionYear].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.TireSize].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.TireSize].label}
            options={tireSizeOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.TireRimType].name}
            label={ASSET_OTHER_FORM_FIELDS[AssetOtherFields.TireRimType].label}
            options={tireRimTypeOptions}
          />
        </Grid>
      </Grid>
    </AccordionWrapper>
  );
};
