import { FC, memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import { ASSETS_LIST_WIDTH } from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import { AssetOs, GeofenceData } from "../../../../../graphql/operations";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { NavigationRoutes } from "../../../../../utils/routes/routesUtils";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import {
  ARROW_COMMON_CLASSES,
  AssetPagesUrlTypes,
  getViewFromUrl,
} from "../../../utils";
import AssetsList from "./AssetsList";

const ARROW_RIGHT_CLASSES = `${ARROW_COMMON_CLASSES} rounded-tr-none rounded-tl-lg rounded-bl-lg rounded-br-none z-10`;
interface ArrowButtonProps {
  onToggleAssetsDrawer: () => void;
  isAssetsDrawerOpen: boolean;
}

const ArrowButton: FC<ArrowButtonProps> = ({
  onToggleAssetsDrawer,
  isAssetsDrawerOpen,
}) => (
  <Box
    className={ARROW_RIGHT_CLASSES}
    onClick={onToggleAssetsDrawer}
    sx={{
      // The 0.5 is subtracted to account for the border (so the button sits above the border)
      right: isAssetsDrawerOpen ? ASSETS_LIST_WIDTH - 0.5 : 0,
      boxShadow: isAssetsDrawerOpen ? "none" : "0 0 16px 0px var(--box-shadow)",
    }}
  >
    {isAssetsDrawerOpen ? (
      <KeyboardArrowRight sx={{ color: "var(--typography)" }} />
    ) : (
      <KeyboardArrowLeft sx={{ color: "var(--typography)" }} />
    )}
  </Box>
);

const AssetsDrawer = memo(() => {
  const { setSelectedAssetId, setSelectedGeofence, isGeofencesView } =
    useAssetsDataContext();
  const navigate = useNavigate();
  const isMobile = useBreakpoint("down", "sm");
  const {
    isGeofencesListVisible,
    setIsFiltersDrawerOpen,
    isAssetsDrawerOpen,
    setIsAssetsDrawerOpen,
  } = useAssetsDataContext();

  const {
    state: { appConfig },
  } = useAppContext();
  const { selectedAssetsView } = appConfig;
  const urlView = getViewFromUrl(window.location.pathname);

  const view = useMemo(
    () => selectedAssetsView ?? urlView,
    [selectedAssetsView, urlView]
  );
  const viewIsMapOrGeofences = useMemo(
    () =>
      [AssetPagesUrlTypes.Map].includes(view as AssetPagesUrlTypes) ||
      isGeofencesView,
    [view, isGeofencesView]
  );

  const onGeofenceClick = useCallback(
    (geofence: GeofenceData) => {
      setSelectedAssetId(null);
      setIsFiltersDrawerOpen(false); // Close the filters drawer
      navigate(`${NavigationRoutes.Geofences}/${geofence._id}`);
    },
    [setSelectedAssetId, setIsFiltersDrawerOpen, navigate]
  );
  const onFeatureClick = useCallback(
    (asset: AssetOs) => {
      setSelectedAssetId(asset._id);
      setIsFiltersDrawerOpen(false); // Close the filters drawer
      setSelectedGeofence(null);
      navigate(asset._id);
    },
    [setSelectedAssetId, setIsFiltersDrawerOpen, setSelectedGeofence, navigate]
  );

  const onToggleAssetsDrawer = () => {
    if (isMobile && !isAssetsDrawerOpen) {
      setIsFiltersDrawerOpen(false);
    }
    setIsAssetsDrawerOpen(!isAssetsDrawerOpen);
  };

  const wrapperStyles = `${
    isAssetsDrawerOpen ? "fixed" : "hidden"
  } overflow-hidden bg-background text-primary relative shadow-2xl`;
  const wrapperHeight = isGeofencesListVisible
    ? "calc(100% - 11rem)"
    : "inherit";

  // Show Assets Drawer only on map view
  if (!viewIsMapOrGeofences) return null;

  return (
    <>
      <ArrowButton
        onToggleAssetsDrawer={onToggleAssetsDrawer}
        isAssetsDrawerOpen={isAssetsDrawerOpen}
      />
      <Box
        className={wrapperStyles}
        width={ASSETS_LIST_WIDTH}
        height={wrapperHeight}
        sx={{
          width: ASSETS_LIST_WIDTH,
          height: wrapperHeight,
          borderLeft: "1px solid var(--border-color)",
        }}
        data-testid="map-assets-drawer"
      >
        <Box className="absolute inset-0 flex">
          <AssetsList
            onFeatureClick={onFeatureClick}
            onGeofenceClick={onGeofenceClick}
          />
        </Box>
      </Box>
    </>
  );
});

export default AssetsDrawer;
