import { FC, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as CargoVisionSensorDark } from "../../../../../assets/svgs/cargoVisionSensorDark.svg";
import { ReactComponent as CargoVisionSensorLight } from "../../../../../assets/svgs/cargoVisionSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AssetOs,
  ProfileConfigProperty,
  useSetAssetSensorProfilesMutation,
  SetAssetSensorProfilesInput,
  AssetSensorProfilesResponseStatus,
  SetAssetSensorProfilesMutation,
  MergedProfileForAsset,
  SetAssetSensorProfilesResponse,
} from "../../../../../graphql/operations";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractReversedRules,
  prepareReversedPayload,
  cargoCameraDefaultValues,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Spinner from "../../../../../shared/components/Spinner";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { SensorValueType } from "../../../../../shared/helpers/sensors.utils";
import { useUserPermission } from "../../../../../shared/hooks/useUserPermission";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";
import {
  analyzeResponse,
  handleAnalyzedResponse,
} from "../../../../AdminPanel/tabs/Sensors/sensorsUtils";
import SaveCancelButtons from "../SaveCancelButtons/SaveCancelButtons";

interface CargoProps {
  asset: AssetOs;
  assetMergedSensorProfile: MergedProfileForAsset;
  isAssetRefetching: boolean;
}

export const CargoComponent: FC<CargoProps> = ({
  asset,
  assetMergedSensorProfile,
  isAssetRefetching,
}: CargoProps) => {
  const { configuration } = assetMergedSensorProfile;
  const initialFloorSpace =
    extractReversedRules(
      configuration?.cargoCamera?.floorUsagePercentage?.match?.thresholds
    ) || cargoCameraDefaultValues;
  const initialCubeSpace =
    extractReversedRules(
      configuration?.cargoCamera?.cubeUsagePercentage?.match?.thresholds
    ) || cargoCameraDefaultValues;

  const cameraMin = MinValuesBySensorType.cargoCamera;
  const cameraMax = MaxValuesBySensorType.cargoCamera;

  // State
  const [floorSpace, setFloorSpace] = useState(initialFloorSpace);
  const [cubeSpace, setCubeSpace] = useState(initialCubeSpace);

  const queryClient = useQueryClient();
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
  };
  const isUserAllowed = useUserPermission("assetManagement.editSettings");

  const { mutate: mutateProfile, isLoading } =
    useSetAssetSensorProfilesMutation({
      onSuccess: async (data: SetAssetSensorProfilesMutation) => {
        const response = analyzeResponse(
          data?.setAssetSensorProfiles as SetAssetSensorProfilesResponse[],
          AssetSensorProfilesResponseStatus.Success
        );
        handleAnalyzedResponse(
          response,
          dispatchCargoErrorMessage,
          dispatchCargoSuccessMessage,
          queryClient
        );
        queryClient.invalidateQueries({
          queryKey: ["getMergedProfileForAsset"],
        });
      },
    });

  const dispatchCargoErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Settings Uploading Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const dispatchCargoSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success",
        text: "Cargo Settings Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handleCargoSubmit = async () => {
    if (!isUserAllowed || !asset.imei) return;

    const cargoInput: SetAssetSensorProfilesInput = {
      selectedImeis: [asset.imei],
      orgId: asset.customer_orgs_id ?? "",
      sensors: {
        cargoCamera: {
          floorUsagePercentage: {
            sensorType: SensorValueType.Percentage,
            ...prepareReversedPayload(
              ProfileConfigProperty.SupplyPressure,
              floorSpace,
              cameraMin,
              cameraMax
            ),
          },
          cubeUsagePercentage: {
            sensorType: SensorValueType.Percentage,
            ...prepareReversedPayload(
              ProfileConfigProperty.SupplyPressure,
              cubeSpace,
              cameraMin,
              cameraMax
            ),
          },
        },
      },
    };

    mutateProfile({ input: cargoInput });
  };

  const handleCargoReset = () => {
    setFloorSpace(initialFloorSpace);
    setCubeSpace(initialCubeSpace);
  };

  const isAnySliderDirty =
    floorSpace.toString() !== initialFloorSpace.toString() ||
    cubeSpace.toString() !== initialCubeSpace.toString();
  const isDisabled = isAssetRefetching || isLoading || !isAnySliderDirty;

  return (
    <>
      <Grid container className="mb-10" data-testid="asset-settings-cargo">
        <Grid item xs={12} data-testid="settings-camera-floor">
          <Box className="mb-16">
            <Typography className="assetSettingsSectionTitle">
              Floor Space Settings
            </Typography>
            {isLightTheme ? (
              <CargoVisionSensorDark
                style={svgIconSettings}
                data-testid="temperature-profile-drawer-thermostat"
              />
            ) : (
              <CargoVisionSensorLight
                style={svgIconSettings}
                data-testid="temperature-profile-drawer-thermostat"
              />
            )}
          </Box>
          <SensorSlider
            values={floorSpace}
            min={cameraMin}
            max={cameraMax}
            marks={sliderMarks(cameraMin, cameraMax, "%")}
            disabled={isLoading}
            reversed
            onChange={setFloorSpace}
          />
        </Grid>

        <Grid item xs={12} data-testid="settings-camera-cube">
          <Box className="mt-12 mb-16">
            <Typography className="assetSettingsSectionTitle">
              Volume Settings
            </Typography>
            {isLightTheme ? (
              <CargoVisionSensorDark
                style={svgIconSettings}
                data-testid="temperature-profile-drawer-thermostat"
              />
            ) : (
              <CargoVisionSensorLight
                style={svgIconSettings}
                data-testid="temperature-profile-drawer-thermostat"
              />
            )}
          </Box>
          <SensorSlider
            values={cubeSpace}
            min={cameraMin}
            max={cameraMax}
            marks={sliderMarks(cameraMin, cameraMax, "%")}
            disabled={isLoading}
            reversed
            onChange={setCubeSpace}
          />
        </Grid>
      </Grid>

      {isUserAllowed && (
        <SaveCancelButtons
          handleSubmitClick={handleCargoSubmit}
          handleCancelClick={handleCargoReset}
          isLoading={isLoading}
          areButtonsDisabled={isDisabled}
          isUserAllowed={isUserAllowed}
        />
      )}
      <Spinner counter={Number(isLoading)} />
    </>
  );
};
