import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { AssetOs } from "../../../../../graphql/operations";
import { schema } from "./schema";

export const useAssetForm = (initialValues: Partial<any> = {}) => {
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form]
  );

  return { form, getValues, schema };
};

export const getAssetFields = (assetData: Partial<AssetOs>) => {
  return {
    _id: assetData._id,
    customer_orgs_id: assetData?.customer_orgs_id ?? "",
    asset_id: assetData?.asset_id ?? "",
    name: assetData?.name ?? "",
    imei: assetData.imei ?? "",
    prd_cde: assetData?.prd_cde ?? "",
    category: assetData?.category ?? "",
    tags: assetData?.tags ?? "",
    vin: assetData?.vin ?? "",
    wheel_config: assetData?.wheel_config ?? undefined,
    num_of_axles: assetData?.num_of_axles ?? undefined,
    length: assetData.length ?? ("" as unknown as number), // needed for form as input text is string
    door_type: assetData.door_type ?? undefined,

    externalHeight: assetData.externalHeight ?? undefined,
    internalHeight: assetData.internalHeight ?? undefined,
    internalLength: assetData.internalLength ?? undefined,
    internalWidth: assetData.internalWidth ?? undefined,
    sub_asset_type: assetData.sub_asset_type ?? undefined,
    externalLength: assetData.externalLength ?? undefined,
    manufacturer: assetData.manufacturer ?? undefined,
    assetModel: assetData.assetModel ?? undefined,
    emptyWeight: assetData.emptyWeight ?? undefined,
    engineHours: assetData.engineHours ?? undefined,
    tripStatus: {
      odometer: assetData.tripStatus?.odometer ?? undefined,
    },
    metadata: {
      year: assetData.metadata?.year ?? undefined,
      assetInserviceDate: assetData.metadata?.assetInserviceDate ?? undefined,
      cubeCapacity: assetData.metadata?.cubeCapacity ?? undefined,
      railCompliant: assetData.metadata?.railCompliant ?? undefined,
      airRide: assetData.metadata?.airRide ?? undefined,

      // Lifgtage
      liftgate: assetData.metadata?.liftgate ?? undefined,
      liftgateMACAddress: assetData.metadata?.liftgateMACAddress ?? undefined,
      liftgateManufacturer:
        assetData.metadata?.liftgateManufacturer ?? undefined,
      liftgateModel: assetData.metadata?.liftgateModel ?? undefined,
      liftgateSerial: assetData.metadata?.liftgateSerial ?? undefined,
      liftgateYear: assetData.metadata?.liftgateYear ?? undefined,
      liftgateFWVersion: assetData.metadata?.liftgateFWVersion ?? undefined,

      //TCU
      CARBNumber: assetData.metadata?.CARBNumber ?? undefined,
      reeferModel: assetData.metadata?.reeferModel ?? undefined,
      reeferSerialNumber: assetData.metadata?.reeferSerialNumber ?? undefined,
      reeferManufacturer: assetData.metadata?.reeferManufacturer ?? undefined,
      reeferCARBCompliant: assetData.metadata?.reeferCARBCompliant ?? undefined,
      reeferYear: assetData.metadata?.reeferYear ?? undefined,

      //Rail Compliance
      flushMountedNose:
        typeof assetData.metadata?.flushMountedNose !== "undefined" &&
        assetData.metadata?.flushMountedNose !== null
          ? Number(assetData.metadata?.flushMountedNose)
          : undefined,
      gridExtensionPlate:
        typeof assetData.metadata?.gridExtensionPlate !== "undefined" &&
        assetData.metadata?.gridExtensionPlate !== null
          ? Number(assetData.metadata?.gridExtensionPlate)
          : undefined,
      liftPads:
        typeof assetData.metadata?.liftPads !== "undefined" &&
        assetData.metadata?.liftPads !== null
          ? Number(assetData.metadata?.liftPads)
          : undefined,

      //ABS
      absCanbus:
        typeof assetData.metadata?.absCanbus !== "undefined" &&
        assetData.metadata?.absCanbus !== null
          ? Number(assetData.metadata?.absCanbus)
          : undefined,
      absConfiguration: assetData.metadata?.absConfiguration ?? undefined,
      absManufacturer: assetData.metadata?.absManufacturer ?? undefined,
      absModel: assetData.metadata?.absModel ?? undefined,
      absYear: assetData.metadata?.absYear ?? undefined,

      // ATIS
      atis:
        typeof assetData.metadata?.atis !== "undefined" &&
        assetData.metadata?.atis !== null
          ? Number(assetData.metadata?.atis)
          : undefined,
      atisManufacturer: assetData.metadata?.atisManufacturer ?? undefined,
      atisModel: assetData.metadata?.absModel ?? undefined,

      // Others
      lightWeight:
        typeof assetData.metadata?.lightWeight !== "undefined" &&
        assetData.metadata?.lightWeight !== null
          ? Number(assetData.metadata?.lightWeight)
          : undefined,
      ttPairCapable:
        typeof assetData.metadata?.ttPairCapable !== "undefined" &&
        assetData.metadata?.ttPairCapable !== null
          ? Number(assetData.metadata?.ttPairCapable)
          : undefined,
      aeroKitType: assetData.metadata?.aeroKitType ?? undefined,
      batteryCharger: assetData.metadata?.batteryCharger ?? undefined,
      doorLatchType: assetData.metadata?.doorLatchType ?? undefined,
      doorLatchManufacturer:
        assetData.metadata?.doorLatchManufacturer ?? undefined,
      electricalHarnessManufacturer:
        assetData.metadata?.electricalHarnessManufacturer ?? undefined,
      electronicLandingGear:
        typeof assetData.metadata?.electronicLandingGear !== "undefined" &&
        assetData.metadata?.electronicLandingGear !== null
          ? Number(assetData.metadata?.electronicLandingGear)
          : undefined,
      etrack:
        typeof assetData.metadata?.etrack !== "undefined" &&
        assetData.metadata?.etrack !== null
          ? Number(assetData.metadata?.etrack)
          : undefined,
      gladhandManufacturer:
        assetData.metadata?.gladhandManufacturer ?? undefined,
      gladhandType: assetData.metadata?.gladhandType ?? undefined,
      howIsNoseboxMounted: assetData.metadata?.howIsNoseboxMounted ?? undefined,
      innerLinerAvailable:
        typeof assetData.metadata?.innerLinerAvailable !== "undefined" &&
        assetData.metadata?.innerLinerAvailable !== null
          ? Number(assetData.metadata?.innerLinerAvailable)
          : undefined,
      nosePostDepth: assetData.metadata?.nosePostDepth ?? undefined,
      interiorDropDownCargoSystem:
        typeof assetData.metadata?.interiorDropDownCargoSystem !==
          "undefined" &&
        assetData.metadata?.interiorDropDownCargoSystem !== null
          ? Number(assetData.metadata?.interiorDropDownCargoSystem)
          : undefined,
      kingpinOffset: assetData.metadata?.kingpinOffset ?? undefined,
      landingGearManufacturer:
        assetData.metadata?.landingGearManufacturer ?? undefined,
      landingGearModel: assetData.metadata?.landingGearModel ?? undefined,
      landingGearModelRating:
        assetData.metadata?.landingGearModelRating ?? undefined,
      lightingManufacture: assetData.metadata?.lightingManufacture ?? undefined,
      preferredMidturnLightPartNumber:
        assetData.metadata?.preferredMidturnLightPartNumber ?? undefined,
      preferredTailLightPartNumber:
        assetData.metadata?.preferredTailLightPartNumber ?? undefined,
      logisticsPosts:
        typeof assetData.metadata?.logisticsPosts !== "undefined" &&
        assetData.metadata?.logisticsPosts !== null
          ? Number(assetData.metadata?.logisticsPosts)
          : undefined,
      noseTypeBody: assetData.metadata?.noseTypeBody ?? undefined,
      noseboxManufacturer: assetData.metadata?.noseboxManufacturer ?? undefined,
      noseboxModel: assetData.metadata?.noseboxModel ?? undefined,
      permalogic:
        typeof assetData.metadata?.permalogic !== "undefined" &&
        assetData.metadata?.permalogic !== null
          ? Number(assetData.metadata?.permalogic)
          : undefined,
      rearAero:
        typeof assetData.metadata?.rearAero !== "undefined" &&
        assetData.metadata?.rearAero !== null
          ? Number(assetData.metadata?.rearAero)
          : undefined,
      rearAeroManufacturer:
        assetData.metadata?.rearAeroManufacturer ?? undefined,
      roadtrainAllowed:
        typeof assetData.metadata?.roadtrainAllowed !== "undefined" &&
        assetData.metadata?.roadtrainAllowed !== null
          ? Number(assetData.metadata?.roadtrainAllowed)
          : undefined,
      skirt:
        typeof assetData.metadata?.skirt !== "undefined" &&
        assetData.metadata?.skirt !== null
          ? Number(assetData.metadata?.skirt)
          : undefined,
      skirtManufacturer: assetData.metadata?.skirtManufacturer ?? undefined,
      suspensionManufacturer:
        assetData.metadata?.suspensionManufacturer ?? undefined,
      suspensionModel: assetData.metadata?.suspensionModel ?? undefined,
      suspensionType: assetData.metadata?.suspensionType ?? undefined,
      suspensionYear: assetData.metadata?.suspensionYear ?? undefined,
      tireSize: assetData.metadata?.tireSize ?? undefined,
      tireRimType: assetData.metadata?.tireRimType ?? undefined,
    },
  };
};

/**
 * Definition of fields
 * Only name & label are used in the asset form,
 * other properties are added so that sonarcloud doesn't trigger duplication error
 */
export const ASSET_FORM_FIELDS: any[] = [
  {
    name: "customer_orgs_id",
    label: "Organization Name",
    required: true,
    matchid: "true",
    disabled: false,
  },
  {
    name: "asset_id",
    label: "Asset ID",
    required: true,
    disabled: false,
  },
  {
    name: "name",
    label: "Asset Nickname",
    required: true,
  },
  {
    name: "imei",
    label: "Device ID",
    required: false,
    readOnly: false,
    options: [],
  },
  {
    name: "prd_cde",
    label: "Product Name",
    options: [],
    required: false,
  },
  {
    name: "category",
    label: "Asset Type",
    disabled: false,
    required: true,
  },
  {
    name: "tags",
    label: "Asset Tags",
    readOnly: false,
    required: false,
  },
  {
    name: "vin",
    label: "VIN",
    disabled: false,
    required: false,
  },
  {
    name: "wheel_config",
    label: "# of Tires",
    required: false,
    options: [],
  },
  {
    name: "num_of_axles",
    label: "# of Axles",
    required: false,
    options: [],
  },
  {
    name: "length",
    label: "Length",
  },
  {
    name: "door_type",
    label: "Door Type",
  },
  {
    name: "sub_asset_type",
    label: "Sub Asset Type",
  },
];

// used to prevent entering 'e', '+', '-', '.' because they are allowed in type number
export const numberInputChangeHandler = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const invalidKeys = ["e", "+", "-", "."];
  if (invalidKeys.includes(event.key)) {
    event.preventDefault();
  }
};

// TODO: Think about applying the new booleanOptions everywhere
export const yesNoOptions = [
  { id: 1, label: "Yes", value: "Yes" },
  { id: 0, label: "No", value: "No" },
];

export const booleanOptions = [
  { id: true, label: "Yes", value: true },
  { id: false, label: "No", value: false },
];

export const ASSET_VIN_HELPER_TEXT =
  "Automatically populated once VIN is provided";

export enum AssetFormSectionTitles {
  AssetInformation = "Asset Information",
  AssetDetails = "Asset Details",
  AssetSummary = "Summary",
  Liftgate = "Liftgate",
  TemperatureControlledUnit = "TCU",
  RailCompliance = "Rail Compliance",
  ABS = "ABS",
  ATIS = "ATIS",
  Other = "Other",
}

export enum AssetSummaryFields {
  ExternalHeight = "External Height",
  InternalHeight = "Internal Height",
  InternalLength = "Internal Length",
  InternalWidth = "Internal Width",
}

export enum AssetDetailsFields {
  ExternalLength = "External Length",
  Manufacturer = "Manufacturer",
  Model = "Model",
  Year = "Year",
  AssetInserviceDate = "Asset Inservice Date",
  EmptyWeight = "Empty Weight",
  CubeCapacity = "Volume Capacity",
  Length = "Length",
  RailCompliant = "Rail Compliant",
  NumberOfTires = "Number Of Tires",
  NumberOfAxles = "Number Of Axles",
  DoorType = "Door Type",
  AirRide = "Air Ride",
  Odometer = "Odometer",
  EngineHours = "Engine Hours",
}

export enum AssetLiftgateFields {
  Liftgate = "Liftgate",
  LiftgateMacAddress = "Liftgate MAC Address",
  LiftgateManufacturer = "Liftgate Manufacturer",
  LiftgateModel = "Liftgate Model",
  LiftgateSerial = "Liftgate Serial",
  LiftgateYear = "Liftgate Year",
  LiftgateFWVersion = "Liftgate FW Version",
}

export enum AssetTCUFields {
  CARB = "CARB",
  ReeferModel = "Reefer Model",
  ReeferSerial = "Reefer Serial",
  ReeferManufacturer = "Reefer Manufacturer",
  ReeferCARBCompliant = "Reefer CARB Compliant",
  ReeferYear = "Reefer Year",
}

export enum AssetRailComplianceFields {
  FlushMountedNose = "Flush Mounted Nose",
  GridExtensionPlate = "Grid Extension Plate",
  LiftPads = "Lift Pads",
}

export enum AssetABSFields {
  ABSCanbus = "ABS Canbus",
  ABSConfiguration = "ABS Configuration",
  ABSManufacturer = "ABS Manufacturer",
  ABSModel = "ABS Model",
  ABSYear = "ABS Year",
}

export enum AssetATISFields {
  ATIS = "ATIS",
  ATISManufacturer = "ATIS Manufacturer",
  ATISModel = "ATIS Model",
}
export enum AssetOtherFields {
  LightWeight = "Flush Mounted Nose",
  TTPairCapable = "TT Pair Capable",
  AeroKitType = "Aero Kit Type",
  BatteryCharger = "Battery Charger",
  DoorLatchType = "Door Latch Type",
  DoorLatchManufacturer = "Door Latch Manufacturer",
  ElectricalHarnessManufacturer = "Electrical Harness Manufacturer",
  ElectronicLandingGear = "Electronic Landing Gear",
  Etrack = "Etrack",
  GladhandManufacturer = "Gladhand Manufacturer",
  GladhandType = "Gladhand Type",
  HowIsNoseboxMounted = "How is Nosebox Mounted",
  InnerLinerAvailable = "InnerLinerAvailable",
  NosePostDepth = "Nose Post Depth",
  InteriorDropDownCargoSystem = "Interior Drop Down Cargo System",
  KingpinOffset = "Kingpin Offset",
  LandingGearManufacturer = "Landing Gear Manufacturer",
  LandingGearModel = "Landing Gear Model",
  LandingGearModelRating = "Landing Gear Model Rating",
  LightingManufacture = "Lighting Manufacture",
  PreferredMidturnLightPartNumber = "Preferred Mid-turn light Part #",
  PreferredTailLightPartNumber = "Preferred Tail light Part #",
  LogisticsPosts = "Logistics Posts",
  NoseTypeBody = "Nose Type (Body)",
  NoseboxManufacturer = "Nosebox manufacturer",
  NoseboxModel = "Nosebox model",
  Permalogic = "Permalogic",
  RearAero = "Rear Aero",
  RearAeroManufacturer = "Rear Aero Manufacturer",
  RoadtrainAllowed = "Roadtrain Allowed?",
  Skirt = "Skirt",
  SkirtManufacturer = "Skirt Manufacturer",
  SuspensionManufacturer = "Suspension Manufacturer",
  SuspensionModel = "Suspension Model",
  SuspensionType = "Suspension Type",
  SuspensionYear = "Suspension Year",
  TireSize = "Tire Size",
  TireRimType = "Tire Rim Type",
}

export type FormField = {
  name: any;
  label: string;
  required: boolean;
  matchid?: string;
  disabled: boolean;
};

type AssetSummaryFormFields = {
  [key in AssetSummaryFields]: FormField;
};
type AssetDetailsFormFields = {
  [key in AssetDetailsFields]: Partial<FormField>;
};
type AssetLiftFormFields = {
  [key in AssetLiftgateFields]: Partial<FormField>;
};
type AssetTCUFormFields = {
  [key in AssetTCUFields]: Partial<FormField>;
};
type AssetRailComplianceFormFields = {
  [key in AssetRailComplianceFields]: Partial<FormField>;
};
type AssetABSFormFields = {
  [key in AssetABSFields]: Partial<FormField>;
};
type AssetATISFormFields = {
  [key in AssetATISFields]: Partial<FormField>;
};
type AssetOtherFormFields = {
  [key in AssetOtherFields]: Partial<FormField>;
};

export const ASSET_SUMMARY_FORM_FIELDS: AssetSummaryFormFields = {
  [AssetSummaryFields.ExternalHeight]: {
    name: "externalHeight",
    label: "External Height",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetSummaryFields.InternalHeight]: {
    name: "internalHeight",
    label: "Internal Height",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetSummaryFields.InternalLength]: {
    name: "internalLength",
    label: "Internal Length",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetSummaryFields.InternalWidth]: {
    name: "internalWidth",
    label: "Internal Width",
    required: true,
    matchid: "true",
    disabled: false,
  },
};

export const ASSET_DETAILS_FORM_FIELDS: AssetDetailsFormFields = {
  [AssetDetailsFields.ExternalLength]: {
    name: "externalLength",
    label: "External Length",
  },
  [AssetDetailsFields.Manufacturer]: {
    name: "manufacturer",
    label: "Manufacturer",
  },
  [AssetDetailsFields.Model]: {
    name: "assetModel",
    label: "Model",
  },
  [AssetDetailsFields.Year]: {
    name: "metadata.year",
    label: "Year",
  },
  [AssetDetailsFields.AssetInserviceDate]: {
    name: "metadata.assetInserviceDate",
    label: "Asset Inservice Date",
  },
  [AssetDetailsFields.EmptyWeight]: {
    name: "emptyWeight",
    label: "Empty Weight",
  },
  [AssetDetailsFields.CubeCapacity]: {
    name: "metadata.cubeCapacity",
    label: "Volume Capacity",
  },
  [AssetDetailsFields.Length]: {
    name: "length",
    label: "Length",
  },
  [AssetDetailsFields.RailCompliant]: {
    name: "metadata.railCompliant",
    label: "Rail Compliant",
  },
  [AssetDetailsFields.NumberOfTires]: {
    name: "wheel_config",
    label: "# of Tires",
  },
  [AssetDetailsFields.NumberOfAxles]: {
    name: "num_of_axles",
    label: "# of Axles",
  },
  [AssetDetailsFields.DoorType]: {
    name: "door_type",
    label: "Door Type",
  },
  [AssetDetailsFields.AirRide]: {
    name: "metadata.airRide",
    label: "Air Ride",
  },
  [AssetDetailsFields.Odometer]: {
    name: "tripStatus.odometer",
    label: "Odometer",
  },
  [AssetDetailsFields.EngineHours]: {
    name: "engineHours",
    label: "Engine Hours",
  },
};

export const ASSET_LIFT_FORM_FIELDS: AssetLiftFormFields = {
  [AssetLiftgateFields.Liftgate]: {
    name: "metadata.liftgate",
    label: "Liftgate",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetLiftgateFields.LiftgateMacAddress]: {
    name: "metadata.liftgateMACAddress",
    label: "Liftgate Mac Address",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetLiftgateFields.LiftgateManufacturer]: {
    name: "metadata.liftgateManufacturer",
    label: "Liftgate Manufacturer",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetLiftgateFields.LiftgateModel]: {
    name: "metadata.liftgateModel",
    label: "Liftgate Model",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetLiftgateFields.LiftgateSerial]: {
    name: "metadata.liftgateSerial",
    label: "Liftgate Serial #",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetLiftgateFields.LiftgateYear]: {
    name: "metadata.liftgateYear",
    label: "Liftgate Year",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetLiftgateFields.LiftgateFWVersion]: {
    name: "metadata.liftgateFWVersion",
    label: "Liftgate FW Version",
    required: true,
    matchid: "true",
    disabled: false,
  },
};

export const ASSET_TCU_FORM_FIELDS: AssetTCUFormFields = {
  [AssetTCUFields.CARB]: {
    name: "metadata.CARBNumber",
    label: "CARB #",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetTCUFields.ReeferModel]: {
    name: "metadata.reeferModel",
    label: "Reefer Model",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetTCUFields.ReeferSerial]: {
    name: "metadata.reeferSerialNumber",
    label: "Reefer Serial #",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetTCUFields.ReeferManufacturer]: {
    name: "metadata.reeferManufacturer",
    label: "Reefer Manufacturer",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetTCUFields.ReeferCARBCompliant]: {
    name: "metadata.reeferCARBCompliant",
    label: "Reefer CARB Compliant",
    required: true,
    matchid: "true",
    disabled: false,
  },
  [AssetTCUFields.ReeferYear]: {
    name: "metadata.reeferYear",
    label: "Reefer Year",
    required: true,
    matchid: "true",
    disabled: false,
  },
};

export const ASSET_RAIL_COMPLIANCE_FORM_FIELDS: AssetRailComplianceFormFields =
  {
    [AssetRailComplianceFields.FlushMountedNose]: {
      name: "metadata.flushMountedNose",
      label: "Flush Mounted Nose",
    },
    [AssetRailComplianceFields.GridExtensionPlate]: {
      name: "metadata.gridExtensionPlate",
      label: "Grid Extension Plate",
    },
    [AssetRailComplianceFields.LiftPads]: {
      name: "metadata.liftPads",
      label: "Lift Pads",
    },
  };

export const ASSET_ABS_FORM_FIELDS: AssetABSFormFields = {
  [AssetABSFields.ABSCanbus]: {
    name: "metadata.absCanbus",
    label: "ABS Canbus",
  },
  [AssetABSFields.ABSConfiguration]: {
    name: "metadata.absConfiguration",
    label: "ABS Configuration",
  },
  [AssetABSFields.ABSManufacturer]: {
    name: "metadata.absManufacturer",
    label: "ABS Manufacturer",
  },
  [AssetABSFields.ABSModel]: {
    name: "metadata.absModel",
    label: "ABS Model",
  },
  [AssetABSFields.ABSYear]: {
    name: "metadata.absYear",
    label: "ABS Year",
  },
};

export const ASSET_ATIS_FORM_FIELDS: AssetATISFormFields = {
  [AssetATISFields.ATIS]: {
    name: "metadata.atis",
    label: "ATIS",
  },
  [AssetATISFields.ATISManufacturer]: {
    name: "metadata.atisManufacturer",
    label: "ATIS Manufacturer",
  },
  [AssetATISFields.ATISModel]: {
    name: "metadata.atisModel",
    label: "ATIS Model",
  },
};
export const ASSET_OTHER_FORM_FIELDS: AssetOtherFormFields = {
  [AssetOtherFields.LightWeight]: {
    name: "metadata.lightWeight",
    label: "Light Weight",
  },
  [AssetOtherFields.TTPairCapable]: {
    name: "metadata.ttPairCapable",
    label: "T-T Pair Capable",
  },
  [AssetOtherFields.AeroKitType]: {
    name: "metadata.aeroKitType",
    label: "Aero Kit Type",
  },
  [AssetOtherFields.BatteryCharger]: {
    name: "metadata.batteryCharger",
    label: "Battery Charger",
  },
  [AssetOtherFields.DoorLatchType]: {
    name: "metadata.doorLatchType",
    label: "Door Latch Type",
  },
  [AssetOtherFields.DoorLatchManufacturer]: {
    name: "metadata.doorLatchManufacturer",
    label: "Door Latch Manufacturer",
  },
  [AssetOtherFields.ElectricalHarnessManufacturer]: {
    name: "metadata.electricalHarnessManufacturer",
    label: "Electrical Harness Manufacturer",
  },
  [AssetOtherFields.ElectronicLandingGear]: {
    name: "metadata.electronicLandingGear",
    label: "Electronic Landing Gear",
  },
  [AssetOtherFields.Etrack]: {
    name: "metadata.etrack",
    label: "Etrack",
  },
  [AssetOtherFields.GladhandManufacturer]: {
    name: "metadata.gladhandManufacturer",
    label: "Gladhand Manufacturer",
  },
  [AssetOtherFields.GladhandType]: {
    name: "metadata.gladhandType",
    label: "Gladhand Type",
  },
  [AssetOtherFields.HowIsNoseboxMounted]: {
    name: "metadata.howIsNoseboxMounted",
    label: "Nosebox Mounted",
  },
  [AssetOtherFields.InnerLinerAvailable]: {
    name: "metadata.innerLinerAvailable",
    label: "Inner liner available",
  },
  [AssetOtherFields.NosePostDepth]: {
    name: "metadata.nosePostDepth",
    label: "Nose Post Depth",
  },
  [AssetOtherFields.InteriorDropDownCargoSystem]: {
    name: "metadata.interiorDropDownCargoSystem",
    label: "Interior Drop Down Cargo System",
  },
  [AssetOtherFields.KingpinOffset]: {
    name: "metadata.kingpinOffset",
    label: "Kingpin Offset",
  },
  [AssetOtherFields.LandingGearManufacturer]: {
    name: "metadata.landingGearManufacturer",
    label: "Landing Gear Manufacturer",
  },
  [AssetOtherFields.LandingGearModel]: {
    name: "metadata.landingGearModel",
    label: "Landing Gear Model",
  },
  [AssetOtherFields.LandingGearModelRating]: {
    name: "metadata.landingGearModelRating",
    label: "Landing Gear Model Rating",
  },
  [AssetOtherFields.LightingManufacture]: {
    name: "metadata.lightingManufacture",
    label: "Lighting Manufacturer",
  },
  [AssetOtherFields.PreferredMidturnLightPartNumber]: {
    name: "metadata.preferredMidturnLightPartNumber",
    label: "Preferred Mid-turn light Part #",
  },
  [AssetOtherFields.PreferredTailLightPartNumber]: {
    name: "metadata.preferredTailLightPartNumber",
    label: "Preferred Tail light Part #",
  },
  [AssetOtherFields.LogisticsPosts]: {
    name: "metadata.logisticsPosts",
    label: "Logistics Posts",
  },
  [AssetOtherFields.NoseTypeBody]: {
    name: "metadata.noseTypeBody",
    label: "Nose Type (Body)",
  },
  [AssetOtherFields.NoseboxManufacturer]: {
    name: "metadata.noseboxManufacturer",
    label: "Nosebox Manufacturer",
  },
  [AssetOtherFields.NoseboxModel]: {
    name: "metadata.noseboxModel",
    label: "Nosebox Model",
  },
  [AssetOtherFields.Permalogic]: {
    name: "metadata.permalogic",
    label: "Permalogic available",
  },
  [AssetOtherFields.RearAero]: {
    name: "metadata.rearAero",
    label: "Rear Aero",
  },
  [AssetOtherFields.RearAeroManufacturer]: {
    name: "metadata.rearAeroManufacturer",
    label: "Rear Aero Manufacturer",
  },
  [AssetOtherFields.RoadtrainAllowed]: {
    name: "metadata.roadtrainAllowed",
    label: "Roadtrain Allowed",
  },
  [AssetOtherFields.Skirt]: {
    name: "metadata.skirt",
    label: "Skirt",
  },
  [AssetOtherFields.SkirtManufacturer]: {
    name: "metadata.skirtManufacturer",
    label: "Skirt Manufacturer",
  },
  [AssetOtherFields.SuspensionManufacturer]: {
    name: "metadata.suspensionManufacturer",
    label: "Suspension Manufacturer",
  },
  [AssetOtherFields.SuspensionModel]: {
    name: "metadata.suspensionModel",
    label: "Suspension Model",
  },
  [AssetOtherFields.SuspensionType]: {
    name: "metadata.suspensionType",
    label: "Suspension Type",
  },
  [AssetOtherFields.SuspensionYear]: {
    name: "metadata.suspensionYear",
    label: "Suspension Year",
  },
  [AssetOtherFields.TireSize]: {
    name: "metadata.tireSize",
    label: "Tire Size",
  },
  [AssetOtherFields.TireRimType]: {
    name: "metadata.tireRimType",
    label: "Tire Rim Type",
  },
};

export const ASSET_FORM_FIELDS_MAPPING: Partial<FormField>[] = [
  ...ASSET_FORM_FIELDS,
  ...Object.values(ASSET_SUMMARY_FORM_FIELDS),
  ...Object.values(ASSET_DETAILS_FORM_FIELDS),
  ...Object.values(ASSET_LIFT_FORM_FIELDS),
  ...Object.values(ASSET_TCU_FORM_FIELDS),
  ...Object.values(ASSET_RAIL_COMPLIANCE_FORM_FIELDS),
  ...Object.values(ASSET_ABS_FORM_FIELDS),
  ...Object.values(ASSET_ATIS_FORM_FIELDS),
  ...Object.values(ASSET_OTHER_FORM_FIELDS),
];

export const mapFieldsLabel = (fieldName: string): string => {
  switch (fieldName) {
    // Note: Apply custom mappings for specific fields here
    // case "fieldName":
    //   return "Custom Label";
    default: {
      const customMapping = ASSET_FORM_FIELDS_MAPPING.find(
        (field: Partial<FormField>) => field?.name === fieldName
      );

      if (customMapping?.label) {
        return customMapping.label;
      }

      // If we don't have a particular mapping for the change, apply the following enhancements:
      // - make sure to capitalize the first letter
      // - remove underscores
      // - remove the where the string is camel cased
      return fieldName
        .replace(/_/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }
  }
};
