import { FC, useCallback, useMemo } from "react";
import { Circle } from "@mui/icons-material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  Chip,
  Stack,
  TextField,
  ThemeProvider,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { capitalize } from "lodash";
import { nanoid } from "nanoid";
import { ReactComponent as CargoSensorDark } from "../../../../../assets/svgs/cargoSensorDark.svg";
import { ReactComponent as CargoSensorLight } from "../../../../../assets/svgs/cargoSensorLight.svg";
import {
  DEFAULT_DATE_RANGE_OPTIONS,
  OTHER_SENSORS_GRAPH_OPTIONS,
} from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import { SensorStatus } from "../../../../../graphql/operations";
import SensorHeader from "../../../../../shared/components/SensorHeader/SensorHeader";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import useChartExpand from "../../../../../shared/hooks/useChartExpand";
import {
  DATE_FORMAT,
  formatDateWithoutTimeZone,
  getDayjs,
  getIsSameOrAfter,
  getIsSameOrBefore,
  getSubMonths,
} from "../../../../../utils/date";
import { CargoState } from "../../Assets/AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import {
  CargoData,
  getBoxBorderColor,
  getBoxColor,
} from "../../Assets/CargoTabPanel/CargoStatus/utils";
import { HOURS_MAP } from "../../Assets/CargoTabPanel/InternalCameraSpaceGraph/constants";
import { useBrakesTabTheme } from "../BrakesTab/useBrakesTabTheme";

const dayjs = getDayjs();
const isSameOrBefore = getIsSameOrBefore();
const isSameOrAfter = getIsSameOrAfter();

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export interface CargoTrendingGraphProps {
  data: CargoData[];
  allowResize?: boolean;
  startDateCargo: Date | null;
  endDateCargo: Date | null;
  setEndDateCargo: (date: Date | null) => void;
  setStartDateCargo: (date: Date | null) => void;
  selectedDateRangeOptionCargo: number;
  setSelectedDateRangeOptionCargo: (value: number) => void;
}

const SVG_ICON_STYLES = {
  width: "5rem",
  height: "5rem",
  display: "block",
};

const LEGEND_KEYS = [CargoState.Loaded, CargoState.Empty, SensorStatus.Unknown];
const LEGEND_LABELS = LEGEND_KEYS.map((key) => capitalize(key));
const CUSTOM_DATE_RANGE_OPTION = OTHER_SENSORS_GRAPH_OPTIONS.find(
  ({ label }) => label?.toLowerCase() === "custom"
);

const CargoTrendingGraph: FC<CargoTrendingGraphProps> = ({
  data,
  startDateCargo,
  endDateCargo,
  setStartDateCargo,
  setEndDateCargo,
  selectedDateRangeOptionCargo,
  setSelectedDateRangeOptionCargo,
  allowResize = true,
}) => {
  // Global state
  const {
    state: { theme, appConfig },
  } = useAppContext();
  const isLightTheme = useMemo(() => theme?.theme === "light", [theme?.theme]);
  const isDesktop = useBreakpoint("up", "lg");
  const brakesTabTheme = useBrakesTabTheme();
  const { gridWidth, gridWidthIndicator, handleToggleGridWidth } =
    useChartExpand();

  // Memos
  const legendColors = useMemo(
    () =>
      isLightTheme
        ? ["var(--brand-light-blue)", "var(--off-white)", "var(--border-color)"]
        : ["var(--brand-light-blue)", "var(--border)", "var(--dark-grey)"],
    [isLightTheme]
  );

  const parsedData = useMemo(() => {
    // The incoming data is already sorted by the query so we just need to reverse it
    const sorted = data.slice().reverse();
    return sorted.filter((event) => {
      const eventDate = dayjs(event?.eventHistoryData?.date?.split("T")[0]);

      return (
        eventDate.isSameOrAfter(startDateCargo, "date") &&
        eventDate.isSameOrBefore(endDateCargo, "date")
      );
    });
  }, [data, startDateCargo, endDateCargo]);

  // Callbacks and handlers
  const setCustomDateRangeOption = useCallback(() => {
    setSelectedDateRangeOptionCargo(CUSTOM_DATE_RANGE_OPTION?.value!);
  }, [setSelectedDateRangeOptionCargo]);

  const handleDateRangeChange = useCallback(
    (value: number) => {
      setSelectedDateRangeOptionCargo(value);
      const option = DEFAULT_DATE_RANGE_OPTIONS.find(
        (option) => option.value === value
      );

      if (option?.getRange) {
        const [startDate, endDate] = option.getRange();

        setStartDateCargo(startDate);
        setEndDateCargo(endDate);
      }
    },
    [setSelectedDateRangeOptionCargo, setStartDateCargo, setEndDateCargo]
  );

  const minAllowedDate = getSubMonths(
    new Date(),
    appConfig.monthsBackDateLimit
  );

  // cargo-trend-expand-icon
  return (
    <Grid
      item
      lg={gridWidth}
      className={isDesktop ? "block" : "hidden"}
      data-testid="cargo-trending-graph"
    >
      <Box className="h-full w-full flex flex-col p-5 bg-dashboard_subheader__bg">
        <Stack direction="row" justifyContent="end">
          <SensorHeader title="Cargo Trending Graph" noBorder>
            {isLightTheme ? (
              <CargoSensorDark style={SVG_ICON_STYLES} />
            ) : (
              <CargoSensorLight style={SVG_ICON_STYLES} />
            )}
          </SensorHeader>
          {allowResize && (
            <IconButton
              sx={{
                width: "2rem",
                height: "1rem",
                backgroundColor: "transparent!important",
              }}
              aria-label="toggleWidth-cargo"
              data-testid="toggleWidth-cargo"
              onClick={() => handleToggleGridWidth()}
            >
              <OpenInFullIcon
                fontSize="medium"
                style={{
                  fill: "var(--cargo-trend-expand-icon)",
                  marginRight: "0.313rem",
                }}
              />
              <span
                className="font-bold text-sm leading-4 text-brand"
                style={{ marginTop: "-0.5rem" }}
              >
                {gridWidthIndicator}
              </span>
            </IconButton>
          )}
        </Stack>
        <ThemeProvider theme={brakesTabTheme}>
          <Box className="flex items-center justify-end gap-4 py-4">
            <MobileDatePicker
              label="Start date"
              inputFormat={DATE_FORMAT}
              closeOnSelect
              value={startDateCargo}
              minDate={minAllowedDate}
              disableFuture
              onChange={(date) => {
                if (date) {
                  setStartDateCargo(date);
                  setCustomDateRangeOption();
                }
              }}
              DialogProps={{
                className: "cargo-trending-date-picker",
              }}
              renderInput={(params) => (
                <TextField
                  data-testid="cargo-chart-start-date"
                  variant="outlined"
                  {...params}
                />
              )}
            />
            <span className="text-base font-normal text-primary">to</span>
            <MobileDatePicker
              DialogProps={{
                className: "trending-voltage-date-picker",
              }}
              label="End date"
              closeOnSelect
              inputFormat={DATE_FORMAT}
              value={endDateCargo}
              minDate={minAllowedDate}
              disableFuture
              onChange={(date) => {
                if (date) {
                  setEndDateCargo(date);
                  setCustomDateRangeOption();
                }
              }}
              renderInput={(params) => (
                <TextField
                  data-testid="cargo-chart-end-date"
                  variant="outlined"
                  {...params}
                />
              )}
            />

            <TextField
              data-testid="cargo-chart-date-range-select"
              select
              value={selectedDateRangeOptionCargo}
              variant="outlined"
              className="w-40"
              onChange={(e) => handleDateRangeChange(Number(e.target.value))}
            >
              {Object.entries(DEFAULT_DATE_RANGE_OPTIONS).map(
                ([key, option]) => (
                  <MenuItem
                    key={key}
                    value={option.value}
                    data-testid={`cargo-chart-date-range-select-option-${option.value}`}
                  >
                    {option.label}
                  </MenuItem>
                )
              )}
            </TextField>
          </Box>
        </ThemeProvider>
        <Box
          className="flex font-medium text-[10px] overflow-auto"
          data-testid="cargo-trending-graph-wrapper"
        >
          <Box
            className="flex flex-col mr-2 pt-1 text-card-sensors-text"
            data-testid="cargo-trending-graph-hour-list"
          >
            {HOURS_MAP.map((hour) => (
              <Box
                key={nanoid()}
                className="h-[36px] mb-2 flex items-center justify-end"
              >
                {hour}
              </Box>
            ))}
          </Box>

          <Box
            className="flex h-full w-full"
            data-testid="cargo-trending-graph-content"
          >
            {(parsedData ?? []).map((day) => (
              <Box className="w-full" key={nanoid()}>
                {!!day?.eventHistoryData?.cargo?.cargoStats &&
                  day.eventHistoryData.cargo.cargoStats
                    .slice()
                    .sort((statA, statB) =>
                      (statA?.hour ?? 0) > (statB?.hour ?? 0) ? -1 : 1
                    )
                    .map((hour) => (
                      <Box
                        key={nanoid()}
                        className={`h-[36px] flex flex-1 items-center justify-center m-1 mb-2 rounded ${getBoxColor(
                          hour?.loadedEvents ?? 0
                        )}`}
                        sx={{
                          borderColor: getBoxBorderColor(
                            hour?.loadedEvents ?? 0
                          ),
                        }}
                      />
                    ))}

                <Box
                  className="mt-4 text-center text-card-sensors-text"
                  data-testid="cargo-trending-graph-day"
                >
                  {formatDateWithoutTimeZone(day?.eventHistoryData?.date)}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          className="mt-8 flex justify-center items-center"
          data-testid="cargo-trending-graph-legend"
        >
          {LEGEND_KEYS.map((key, index) => (
            <Chip
              key={key}
              label={LEGEND_LABELS[index]}
              icon={
                <Circle
                  sx={{
                    width: "16px",
                    height: "16px",
                    fill: legendColors[index],
                    borderRadius: "50%",
                  }}
                />
              }
              sx={{
                height: "1.5rem",
                color: ColorsPalette.BrightWhite,
                borderRadius: "9999px",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                backgroundColor: "var(--grayscale-feather-gray)",
                "& .MuiChip-label": {
                  color: "var(--mid-charcoal)",
                },
              }}
            />
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default CargoTrendingGraph;
