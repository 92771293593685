import { MutationKey, UseMutationOptions } from "@tanstack/react-query";
import {
  StopAssetsShareMutationVariables,
  StopAssetsShareOsMutation,
  useStopAssetsShareOsMutation,
} from "../../../graphql/operations";

type StopAssetsShareMutationOptions = UseMutationOptions<
  StopAssetsShareOsMutation,
  unknown,
  StopAssetsShareMutationVariables,
  MutationKey
>;

export const useStopAssetsShare = (options: StopAssetsShareMutationOptions) => {
  const { mutate, mutateAsync, isLoading } =
    useStopAssetsShareOsMutation(options);

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};
