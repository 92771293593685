import { useCallback, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button as MUIButton, Grid, ThemeProvider, Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { cloneDeep, isEmpty } from "lodash";
import { PAGE_SNACKBAR } from "../../../../constants";
import {
  DEVICE_FORM_FIELDS,
  DEVICE_FORM_NO_CHANGES,
  DEVICE_UPDATE_FAILED_PAYLOAD,
  DEVICE_UPDATE_SUCCESS_PAYLOAD,
} from "../../../../constants/device";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import {
  Asset,
  DeviceData,
  DeviceDataInput,
  FindOrgsQuery,
  Maybe,
  useFindOrgsQuery,
  useGetUnassociatedAssetsByOrgIdOsQuery,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
} from "../../../../graphql/operations";
import { Button } from "../../../../shared/components/Button";
import { ConfirmationDialog } from "../../../../shared/components/ConfirmationDialog";
import { useFormTheme } from "../../../../shared/hooks/theme/useFormTheme";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { CreatableAutocomplete } from "../../../AssetsView/TableView/components/AssetForm/components";
import { handleDataInvalidation } from "../../deviceUtils";
import { useDeviceSettingsForm } from "./useDeviceSettingsForm";
import { onErrorDelete, onSuccessDelete } from "./utils";

interface DeviceDashboardProps {
  device: DeviceData;
}

type EditDevicePayload = Omit<DeviceDataInput, "tags"> & {
  tags: string;
};

export const DeviceSettings = ({ device }: DeviceDashboardProps) => {
  const formTheme = useFormTheme();
  const { userRolePermissions } = useAuthContext();

  const { form } = useDeviceSettingsForm(device);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const { dispatch } = useAppContext();
  const {
    customerOrgIdInput,
    imeiInput,
    assetIdInput,
    boxIdInput,
    tagsInput,
    orderNumInput,
    simNumInput,
    packingListInput,
  } = DEVICE_FORM_FIELDS;
  const fieldLength = { xs: 12, sm: 6, md: 6, lg: 3 };
  const hasCreateEditAccess =
    userRolePermissions.device.create || userRolePermissions.device.edit;

  const selected_org_id = form.watch("customer_orgs_id");

  const { data: orgData, refetch: refetchOrgData } = useFindOrgsQuery(
    {},
    {
      staleTime: 900000, // 15 minutes
    }
  );

  const { mutate: updateDeviceMutate } = useUpdateDeviceMutation({
    onSuccess: async () => {
      queryClient.invalidateQueries(["findDeviceById"]);
      setTimeout(async () => {
        await handleDataInvalidation(queryClient, refetchOrgData);
        form.reset();
        setIsLoading(false);
        dispatch({
          type: PAGE_SNACKBAR,
          payload: DEVICE_UPDATE_SUCCESS_PAYLOAD,
        });
      }, 0);
    },
    onError: () => {
      setIsLoading(false);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          ...DEVICE_UPDATE_FAILED_PAYLOAD,
          text: DEVICE_UPDATE_FAILED_PAYLOAD.text,
        },
      });
    },
  });

  const { mutate: deleteDeviceMutate, isLoading: isDeleting } =
    useDeleteDeviceMutation({
      onSuccess: () => onSuccessDelete(navigate, dispatch),
      onError: () => onErrorDelete(dispatch),
    });

  const { data: unassociatedAssetsData, isLoading: assetsLoading } =
    useGetUnassociatedAssetsByOrgIdOsQuery(
      { input: { orgId: selected_org_id as string } },
      { cacheTime: 0, enabled: Boolean(selected_org_id) }
    );

  const unassociatedAssets = useMemo(
    () => unassociatedAssetsData?.getUnassociatedAssetsByOrgIdOS ?? [],
    [unassociatedAssetsData]
  );

  const loadUnassociatedAssets = useCallback(
    (editDeviceData: DeviceDataInput, assets: Asset[]) => {
      const additionalAsset =
        editDeviceData.assets_id && editDeviceData.asset_name
          ? [
              {
                _id: editDeviceData.assets_id,
                asset_id: editDeviceData.asset_name,
              },
            ]
          : [];

      const combinedAssets = [...assets, ...additionalAsset].map(
        (asset: Partial<Asset>) => {
          return {
            id: asset._id,
            label: asset.asset_id,
            asset_id: asset.asset_id,
            name: asset.name ?? "",
          };
        }
      );

      return combinedAssets;
    },
    []
  );

  //assets
  const assetOptions = useMemo(
    () =>
      loadUnassociatedAssets(
        cloneDeep(device) ?? ({} as DeviceDataInput),
        unassociatedAssets ?? []
      ),
    [device, loadUnassociatedAssets, unassociatedAssets]
  );

  //tags
  const getOrganizationTags = (
    orgs: FindOrgsQuery | undefined,
    selected_org_id: string
  ) => {
    let tags: { label: string | null; id: string | null }[] = [];
    if (selected_org_id) {
      const org = (orgs?.findOrgs || []).find((x) => x._id === selected_org_id);
      tags = (org?.device_tags || []).map((tag) => {
        return { label: tag, id: tag };
      });
    }
    return tags;
  };
  const tagOptions: { label: string | null; id: string | null }[] = useMemo<
    any | null
  >(() => {
    return getOrganizationTags(orgData, selected_org_id as string);
  }, [orgData, selected_org_id]);

  //organizations
  const orgOptions = useMemo(
    () =>
      (orgData?.findOrgs || [])
        .map((org) => {
          return { value: org._id, id: org._id, label: org.name ?? "" };
        })
        .sort((org1, org2) => (org1.label < org2.label ? -1 : 1)),
    [orgData?.findOrgs]
  );

  const enrichFormData = (
    data: EditDevicePayload,
    allOrgs: { value: string; id: string; label: string }[],
    allAssets: {
      id: string | undefined;
      label: Maybe<string> | undefined;
      asset_id: Maybe<string> | undefined;
      name: string;
    }[]
  ) => {
    const currentOrg = (allOrgs as any[]).find(
      (org: any) => org.id === data.customer_orgs_id
    );
    const currentAsset = allAssets.find(
      (asset: any) => asset.id === data.assets_id
    );
    data.org_name = currentOrg.label ?? "";
    data.asset_name = currentAsset?.name ?? "";
    return data;
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleSave = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;

    const data = form.getValues();

    const preparedData = enrichFormData(
      data as EditDevicePayload,
      orgOptions,
      assetOptions
    );
    const { dirtyFields } = form.formState;

    if (Object.keys(dirtyFields).length === 0) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: DEVICE_FORM_NO_CHANGES,
      });
      return;
    }
    const payload: Partial<DeviceDataInput> = {};
    for (const field in dirtyFields) {
      // @ts-ignore
      payload[field] = preparedData[field];
      payload["_id"] = preparedData._id;
      switch (field) {
        case "assets_id":
          payload["assets_id"] = preparedData?.assets_id ?? null;
          payload["asset_name"] = preparedData?.asset_name ?? null;
          break;
        case "customer_orgs_id":
          payload["customer_orgs_id"] = preparedData?.customer_orgs_id;
          payload["org_name"] = preparedData?.org_name;
          break;
        case "tags":
          if (preparedData?.tags?.length > 0) {
            payload["tags"] = preparedData?.tags?.split(",");
          } else {
            payload["tags"] = [];
          }
          break;
      }
    }
    updateDeviceMutate({ input: payload as DeviceDataInput });
    setIsLoading(true);
  };

  const handleCancel = () => {
    form.reset();
  };

  const handleDelete = () => {
    openDeleteDialog();
  };

  const confirmDeleteDevice = () => {
    setIsDeleteDialogOpen(false);
    deleteDeviceMutate({
      input: {
        _id: device._id,
        imei: device.imei ?? "",
      },
    });
  };

  const cancelDeleteDevice = () => {
    setIsDeleteDialogOpen(false);
  };

  useSpinner(isLoading || isDeleting);

  const buttonDisabled = useMemo(
    () => isEmpty(form.formState.dirtyFields),
    [form.formState]
  );

  return (
    <ThemeProvider theme={formTheme}>
      <form data-testid="device-edit-form" className="mt-8">
        <Grid container className="px-6" spacing={6}>
          <Grid item {...fieldLength} data-testid={customerOrgIdInput.id}>
            {/* Organization */}
            <AutocompleteElement
              autocompleteProps={{
                disabled: Boolean(!hasCreateEditAccess),
                readOnly: Boolean(!hasCreateEditAccess),
              }}
              matchId={true}
              control={form.control}
              loading={!orgOptions?.length}
              rules={{ required: customerOrgIdInput.required }}
              label={customerOrgIdInput.label}
              name={customerOrgIdInput.name as never}
              options={orgOptions}
            />
          </Grid>

          {/* Asset Id */}
          <Grid item {...fieldLength} data-testid={assetIdInput.id}>
            <AutocompleteElement
              autocompleteProps={{
                disabled: Boolean(!hasCreateEditAccess),
                readOnly: Boolean(!hasCreateEditAccess),
              }}
              matchId={true}
              loading={Boolean(assetsLoading && selected_org_id)}
              label={assetIdInput.label}
              control={form.control}
              name={assetIdInput.name as never}
              options={assetOptions}
            />
          </Grid>

          {/* Tags */}
          <Grid item {...fieldLength} data-testid={tagsInput.id}>
            <Controller
              name={tagsInput.name as never}
              control={form.control}
              defaultValue={undefined}
              render={({ field }) => {
                return (
                  <CreatableAutocomplete
                    disabled={Boolean(!hasCreateEditAccess)}
                    name={tagsInput.name}
                    label={tagsInput.label}
                    field={field}
                    errors={form.formState.errors}
                    options={tagOptions as any}
                  />
                );
              }}
            />
          </Grid>

          {/* Order # */}
          <Grid item {...fieldLength}>
            <TextFieldElement
              fullWidth
              disabled={Boolean(!hasCreateEditAccess)}
              control={form.control}
              required
              name={orderNumInput.name as never}
              label={orderNumInput.label}
            />
          </Grid>

          {/* Order Group */}
          <Grid item {...fieldLength}>
            <TextFieldElement
              fullWidth
              disabled={Boolean(!hasCreateEditAccess)}
              control={form.control}
              name={boxIdInput.name as never}
              label={boxIdInput.label}
            />
          </Grid>

          {/* SIM ID */}
          <Grid item {...fieldLength}>
            <TextFieldElement
              fullWidth
              disabled={Boolean(!hasCreateEditAccess)}
              control={form.control}
              name={simNumInput.name as never}
              label={simNumInput.label}
            />
          </Grid>

          {/* Packing List */}
          <Grid item {...fieldLength}>
            <TextFieldElement
              fullWidth
              control={form.control}
              disabled={Boolean(!hasCreateEditAccess)}
              name={packingListInput.name as never}
              label={packingListInput.label}
            />
          </Grid>

          {/* imei */}
          <Grid item {...fieldLength} data-testid={imeiInput.testId}>
            <TextFieldElement
              fullWidth
              disabled={Boolean(!hasCreateEditAccess)}
              control={form.control}
              name={imeiInput.name as never}
              required
              label={imeiInput.label}
            />
          </Grid>
        </Grid>
      </form>
      <Grid item className="px-6 py-6 flex justify-between items-baseline">
        <MUIButton
          className="global-text-btn global-text-btn--medium global-text-btn__theme--red !text-md !px-3"
          onClick={handleDelete}
          color="error"
          size="small"
          data-testid="delete-device-button"
          startIcon={<DeleteOutlineIcon fontSize="medium" />}
          sx={{
            "&:disabled": {
              opacity: "1 !important",
            },
          }}
        >
          Deactivate Device
        </MUIButton>
        <Box>
          <MUIButton
            className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !text-sm !px-3 !mr-5"
            disabled={buttonDisabled}
            onClick={handleCancel}
            color="inherit"
            size="small"
            data-testid="cancel-device-button"
          >
            Reset
          </MUIButton>
          <Button
            dataTestid="save-device-button"
            className="!text-sm"
            disabled={buttonDisabled}
            text={isLoading ? "Saving" : "Save"}
            size="medium"
            theme="blue"
            variant="default"
            type="button"
            onClick={handleSave}
          />
        </Box>
        {isDeleteDialogOpen && (
          <ConfirmationDialog
            title="You are about to deactivate a Device"
            message="Are you sure?"
            open={isDeleteDialogOpen}
            confirmButtonText="Confirm"
            cancelButtonText="Cancel"
            handleConfirmationResult={(isConfirmed) => {
              if (isConfirmed) {
                confirmDeleteDevice();
              } else {
                cancelDeleteDevice();
              }
            }}
          />
        )}
      </Grid>
    </ThemeProvider>
  );
};
