import { ReactElement } from "react";
import classNames from "classnames";

interface NavListItemProps {
  // Use children prop if you need full control of the render
  children?: ReactElement;
  text?: string;
  onClick?: (event: any) => void;
  onMouseDown?: (event: any) => void;
  className?: string;
  id?: string;
}

const NavListItem = (props: NavListItemProps) => {
  const { children, text, onClick, className, id, onMouseDown } = props;

  return (
    <div
      id={id}
      onMouseDown={onMouseDown}
      onClick={onClick}
      onKeyDown={onClick}
      className={classNames(
        className,
        "px-2 py-3 text-typography hover:bg-brand-transparent cursor-pointer font-bold"
      )}
    >
      {children ?? <span> {text} </span>}
    </div>
  );
};

export default NavListItem;
