import { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { Box, Button, IconButton, Skeleton, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR, ROOT_ORGANIZATION_NAME } from "../../../../constants";
import { USER_UPDATE_FAILED_PAYLOAD } from "../../../../constants/users";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import {
  Dashboard,
  DashboardType,
  MeUserData,
  useUpdateMyAccountMutation,
} from "../../../../graphql/operations";
import {
  isAdmin,
  isSuperAdmin,
} from "../../../../shared/components/WithPermissions";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import { useUserData } from "../../../../shared/hooks/useUserData";
import { toggleFavoriteDashboard } from "../../DashboardsTable/utils";
import { FavoriteDashboardsDropdown } from "../../FavoriteDashboardsDropdown/FavoriteDashboardsDropdown";
import {
  DashboardActionsMenu,
  DashboardActionsMenuOption,
} from "./DashboardActionsMenu";
import DefaultDashboardButton from "./buttons/DefaultDashboardButton";
import FavoriteButton from "./buttons/FavoriteButton";

export interface HeaderProps {
  dashboard: Dashboard | undefined;
  setEditDashboardPopupOpened: React.Dispatch<React.SetStateAction<boolean>>;
  showWidgetsDrawer: () => void;
  isLoading: boolean;
}

const getUserFavoriteDashboards = (userData?: MeUserData) =>
  (userData?.user_preferences?.favorite_dashboards ?? []).filter(
    (id): id is number => id !== null && id !== undefined
  );

const Header: React.FC<HeaderProps> = ({
  dashboard,
  setEditDashboardPopupOpened,
  showWidgetsDrawer,
  isLoading,
}) => {
  const isMobile = useBreakpoint("down", "sm");
  const userData = useUserData();
  const { userInfo } = useAuthContext();
  const currentUserOrganization = useCurrentOrg();
  const isAnyAdmin = useMemo(
    () =>
      isAdmin(userInfo?.groups ?? []) || isSuperAdmin(userInfo?.groups ?? []),
    [userInfo?.groups]
  );

  const isUserAllowedToModify = useMemo(() => {
    const isCustomDashboard = dashboard?.type === DashboardType.Custom;
    const isPhillipsConnectMember =
      currentUserOrganization?.name === ROOT_ORGANIZATION_NAME ||
      currentUserOrganization?.org_key === ROOT_ORGANIZATION_NAME;
    const isUserPhillipsConnectAdmin = isPhillipsConnectMember && isAnyAdmin;
    const isAdminAllowedModifying = isCustomDashboard && isAnyAdmin;
    const hasUserCreatedDashboard = dashboard?.userId === userData?._id;

    return (
      isUserPhillipsConnectAdmin ||
      isAdminAllowedModifying ||
      hasUserCreatedDashboard
    );
  }, [currentUserOrganization, dashboard, isAnyAdmin, userData]);

  const isDashboardFavoriteForTheUserOrganization = useMemo(
    () =>
      (currentUserOrganization?.favorite_dashboards ?? []).includes(
        dashboard?.id ?? null
      ),
    [currentUserOrganization?.favorite_dashboards, dashboard?.id]
  );
  const queryClient = useQueryClient();
  const [favoriteDashboards, setFavoriteDashboards] = useState<number[]>(
    getUserFavoriteDashboards(userData)
  );
  // Sync favorite dashboards from user preferences and local state
  useEffect(() => {
    setFavoriteDashboards((current: number[]): number[] => {
      const newFavoriteDashboards = new Set([
        ...current,
        ...getUserFavoriteDashboards(userData),
      ]);
      return [...newFavoriteDashboards];
    });
  }, [userData, setFavoriteDashboards]);

  const { dispatch } = useAppContext();
  const { mutate } = useUpdateMyAccountMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["getUserData"]);
    },
    onError: (error: Error | null) => {
      const errorMessage = error ? error.message : "Something Went Wrong.";
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          ...USER_UPDATE_FAILED_PAYLOAD,
          text: errorMessage,
        },
      });
    },
  });

  const isDashboardFavorite = useMemo(() => {
    const isDashboardUserFavorite = favoriteDashboards?.includes(
      dashboard?.id as number
    );

    return isDashboardUserFavorite || isDashboardFavoriteForTheUserOrganization;
  }, [
    favoriteDashboards,
    dashboard?.id,
    isDashboardFavoriteForTheUserOrganization,
  ]);

  const toggleFavoriteDashboardHandler = () => {
    toggleFavoriteDashboard(
      favoriteDashboards,
      dashboard?.id as number,
      setFavoriteDashboards,
      mutate,
      userData
    );
  };

  const dashboardMenuOptions: DashboardActionsMenuOption[] = [
    {
      title: "Add New Widget",
      icon: <AddIcon />,
      disabled: isLoading || !isUserAllowedToModify,
      onClick: showWidgetsDrawer,
    },
    {
      title: "Set as Default",
      icon: <DomainVerificationIcon />,
      disabled: true, // TODO: use this option when the `Set as Default` feature is implemented
      onClick: () => {},
    },
    {
      title: isDashboardFavorite ? "Remove from Favorites" : "Add to Favorites",
      icon: isDashboardFavorite ? <StarIcon /> : <StarOutlineIcon />,
      disabled: isDashboardFavoriteForTheUserOrganization,
      onClick: toggleFavoriteDashboardHandler,
    },
    {
      title: "Edit",
      icon: <EditOutlinedIcon />,
      disabled: isLoading || !isUserAllowedToModify,
      onClick: () => setEditDashboardPopupOpened(true),
    },
  ];

  return (
    <Box
      data-testid="page-dashboard-header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: isLoading ? "center" : "baseline",
        backgroundColor: "var(--dashboard-view-header-background)",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {isLoading ? (
          <Box className="py-5 mx-3">
            <Skeleton variant="rounded" width={250} height={32} />
          </Box>
        ) : (
          <Typography
            sx={{
              padding: "1.5rem 1rem",
              fontSize: "1.5rem",
              lineHeight: "2rem",
              fontWeight: "600",
              color: "var(--header-text)",
            }}
          >
            {dashboard?.name ?? ""}
          </Typography>
        )}
        {!isMobile && (
          <Box
            className="h-20"
            sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {!isLoading && isUserAllowedToModify && (
              <IconButton
                size="large"
                onClick={() => {
                  setEditDashboardPopupOpened(true);
                }}
                className="!p-1 !mr-1"
                data-testid="edit-dashboard-button"
              >
                <EditOutlinedIcon className="text-primary" />
              </IconButton>
            )}
            <FavoriteButton
              dashboard={dashboard}
              isDashboardFavoriteForTheUserOrganization={
                isDashboardFavoriteForTheUserOrganization
              }
              favoriteDashboards={favoriteDashboards}
              toggleFavoriteDashboard={toggleFavoriteDashboardHandler}
            />
            <DefaultDashboardButton
              dashboard={dashboard}
              mutateUserPreferences={mutate}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex" }}>
        {!isLoading && isUserAllowedToModify && !isMobile && (
          <Button
            className="!font-bold !text-base"
            sx={{
              color: "var(--brand)",
              textTransform: "none",
            }}
            data-testid="add-widget-button"
            disabled={false}
            variant="text"
            type="button"
            onClick={showWidgetsDrawer}
          >
            <AddIcon />
            <Typography className="!font-bold !text-base">
              Add New Widget
            </Typography>
          </Button>
        )}
        {isMobile && <DashboardActionsMenu options={dashboardMenuOptions} />}
        <FavoriteDashboardsDropdown />
      </Box>
    </Box>
  );
};
export default Header;
