import { FC, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { useAppContext } from "../../../../context/AppContext";
import {
  SensorStatus,
  useFindAssetsByIdsForSharingOsQuery,
} from "../../../../graphql/operations";
import { useFindAssetListOptions } from "../../../../shared/hooks/useFindAssetListOptions";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import { AlertFormValues } from "../../interfaces";
import {
  getAlertMultiselectIds,
  isFindAssetByIdQueryEnabled,
  onFindAssetsOsSettledCallback,
} from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface AlertATISParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
export const AlertATISParameters: FC<AlertATISParametersProps> = ({
  form,
}: AlertATISParametersProps) => {
  const healthStateOptions = [
    { id: SensorStatus.Warning, label: "Warning" },
    { id: SensorStatus.Alert, label: "Alert" },
    { id: SensorStatus.Critical, label: "Critical" },
  ];

  const { state } = useAppContext();
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const [assetsOptionsList, setAssetsOptionsList] = useState<
    { id: string; label: string }[]
  >([]);
  const assetTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetType);
  const [assetNameSearch, setAssetNameSearch] = useState(
    form.getValues().name ?? ""
  );

  const watchAssets = useWatch({
    name: "parameters.assetIds",
    control: form.control,
  });
  const watchAssetType = useWatch({
    name: "parameters.assetType",
    control: form.control,
  });
  const shouldBeEnabled = isFindAssetByIdQueryEnabled(watchAssets);

  const { data, isLoading, isSuccess } = useFindAssetsByIdsForSharingOsQuery(
    { assetIds: getAlertMultiselectIds(watchAssets) ?? [] },
    {
      enabled: shouldBeEnabled,
    }
  );

  useEffect(() => {
    if (data && !isLoading && isSuccess) {
      onFindAssetsOsSettledCallback({
        data,
        form,
        setSelectedAsset,
      });
    }
  }, [data, isLoading, isSuccess, form, shouldBeEnabled]);

  useEffect(() => {
    if (isSuccess && selectedAsset) {
      form.setValue("parameters.assetIds", selectedAsset);
    }
  }, [form, selectedAsset, isSuccess]);

  const { options, isFetching } = useFindAssetListOptions(
    assetNameSearch,
    state.appConfig.searchOptionsLimit
  );

  useEffect(() => {
    setAssetsOptionsList(options);
  }, [options]);

  const hideInputValue =
    !selectedAsset && Boolean(watchAssets?.length) && isLoading && !isSuccess;

  return (
    <AlertParametersContainer>
      <AssetsParameters
        setAssetNameSearch={setAssetNameSearch}
        form={form}
        setSelectedAsset={setSelectedAsset}
        setAssetsOptionsList={setAssetsOptionsList}
        watchAssetTypeValue={watchAssetType}
        hideInputValue={hideInputValue}
        isFetching={isFetching}
        assetsOptionsList={assetsOptionsList}
        watchAssetsValue={watchAssets}
        assetTypeOptions={assetTypeOptions}
        assetsSelectTestId="atis-assets-select"
        assetTypeSelectTestId="atis-asset-type-select"
      />

      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="atis-asset-health-state"
      >
        <AutocompleteElement
          matchId={true}
          label="ATIS Health status"
          control={form.control}
          name="parameters.atisHealthStatus"
          options={healthStateOptions}
        />
      </Grid>
    </AlertParametersContainer>
  );
};
