import { FC, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useAppContext } from "../../../../context/AppContext";
import { useFindAssetsByIdsForSharingOsQuery } from "../../../../graphql/operations";
import { useFindAssetListOptions } from "../../../../shared/hooks/useFindAssetListOptions";
import { AutocompleteOption } from "../../../../types";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import { AlertFormValues } from "../../interfaces";
import {
  getAlertMultiselectIds,
  isFindAssetByIdQueryEnabled,
  onFindAssetsOsSettledCallback,
} from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface DoorOpenMovingProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
export const DoorOpenMovingParameters: FC<DoorOpenMovingProps> = ({
  form,
}: DoorOpenMovingProps) => {
  const { state } = useAppContext();
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const [assetsOptionsList, setAssetsOptionsList] = useState<
    AutocompleteOption[]
  >([]);
  const assetTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetType);
  const [assetNameSearch, setAssetNameSearch] = useState(
    form.getValues().name ?? ""
  );

  const watchAssets = useWatch({
    name: "parameters.assetIds",
    control: form.control,
  });
  const watchAssetType = useWatch({
    name: "parameters.assetType",
    control: form.control,
  });

  const { data, isLoading, isSuccess } = useFindAssetsByIdsForSharingOsQuery(
    { assetIds: getAlertMultiselectIds(watchAssets) ?? [] },
    {
      enabled: isFindAssetByIdQueryEnabled(watchAssets),
    }
  );

  useEffect(() => {
    if (data && !isLoading && isSuccess) {
      onFindAssetsOsSettledCallback({
        data,
        form,
        setSelectedAsset,
      });
    }
  }, [data, isLoading, isSuccess, form]);

  useEffect(() => {
    if (isSuccess && selectedAsset) {
      form.setValue("parameters.assetIds", selectedAsset);
    }
  }, [form, selectedAsset, isSuccess]);

  const { options, isFetching } = useFindAssetListOptions(
    assetNameSearch,
    state.appConfig.searchOptionsLimit
  );

  useEffect(() => {
    setAssetsOptionsList(options);
  }, [options]);

  const hideInputValue =
    !selectedAsset && !!watchAssets?.length && isLoading && !isSuccess;

  return (
    <AlertParametersContainer>
      <AssetsParameters
        setAssetNameSearch={setAssetNameSearch}
        form={form}
        setSelectedAsset={setSelectedAsset}
        setAssetsOptionsList={setAssetsOptionsList}
        watchAssetTypeValue={watchAssetType}
        hideInputValue={hideInputValue}
        isFetching={isFetching}
        assetsOptionsList={assetsOptionsList}
        watchAssetsValue={watchAssets}
        assetTypeOptions={assetTypeOptions}
        assetsSelectTestId="cargo-assets-select"
        assetTypeSelectTestId="cargo-asset-type-select"
      />
    </AlertParametersContainer>
  );
};
