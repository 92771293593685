import { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { PAGE_SNACKBAR } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import {
  TableFiltersInput,
  useGetAssetsForGalleryOsQuery,
} from "../../../graphql/operations";
import { mapAssetFilters } from "../../../views/AssetsView/TableView/components/AssetsTable/utils";
import {
  assetsListInputParams,
  splitViewportAtMeridian,
} from "../../../views/AssetsView/helpers/helpers";
import { useAssetsDataContext } from "../../../views/AssetsView/shared/AssetsDataContext";
import { onGetAssetsSuccess } from "../../../views/AssetsView/shared/AssetsDataContext/helpers";
import { useAssetsComplexFilters } from "../../../views/AssetsView/shared/hooks/useAssetsComplexFilters";
import { usePreferredTimezone } from "../usePreferredTimezone";
import { useUserData } from "../useUserData";
import { useOrgsDownwardHierarchy } from "./useOrgsDownwardHierarchy";

export const useGetAssetsGallery = () => {
  const {
    assetsPagination,
    debouncedMapViewCoordinatesFromUrl,
    assetsSearchInput,
    currentFilter,
  } = useAssetsDataContext();

  const {
    state: {
      assets,
      selectedOrganization: { selectedOrganization },
    },
    dispatch,
  } = useAppContext();
  const userData = useUserData();
  const timezone = usePreferredTimezone();
  const [searchParams] = useSearchParams();

  const [tableFilters, setTableFilters] = useState<{
    filterList: TableFiltersInput | undefined;
  }>();

  const orgs = useOrgsDownwardHierarchy();

  useEffect(() => {
    const filters = mapAssetFilters(
      currentFilter,
      userData,
      orgs,
      selectedOrganization,
      currentFilter?.sharedGroupName?.type
    );
    if (JSON.stringify(filters) !== JSON.stringify(tableFilters?.filterList)) {
      setTableFilters({ filterList: filters });
    }
  }, [
    selectedOrganization,
    currentFilter,
    assetsSearchInput,
    userData,
    tableFilters?.filterList,
    orgs,
  ]);

  const zoomLevelUrlParam = searchParams.get("zoomLevel");
  const zoom = zoomLevelUrlParam ? { zoom: parseFloat(zoomLevelUrlParam) } : {};
  const viewport: Array<Array<number>> | null = useMemo(() => {
    return debouncedMapViewCoordinatesFromUrl?.length
      ? splitViewportAtMeridian(debouncedMapViewCoordinatesFromUrl)
      : null;
  }, [debouncedMapViewCoordinatesFromUrl]);

  const { complexFiltersQuery } = useAssetsComplexFilters();

  const { data, isLoading, isRefetching } = useGetAssetsForGalleryOsQuery(
    {
      input: {
        ...assetsListInputParams,
        skip: assetsPagination,
        ...tableFilters,
        complexFilters: JSON.stringify(complexFiltersQuery),
        search: assetsSearchInput,
        viewport,
        ...zoom,
        currentOrgId: selectedOrganization.value,
        timezone,
      },
    },
    {
      onSuccess: () => {
        onGetAssetsSuccess(assets.isOnClickDisabled, dispatch);
      },
      onError: () => {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Failed to load assets!",
            text: "Something Went Wrong.",
            severity: "error",
          },
        });
      },
      keepPreviousData: true,
      enabled: Boolean(complexFiltersQuery),
    }
  );

  return {
    data: data?.getAssetsOS,
    isLoading,
    isRefetching,
  };
};
