import { GridColDef } from "@mui/x-data-grid-premium";
import { lowerCase } from "lodash";
import { SensorProfileResult } from "../../../../../graphql/operations";
import TableHeader from "../../../../../shared/components/TableHeader/TableHeader";
import {
  SensorData,
  SensorThresholds,
} from "../../../../../shared/helpers/sensors.utils";
import {
  MappedSensorProfile,
  SensorsProperties,
  getProfilesTableColumns,
  tableStyleMap,
} from "../profileUtils";

export enum CargoMeasurementType {
  CubeSpace = "Volume",
  FloorSpace = "Floor Space",
}

export enum CargoCameraState {
  Healthy = "Healthy",
  Warning = "Warning",
  Alert = "Alert",
  Critical = "Critical",
}

const getIconTestId = (cargoCameraState: CargoCameraState): string => {
  return `cargo-camera-${lowerCase(cargoCameraState)}-icon`;
};

type CameraStateWithCargoMeasurementString =
  `${keyof typeof CargoCameraState} ${CargoMeasurementType}`;

const tableFloorSpaceHeaderNames: Record<
  CargoCameraState,
  CameraStateWithCargoMeasurementString
> = {
  [CargoCameraState.Healthy]: `${CargoCameraState.Healthy} ${CargoMeasurementType.FloorSpace}`,
  [CargoCameraState.Warning]: `${CargoCameraState.Warning} ${CargoMeasurementType.FloorSpace}`,
  [CargoCameraState.Alert]: `${CargoCameraState.Alert} ${CargoMeasurementType.FloorSpace}`,
  [CargoCameraState.Critical]: `${CargoCameraState.Critical} ${CargoMeasurementType.FloorSpace}`,
};

const tableCubeSpaceHeaderNames: Record<
  CargoCameraState,
  CameraStateWithCargoMeasurementString
> = {
  [CargoCameraState.Healthy]: `${CargoCameraState.Healthy} ${CargoMeasurementType.CubeSpace}`,
  [CargoCameraState.Warning]: `${CargoCameraState.Warning} ${CargoMeasurementType.CubeSpace}`,
  [CargoCameraState.Alert]: `${CargoCameraState.Alert} ${CargoMeasurementType.CubeSpace}`,
  [CargoCameraState.Critical]: `${CargoCameraState.Critical} ${CargoMeasurementType.CubeSpace}`,
};

const getCameraSensorTableColumns = (
  fieldPrefix: string = ""
): GridColDef<SensorProfileResult>[] => {
  const floorUsageFieldPrefix = `${fieldPrefix}.floorUsagePercentage`;
  const cubeUsageFieldPrefix = `${fieldPrefix}.cubeUsagePercentage`;

  return [
    {
      field: `${floorUsageFieldPrefix}.healthy`,
      headerName: tableFloorSpaceHeaderNames.Healthy,
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={tableFloorSpaceHeaderNames.Healthy} />
      ),
      valueGetter: (params) =>
        `${params.value?.healthyFrom}% - ${params.value?.healthyTo}%`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
    },
    {
      field: `${floorUsageFieldPrefix}.warning`,
      headerName: tableFloorSpaceHeaderNames.Warning,
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={tableFloorSpaceHeaderNames.Warning} />
      ),
      valueGetter: (params) =>
        `${params.value?.warningFrom}% - ${params.value?.warningTo}%`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
    },
    {
      field: `${floorUsageFieldPrefix}.alert`,
      headerName: tableFloorSpaceHeaderNames.Alert,
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={tableFloorSpaceHeaderNames.Alert} />
      ),
      valueGetter: (params) =>
        `${params.value?.alertFrom}% - ${params.value?.alertTo}%`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
    },
    {
      field: `${floorUsageFieldPrefix}.critical`,
      headerName: tableFloorSpaceHeaderNames.Critical,
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={tableFloorSpaceHeaderNames.Critical} />
      ),
      valueGetter: (params) =>
        `${params.value?.criticalFrom}% - ${params.value?.criticalTo}%`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
    },
    {
      field: `${cubeUsageFieldPrefix}.healthy`,
      headerName: tableCubeSpaceHeaderNames.Healthy,
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={tableCubeSpaceHeaderNames.Healthy} />
      ),
      valueGetter: (params) =>
        `${params.value?.healthyFrom}% - ${params.value?.healthyTo}%`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
    },
    {
      field: `${cubeUsageFieldPrefix}.warning`,
      headerName: tableCubeSpaceHeaderNames.Warning,
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={tableCubeSpaceHeaderNames.Warning} />
      ),
      valueGetter: (params) =>
        `${params.value?.warningFrom}% - ${params.value?.warningTo}%`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
    },
    {
      field: `${cubeUsageFieldPrefix}.alert`,
      headerName: tableCubeSpaceHeaderNames.Alert,
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={tableCubeSpaceHeaderNames.Alert} />
      ),
      valueGetter: (params) =>
        `${params.value?.alertFrom}% - ${params.value?.alertTo}%`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
    },
    {
      field: `${cubeUsageFieldPrefix}.critical`,
      headerName: tableCubeSpaceHeaderNames.Critical,
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={tableCubeSpaceHeaderNames.Critical} />
      ),
      valueGetter: (params) =>
        `${params.value?.criticalFrom}% - ${params.value?.criticalTo}%`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
    },
  ] as GridColDef<SensorProfileResult>[];
};

export const cargoCameraTableColumns = getProfilesTableColumns(
  getCameraSensorTableColumns(SensorsProperties.CargoCamera)
);

export const cargoCameraTableKeyList: string[] = cargoCameraTableColumns.map(
  (column) => column.field
);

export const mapCargoCameraSensorProfileData = (
  profiles: SensorProfileResult[]
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter((profile) => profile?.configuration?.cargoCamera)
    .map((profile) => {
      const sensorData = {} as SensorData;
      const floorUsagePercentage = profile?.configuration?.cargoCamera
        ?.floorUsagePercentage as SensorThresholds;
      const cubeUsagePercentage = profile?.configuration?.cargoCamera
        ?.cubeUsagePercentage as SensorThresholds;
      if (floorUsagePercentage && cubeUsagePercentage) {
        sensorData.cargoCamera = {
          floorUsagePercentage,
          cubeUsagePercentage,
        };
      }

      return {
        ...profile,
        ...sensorData,
      };
    });

  return filteredAndMappedProfiles;
};
