import {
  GetAssetsManufacturersInput,
  useGetAssetsManufacturersOsQuery,
} from "../../../graphql/operations";

export const useGetAssetsManufacturers = (
  input: GetAssetsManufacturersInput
): {
  assetManufacturers: string[];
  isLoading: boolean;
} => {
  const { isLoading, data } = useGetAssetsManufacturersOsQuery({ input });

  return {
    assetManufacturers: data?.getAssetsManufacturersOS ?? [],
    isLoading,
  };
};
