import { memo, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FieldValues, useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  AuthenticationResultType,
  ChallengeNameType,
  NotAuthorizedException,
  PasswordResetRequiredException,
} from "@aws-sdk/client-cognito-identity-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  CircularProgress,
  Link,
  Skeleton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { useAppContext } from "../../../context/AppContext";
import {
  ACCESS_COOKIE_NAME,
  REFRESH_COOKIE_NAME,
  useAuthContext,
} from "../../../context/AuthContext";
import { ColorsPalette } from "../../../design-system/colors-palette";
import { SessionStorageItem } from "../../../enums/sessionStorage";
import {
  signIn,
  handleMfaChallengeResponse,
  handleMfaSetupChallengeResponse,
} from "../../../services/aws/auth";
import { Button } from "../../../shared/components/Button";
import { navigateToRoute } from "../../../utils/generic";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { useCheckRouteSecurity } from "../../../utils/routes/useCheckRouteSecurity";
import { ConfirmPasswordReason } from "../ForgotPassword/ConfirmForgotPassword";
import {
  fetchTokensForSSO,
  redirectToSSOIdentityProviderLogin,
  SSOProviders,
} from "../SSO/utils";
import { AuthButton } from "../components/AuthButton";
import { AuthenticationBox } from "../components/AuthenticationBox";
import { useAuthFormTheme } from "../hooks/useAuthFormTheme";
import { useAuthenticationViewBrand } from "../hooks/useAuthenticationViewBrand";

export const schema = yup.object().shape({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

const CustomLogin = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [ssoLoading, setSSOLoading] = useState(searchParams.has("code"));
  const { login, ssoProviders } = useAuthContext();
  const location = useLocation();
  const { dispatch } = useAppContext();
  const { defaultPath, isLoadingDefaultPath } = useCheckRouteSecurity();
  const { brand, refetchBrand } = useAuthenticationViewBrand();
  const formTheme = useAuthFormTheme();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  let SSOLoginButtonText = "Phillips Connect SSO";
  if (ssoProviders?.length > 1) {
    SSOLoginButtonText = "Sign In via SSO";
  }
  const queryClient = useQueryClient();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isLogging, setIsLogging] = useState(false);

  const handleChallengeResponse = async (
    username: string,
    response: {
      challengeName: ChallengeNameType;
      challengeParameters?: any;
    }
  ) => {
    const { challengeName, challengeParameters } = response;

    switch (challengeName) {
      case ChallengeNameType.SMS_MFA:
      case ChallengeNameType.SOFTWARE_TOKEN_MFA:
        return await handleMfaChallengeResponse({
          challengeName,
          challengeParameters,
        });
      case ChallengeNameType.MFA_SETUP:
      case ChallengeNameType.SELECT_MFA_TYPE:
        return await handleMfaSetupChallengeResponse({
          challengeName,
          username,
          dispatch,
        });
      case ChallengeNameType.NEW_PASSWORD_REQUIRED:
        return navigateToRoute(NavigationRoutes.NewUserPassword);
      default:
        return;
    }
  };

  const handleAuthenticationResultResponse = async (
    result: AuthenticationResultType
  ) => {
    if (!result.AccessToken) {
      console.error("AccessToken is undefined.");
      return;
    }

    const tokens = {
      accessToken: result.AccessToken,
      idToken: result.IdToken ?? "",
      refreshToken: result.RefreshToken ?? "",
      expiresIn: result.ExpiresIn ?? 0,
    };

    login(tokens);

    // Silently refresh brand data
    await refetchBrand(true);

    setShouldRedirect(true);
    // clear all the cache after successfull relogin
    localStorage.removeItem("ssoProviders");
    queryClient.clear();
  };

  const handleSignIn = async ({ username, password }: FieldValues) => {
    setIsLogging(true);

    try {
      const response = await signIn(username, password);
      sessionStorage.setItem(SessionStorageItem.Password, password);
      if (!response) {
        console.error("Sign-in failed.");
        return;
      }

      switch (response.type) {
        case "AuthenticationResult":
          return handleAuthenticationResultResponse(response.result);
        case "Challenge":
          return handleChallengeResponse(username, response);
        default:
          return;
      }
    } catch (error) {
      if (error instanceof PasswordResetRequiredException) {
        const usernameParam = `?username=${username}`;
        const reasonParam = `&reason=${ConfirmPasswordReason.Reset}`;
        return navigate(
          `${NavigationRoutes.ConfirmForgotPassword}${usernameParam}${reasonParam}`
        );
      } else if (error instanceof NotAuthorizedException) {
        setError("root", {
          type: "manual",
          message: "Incorrect username or password.",
        });
      } else {
        console.error(`Sign in failed: ${error}`);
      }

      setIsLogging(false);
    }
  };

  const handleResetRootError = () => {
    clearErrors("root");
  };

  // Handle SSO Login Authorization
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const authorizationCode = urlParams.get("code");

    if (authorizationCode) {
      // Exchange the SSO authorization code for access tokens
      fetchTokensForSSO(authorizationCode)
        .then((tokens) => {
          handleAuthenticationResultResponse({
            IdToken: tokens.id_token,
            AccessToken: tokens.access_token,
            RefreshToken: tokens.refresh_token,
            ExpiresIn: tokens.expires_in,
          });
        })
        .catch((error) => {
          setError("root", {
            type: "manual",
            message:
              "Authentication failed! Contact your Identity Provider for assistance",
          });
          setSSOLoading(false);

          console.error("Error fetching tokens:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (shouldRedirect && !isLoadingDefaultPath) {
      navigate(defaultPath, { replace: true });
    }
  }, [defaultPath, isLoadingDefaultPath, shouldRedirect, navigate]);

  useEffect(() => {
    // Function to handle popstate events, which are triggered when the active history entry changes
    const handlePopState = (event: PopStateEvent) => {
      // Check if the state has a property that prevents forward navigation
      if (event.state?.preventForward) {
        // Navigate to the default path and replace the current entry in the history stack
        navigate(defaultPath, { replace: true });
      }
    };

    // Push a new state into the history stack with a flag to prevent forward navigation
    window.history.pushState({ preventForward: true }, "");

    // Add an event listener for popstate events to call handlePopState
    window.addEventListener("popstate", handlePopState);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate, defaultPath]);

  const supportStyles = {
    textAlign: "center",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "16px",
  };

  const [accessTokenCookies] = useCookies([ACCESS_COOKIE_NAME]);
  const [refreshTokenCookies] = useCookies([REFRESH_COOKIE_NAME]);

  const [showNothing, setShowNothing] = useState<boolean>(
    accessTokenCookies[ACCESS_COOKIE_NAME] ||
      refreshTokenCookies[REFRESH_COOKIE_NAME]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNothing(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // If we have tokens in the cookies. show nothing for 1 second until we redirect properly, to prevent flickering
  if (showNothing) {
    return null;
  }

  const handleLoginSSO = (ssoProviders: SSOProviders[]) => {
    if (ssoProviders) {
      if (ssoProviders.length === 1) {
        // if we have only one SSO provider, redirect to that provider
        redirectToSSOIdentityProviderLogin(ssoProviders[0].ProviderName);
      } else if (ssoProviders.length > 1) {
        // if we have more than one SSO provider, show the list of all configured SSO providers
        navigate(NavigationRoutes.SSOSelection);
      }
    }
  };

  return (
    <AuthenticationBox>
      <ThemeProvider theme={formTheme}>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              fontWeight: 500,
            }}
          >
            Sign In With Your Credentials
          </Box>
          <Link
            href={NavigationRoutes.ForgotPassword}
            data-testid="forgot-password-link"
            sx={{
              ...supportStyles,
              color: "var(--error)",
              textDecorationColor: "var(--error)",
            }}
          >
            Forgot your password?
          </Link>
          <form onSubmit={handleSubmit(handleSignIn)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "234px",
              }}
            >
              {ssoLoading ? (
                <div>
                  <Skeleton
                    sx={{
                      marginBottom: "8px",
                    }}
                    variant="rounded"
                    width={210}
                    height={40}
                  />
                  <Skeleton
                    sx={{
                      marginBottom: "8px",
                    }}
                    variant="rounded"
                    width={210}
                    height={40}
                  />
                </div>
              ) : (
                <>
                  <TextFieldElement
                    name="username"
                    control={control}
                    onChange={handleResetRootError}
                    fullWidth
                    inputProps={{
                      "data-testid": "custom-login-username",
                      placeholder: "E-mail",
                      autoFocus: true,
                    }}
                  />
                  <TextFieldElement
                    name="password"
                    type="password"
                    control={control}
                    onChange={handleResetRootError}
                    fullWidth
                    inputProps={{
                      "data-testid": "custom-login-password",
                      placeholder: "Password",
                    }}
                  />
                </>
              )}
              <AuthButton
                text="Sign In"
                type="submit"
                disabled={ssoLoading}
                iconPosition={isLogging ? "right" : "none"}
              />
              {ssoProviders.length > 0 && (
                <Box>
                  <Button
                    text={SSOLoginButtonText}
                    type="button"
                    onClick={() => {
                      handleLoginSSO(ssoProviders);
                    }}
                    icon={
                      <CircularProgress
                        size={15}
                        style={{ color: ColorsPalette.RichBlack }}
                      />
                    }
                    iconPosition={ssoLoading ? "right" : "none"}
                    sx={{
                      width: "100%",
                      minWidth: "235px",
                      fontSize: "14px !important",
                    }}
                  />
                </Box>
              )}

              {errors.root && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "var(--error)",
                    textAlign: "center",
                  }}
                >
                  {errors.root.message}
                </Typography>
              )}
            </Box>
          </form>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={supportStyles}>
              Customer Support: {brand?.supportPhoneNumber}
            </Typography>
            <Link href={`mailto: ${brand?.supportEmail}`} sx={supportStyles}>
              {brand?.supportEmail}
            </Link>
          </Box>
        </Box>
      </ThemeProvider>
    </AuthenticationBox>
  );
};

export default memo(CustomLogin);
