import { MutationKey, UseMutationOptions } from "@tanstack/react-query";
import {
  RemoveAssetOsMutation,
  useRemoveAssetOsMutation,
  RemoveAssetOsMutationVariables,
} from "../../../graphql/operations";

type RemoveAssetMutationOptions = UseMutationOptions<
  RemoveAssetOsMutation,
  unknown,
  RemoveAssetOsMutationVariables,
  MutationKey
>;

export const useRemoveAsset = (options: RemoveAssetMutationOptions) => {
  const { mutate, mutateAsync, isLoading } = useRemoveAssetOsMutation(options);

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};
