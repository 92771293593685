import { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { AssetOs } from "../../../../../../graphql/operations";
import { formatDistanceToNowStrictCustom } from "../../../../../../utils/date";
import DoorHeader from "./DoorHeader";

export interface DoorStateProps {
  selectedAsset: AssetOs;
}

const DoorState: FC<DoorStateProps> = ({ selectedAsset }) => {
  const { sensors } = selectedAsset;

  return (
    <Grid
      spacing={0}
      justify-content="flex-end"
      container
      className="text-sm w-full p-4"
      data-testid="door-first-card"
    >
      {/* TODO: currently showing only one card. Update the layout when we need ot show 2 cards */}
      <DoorHeader title={"Door Status"}></DoorHeader>
      <Grid container alignItems="stretch" className="mt-auto">
        <Box className="truncate w-full text-left">
          <Typography
            className="truncate capitalize !text-5xl !mb-4	 !font-semibold !text-brand"
            data-testid="door-state-label"
          >
            {sensors?.bleDoorSensor?.data?.doorState ?? "Open"}
          </Typography>
        </Box>
        <Box className="!mb-2 w-full text-right">
          <Box
            className="inline-block rounded p-2  !text-center"
            sx={{ background: "var(--primary-blue)", color: "var(--white)" }}
          >
            <Typography className=" !text-xs	 !font-bold">
              {formatDistanceToNowStrictCustom(
                new Date(sensors?.bleDoorSensor?.readingDate)
              )}
            </Typography>
          </Box>
        </Box>

        <Box className="justify w-full text-right">
          <Typography className="!text-sm inline-block align-middle text-card-sensors-text">
            in current status
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DoorState;
