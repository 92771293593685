import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import {
  SearchAggregatedEventDoorOsQuery,
  SearchAggregatedEventHistoryQueryVariables,
  useSearchAggregatedEventDoorOsQuery,
} from "../../../graphql/operations";
import { DoorData } from "../../../views/AssetsView/MapView/Assets/CargoTabPanel/DoorStatus/utils";

type UseSearchAggregatedEventDoorDataHookResult = {
  data: DoorData[];
  isLoading: boolean;
};

type SearchAggregatedEventDoorQueryOptions = UseQueryOptions<
  SearchAggregatedEventDoorOsQuery,
  unknown,
  SearchAggregatedEventDoorOsQuery,
  QueryKey
>;

export const useSearchAggregatedEventDoorData = (
  input: SearchAggregatedEventHistoryQueryVariables,
  options: SearchAggregatedEventDoorQueryOptions
): UseSearchAggregatedEventDoorDataHookResult => {
  const { data, isLoading } = useSearchAggregatedEventDoorOsQuery(
    input,
    options
  );

  return {
    data: data?.searchAggregatedEventHistoryOS?.data ?? [],
    isLoading,
  };
};
