import { useEffect, useMemo, useState } from "react";
import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useAppContext } from "../../../context/AppContext";
import {
  AssetInputOs,
  GetAssetsClustersCoordinatesOsQuery,
  useGetAssetsClustersCoordinatesOsQuery,
} from "../../../graphql/operations";
import {
  AssetOrClusterListItem,
  extractAssetOrClusterCoordinatesData,
} from "../../../utils/maps/core";
import { useAssetsDataContext } from "../../../views/AssetsView/shared/AssetsDataContext";

export interface AssetsClustersCoordinatesDataHook {
  data: number[][];
  isFetching: boolean;
  refetch: () => void;
}

export type GetAssetsClustersCoordinatesOSQueryOptions = UseQueryOptions<
  GetAssetsClustersCoordinatesOsQuery,
  unknown,
  GetAssetsClustersCoordinatesOsQuery,
  QueryKey
>;

export const useAssetsClustersCoordinatesData = (
  queryInput: AssetInputOs,
  queryOptions: GetAssetsClustersCoordinatesOSQueryOptions
): AssetsClustersCoordinatesDataHook => {
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const {
    setLastFetchedAllCoordinatesForOrg,
    setIsLoadingAllAssetsCoordinates,
    setAllAssetsCoordinates,
  } = useAssetsDataContext();
  const [data, setData] = useState<number[][]>([]);

  const queryIsEnabled = useMemo(
    () => queryOptions?.enabled ?? true,
    [queryOptions]
  );

  const {
    data: assetsClustersCoordinatesData,
    isFetching,
    refetch,
  } = useGetAssetsClustersCoordinatesOsQuery(
    {
      input: queryInput,
    },
    {
      ...queryOptions,
      enabled: queryIsEnabled,
    }
  );

  useEffect(() => {
    // Ensure context state is up-to-date
    setIsLoadingAllAssetsCoordinates(isFetching);
  }, [setIsLoadingAllAssetsCoordinates, isFetching]);

  useEffect(() => {
    const allAssetsCoordinates = extractAssetOrClusterCoordinatesData(
      (assetsClustersCoordinatesData?.getAssetsOS?.clusters ??
        []) as AssetOrClusterListItem[]
    );

    // Ensure context state is up-to-date
    setAllAssetsCoordinates(allAssetsCoordinates);
    setLastFetchedAllCoordinatesForOrg(selectedOrganization?.value);

    setData(allAssetsCoordinates);
  }, [
    assetsClustersCoordinatesData,
    selectedOrganization?.value,
    setAllAssetsCoordinates,
    setLastFetchedAllCoordinatesForOrg,
  ]);

  return {
    data,
    isFetching,
    refetch,
  };
};
