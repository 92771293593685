import { memo, useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Box, TextField, Divider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { sub } from "date-fns";
import { isBoolean, isUndefined } from "lodash";
import { useAppContext } from "../../../../../context/AppContext";
import { DateTextField } from "../../../../../shared/components/DateTextField";
import { SwitchButtons } from "../../../../../shared/components/SwitchButtons";
import { ToggleButtonOption } from "../../../../../shared/components/ToggleButtons";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import { useGetGeofenceNames } from "../../../../../shared/hooks/useGetGeofenceNames";
import { useGetTypeAssetOptions } from "../../../MapView/hooks/useGetTypeOptions";
import { useAssetsDataContext } from "../../AssetsDataContext/AssetsDataContext";

export const INSTALLATION_STATUS_OPTIONS: ToggleButtonOption[] = [
  {
    value: true,
    label: "Installed",
    isFalsy: false,
  },
  {
    value: false,
    label: "Not Installed",
    isFalsy: true,
  },
];

export enum InstalledDateFilterOptions {
  StartDate = "startDate",
  EndDate = "endDate",
}

export const GPS_SIGNAL_OPTIONS: ToggleButtonOption[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "locked",
    label: "Locked",
  },
  {
    value: "unlocked",
    label: "Unlocked",
  },
];

const MoreFilters: React.FC = () => {
  const {
    onChangeFilters,
    currentFilter: {
      signals,
      installedDate,
      installStatus,
      geofenceNames,
      geofenceCategories,
      installer,
    },
  } = useAssetsDataContext();

  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const { geofenceAssetCategories, isFetching: isFetchingGeofenceCategories } =
    useGetTypeAssetOptions({
      orgId: selectedOrganization?.value ?? "",
    });

  const {
    geofenceNames: geofenceNamesOptions,
    isFetching: isFetchingGeofenceNames,
  } = useGetGeofenceNames({
    orgId: selectedOrganization?.value ?? "",
  });

  const muiTheme = useCurrentTheme();

  const isDarkMode = muiTheme.palette.mode === "dark";
  const borderColor = isDarkMode ? "!border-white" : "!border-black";

  // Minimal allowed date is 2 years back from current time
  const minDate = sub(new Date(), { years: 2 });
  const maxStartDate = installedDate?.endDate ?? new Date();

  // State
  const [installerValue, setInstallerValue] = useState<string>(installer ?? "");
  const [gpsSignal, setGpsSignal] = useState<string>(
    isUndefined(signals) ? "all" : signals ? "locked" : "unlocked"
  );

  // UseEffect
  useEffect(() => {
    if (isUndefined(signals)) setGpsSignal("all");
    else {
      if (isBoolean(signals)) {
        setGpsSignal(signals ? "locked" : "unlocked");
      } else if (signals === "all") {
        setGpsSignal("all");
      }
    }
  }, [signals, setGpsSignal]);

  // Handlers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstallerValue(event.target.value);
    if (event.target.value === "") onChangeFilters({ installer: "" });
  };

  const handleBlur = (event: React.FocusEvent<HTMLElement>) =>
    onChangeFilters({ installer: installerValue });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") onChangeFilters({ installer: installerValue });
  };

  const handleClear = () => {
    setInstallerValue("");
    onChangeFilters({ installer: "" });
  };

  const changeinstalledDateHandler = (
    selectedDate: Date | null,
    dateField: string
  ) => {
    if (dateField === InstalledDateFilterOptions.StartDate && selectedDate) {
      onChangeFilters({
        installedDate: {
          startDate: selectedDate,
          endDate: installedDate?.endDate ?? null,
        },
      });
    } else if (
      dateField === InstalledDateFilterOptions.EndDate &&
      selectedDate
    ) {
      onChangeFilters({
        installedDate: {
          startDate: installedDate?.startDate ?? null,
          endDate: selectedDate,
        },
      });
    } else {
      onChangeFilters({
        installedDate: {
          startDate: null,
          endDate: null,
        },
      });
    }
  };

  const handleGpsSignalChange = (
    _: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    switch (value) {
      case "locked":
        onChangeFilters({ signals: true });
        break;
      case "unlocked":
        onChangeFilters({ signals: false });
        break;
      default:
        // We set filter state accordingly
        onChangeFilters({ signals: undefined });
        // But locally this means "all" in every case
        setGpsSignal("all");
        break;
    }
  };

  return (
    <Box className="mt-6 w-full">
      <Box className="h-12 mb-3 flex items-center justify-between font-semibold text-[18px] leading-[26px] tracking-[-0.01em]">
        <Box>More</Box>
      </Box>

      <Box className="h-20 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">
          Installation Status
        </Box>

        <SwitchButtons
          data-testid="more-filters__installation-status"
          value={installStatus}
          onChange={(_, value) =>
            value !== null && onChangeFilters({ installStatus: value })
          }
          options={INSTALLATION_STATUS_OPTIONS}
          size="small"
          groupclass={`h-[40px] overflow-hidden !rounded-[30px] p-0.5 !border-2 ${borderColor}`}
          fullWidth
          exclusive
        />
      </Box>

      <Divider />

      <Box className="mt-4 mb-2 flex flex-col">
        <Box className="font-bold text-[10px] leading-4">Installer</Box>
        <TextField
          value={installerValue}
          placeholder="Search Installer"
          data-testid="others-filter__installer"
          className="w-full"
          InputProps={{
            endAdornment: installerValue ? (
              <ClearIcon
                onClick={handleClear}
                className="hover:cursor-pointer"
                data-testid="others-filter__clear-installer"
              />
            ) : null,
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      </Box>

      <Divider />

      <Box className="mt-4 mb-2 flex flex-col">
        <Box className="font-bold text-[10px] leading-4">Installation Date</Box>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box className="grid grid-cols-2 gap-8">
            <DateTextField
              label="Start Date"
              value={installedDate?.startDate}
              data-title="Start Date"
              maxDate={maxStartDate}
              minDate={minDate}
              onChange={(value) => {
                changeinstalledDateHandler(
                  value,
                  InstalledDateFilterOptions.StartDate
                );
              }}
            />

            <DateTextField
              label="End Date"
              data-title="End Date"
              value={installedDate?.endDate}
              minDate={installedDate?.startDate ?? minDate}
              onChange={(value) => {
                changeinstalledDateHandler(
                  value,
                  InstalledDateFilterOptions.EndDate
                );
              }}
            />
          </Box>
        </LocalizationProvider>
      </Box>

      <Divider />

      <Box className="h-20 mt-2 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">GPS Signal</Box>

        <SwitchButtons
          id="others-filter__gps-signal"
          value={gpsSignal}
          onChange={handleGpsSignalChange}
          options={GPS_SIGNAL_OPTIONS}
          size="small"
          groupclass={`h-[40px] overflow-hidden !rounded-[30px] p-0.5 !border-2 ${borderColor}`}
          fullWidth
          exclusive
        />
      </Box>

      <Divider />

      <Box className="mt-4 mb-2 flex flex-col">
        <Box className="font-bold text-[10px] leading-4">
          Current Geofence Name
        </Box>

        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__geofence-name"
          multiple
          loading={isFetchingGeofenceNames}
          disabled={isFetchingGeofenceNames}
          options={geofenceNamesOptions}
          size="small"
          value={geofenceNames}
          onChange={(_, geofenceNames) => onChangeFilters({ geofenceNames })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Current Geofence Name"
              data-testid="current-geofence-name-input"
            />
          )}
        />
      </Box>

      <Divider />

      <Box className="mt-4 mb-2 flex flex-col">
        <Box className="font-bold text-[10px] leading-4">Geofence Category</Box>

        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__geofence-category"
          multiple
          loading={isFetchingGeofenceCategories}
          disabled={isFetchingGeofenceCategories}
          options={geofenceAssetCategories}
          size="small"
          value={geofenceCategories}
          onChange={(_, geofenceCategories) =>
            onChangeFilters({ geofenceCategories })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Geofence Category"
              data-testid="geofence-category-input"
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default memo(MoreFilters);
