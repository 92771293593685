import { FC, useEffect, useMemo, useState } from "react";
import { Box, Grid, CircularProgress, ThemeProvider } from "@mui/material";
import {
  AIRBAG_CHART_DATE_RANGE_OPTIONS,
  DateRangeKey,
  DEFAULT_DATE_RANGE_OPTIONS,
} from "../../../../../constants/map";
import {
  AssetOs,
  HistoricalEventHistory,
  useGetAirBagSensorDataFromEventHistoryQuery,
  useGetMergedProfileForAssetQuery,
} from "../../../../../graphql/operations";
import { Sections } from "../../../../../shared/components/DashboardFrame/components/DashboardSectionsMenu/DashboardSectionsMenu";
import AirBagStatus from "../../../../../views/AssetsView/MapView/Assets/OtherSensorsTabPanel/AirBagStatus/AirBagStatus";
import AirBagStatusCards from "../../../../../views/AssetsView/MapView/Assets/OtherSensorsTabPanel/AirBagStatusCards/AirBagStatusCards";
import { filterAirBagSensorMeasures } from "../../../../../views/AssetsView/MapView/Assets/OtherSensorsTabPanel/utils";
import { useOtherSensorsTabPanelTheme } from "../../../../../views/AssetsView/MapView/Assets/shared";
import { AirBagTrendingGraph } from "../../../../../views/AssetsView/MapView/Shared/AirBagTrendingGraph/AirBagTrendingGraph";

export interface OtherSensorsTabPanelProps {
  selectedAsset: AssetOs | null;
}

const DEFAULT_DATE_RANGE_OPTION = DEFAULT_DATE_RANGE_OPTIONS.find(
  (option) => option.key === DateRangeKey.Last30Days
);

const OtherSensorsTabPanel: FC<OtherSensorsTabPanelProps> = ({
  selectedAsset,
}) => {
  const [defaultStartDate, defaultEndDate] = DEFAULT_DATE_RANGE_OPTION?.getRange
    ? DEFAULT_DATE_RANGE_OPTION.getRange()
    : [null, null];

  const [aggregatedAirBagData, setAggregatedAirBagData] = useState<
    Array<HistoricalEventHistory>
  >([]);

  const [startDateAirBag, setStartDateAirBag] = useState<Date | null>(
    defaultStartDate
  );
  const [endDateAirBag, setEndDateAirBag] = useState<Date | null>(
    defaultEndDate
  );
  const [selectedDateRangeOptionAirBag, setSelectedDateRangeOptionAirBag] =
    useState(AIRBAG_CHART_DATE_RANGE_OPTIONS[0].value);
  const theme = useOtherSensorsTabPanelTheme();

  const {
    isInitialLoading,
    isLoading: isAirBagLoading,
    data: airBagData,
  } = useGetAirBagSensorDataFromEventHistoryQuery(
    {
      input: {
        customerOrgId: selectedAsset?.customer_orgs_id ?? "",
        startDate: startDateAirBag,
        endDate: endDateAirBag,
        imei: selectedAsset?.imei ?? "",
        includeEmptyDates: true,
        period:
          AIRBAG_CHART_DATE_RANGE_OPTIONS.find(
            (option) => option.value === selectedDateRangeOptionAirBag
          )?.key ?? DateRangeKey.Last30Days,
      },
    },
    {
      enabled:
        Boolean(selectedAsset?.imei) &&
        Boolean(selectedAsset?.customer_orgs_id),
    }
  );
  const [noSensorData, setNoSensorData] = useState<boolean>(true);

  const filteredAirbagMeasures = useMemo(
    () =>
      filterAirBagSensorMeasures(
        selectedAsset?.sensors?.airbag?.data?.measures
      ),
    [selectedAsset?.sensors?.airbag?.data?.measures]
  );

  useEffect(() => {
    if (airBagData?.searchHistoricalEventHistory?.data?.length) {
      setAggregatedAirBagData(
        airBagData?.searchHistoricalEventHistory
          ?.data as HistoricalEventHistory[]
      );
    }
  }, [airBagData?.searchHistoricalEventHistory?.data]);

  useEffect(() => {
    const hasAirbagData =
      airBagData?.searchHistoricalEventHistory?.data?.some(
        (sensor) => sensor?.airbag
      ) || filteredAirbagMeasures.length;

    setNoSensorData(!hasAirbagData);
  }, [airBagData?.searchHistoricalEventHistory?.data, filteredAirbagMeasures]);

  const profileGQL = useGetMergedProfileForAssetQuery(
    {
      input: { assetId: selectedAsset?._id ?? "" },
    },
    { enabled: Boolean(selectedAsset?._id) }
  )?.data;

  const mergedSensorProfile = useMemo(
    () => profileGQL?.getMergedProfileForAsset.sensorProfile ?? null,
    [profileGQL]
  );

  if (isInitialLoading) {
    return (
      <Box
        className="flex h-full w-full items-center justify-center"
        data-testid="other-sensors-tab-panel-loading"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (noSensorData) {
    return (
      <Box
        className="text-sm bg-dashboard_subheader__bg p-6 m-2 text-asset-info-subheader"
        data-testid="other-sensors-tab-panel-no-data"
      >
        No Sensors Available.
      </Box>
    );
  }

  // MAIN

  return (
    <ThemeProvider theme={theme}>
      <Box className="w-full p-4" data-testid="other-sensors-tab-panel">
        {/****** Airbag sensor ******/}
        {selectedAsset?.sensors?.airbag && (
          <Grid container direction="row" className="mt-4" id={Sections.Airbag}>
            {/****** Airbag sensor cards ******/}
            <Grid container item lg={12} spacing={2} className="w-full mt-4">
              <AirBagStatus airbagMeasures={filteredAirbagMeasures} />
              <AirBagStatusCards
                selectedAsset={selectedAsset}
                airbagMeasures={filteredAirbagMeasures}
              />
            </Grid>

            {/****** Airbag chart ******/}
            <Grid container item lg={12} className="w-full mt-4">
              <AirBagTrendingGraph
                data={aggregatedAirBagData}
                setStartDateAirBag={setStartDateAirBag}
                setEndDateAirBag={setEndDateAirBag}
                startDateAirBag={startDateAirBag}
                endDateAirBag={endDateAirBag}
                selectedDateRangeOptionAirBag={selectedDateRangeOptionAirBag}
                setSelectedDateRangeOptionAirBag={
                  setSelectedDateRangeOptionAirBag
                }
                isLoading={isAirBagLoading}
                sensorProfile={
                  mergedSensorProfile?.configuration?.airbag ?? null
                }
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default OtherSensorsTabPanel;
