import { FC, memo, useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AssetDetentionTableData,
  FileFormat,
  FindDetentionHistoryOsQuery,
  DetentionTableDataInput,
  GetEventHistoryTableDataInput,
  SortOrder,
  TableDomain,
  TableViewType,
  useFindDetentionHistoryOsQuery,
} from "../../../../../graphql/operations";
import Spinner from "../../../../../shared/components/Spinner";
import { BackEndProcessingTable } from "../../../../../shared/components/Table/BackEndProcessingTable";
import {
  ServerSideExport,
  ServerSideExportFormat,
  StringTableFilterValue,
} from "../../../../../shared/components/Table/types";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { columnVisibilityModel, getColumns } from "./columns";

type GeofenceDwellHistoryProps = {
  geofenceId: string;
};

export const GeofenceDwellHistory: FC<GeofenceDwellHistoryProps> = memo(
  ({ geofenceId }: { geofenceId: string }) => {
    const apiRef = useGridApiRef();
    const {
      state: { appConfig },
    } = useAppContext();
    const timezone = usePreferredTimezone();
    const columns = useMemo(() => getColumns(timezone), [timezone]);
    const [isExporting, setIsExporting] = useState(false);
    const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
    const [isSendingEmail, setIsSendingEmail] = useState(false);

    const [queryInput, setQueryInput] = useState<DetentionTableDataInput>({
      geofence_id: geofenceId,
      sorting: [{ field: "eventDate", order: SortOrder.Desc }],
      pagination: {
        skip: 0,
        limit: appConfig.table.defaultRowsPerPage,
      },
    });

    const baseFilters = useMemo(
      () => ({ geofence_id: geofenceId }),
      [geofenceId]
    );

    const { data, isSuccess, isLoading, isRefetching } =
      useFindDetentionHistoryOsQuery<FindDetentionHistoryOsQuery, unknown>(
        {
          input: queryInput,
        },
        {
          enabled: Boolean(geofenceId),
        }
      );
    const updateQueryInput = useCallback(
      (data: Partial<GetEventHistoryTableDataInput>) =>
        setQueryInput((prev: DetentionTableDataInput) => ({
          ...prev,
          ...data,
        })),
      [setQueryInput]
    );

    useTableDataExporter<AssetDetentionTableData>({
      queryInput,
      apiRef,
      columns,
      domain: TableDomain.AssetsDetention,
      fileFormat,
      isExporting,
      setExporting: setIsExporting,
      baseFilters,
      totalCount: data?.findDetentionHistoryOS?.pagination.total,
    });

    const handleExport = useCallback((format: ServerSideExportFormat) => {
      if (format === ServerSideExport.EMAIL) {
        setIsSendingEmail(true);
      } else {
        setFileFormat(format);
        setIsExporting(true);
      }
    }, []);

    return (
      <Box className="bg-background h-full overflow-auto">
        <Box className="flex h-full w-full pt-2 px-4 pb-6 md:px-6 lg:px-16">
          <BackEndProcessingTable
            domain={TableDomain.AssetsDetention}
            queryInput={queryInput}
            apiRef={apiRef}
            tableType={TableViewType.AlertsHistory}
            tableName={"geofence-detention-history"}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            data={{
              rows: (data?.findDetentionHistoryOS?.assetDetentionTableData ??
                []) as AssetDetentionTableData[],
              pagination: data?.findDetentionHistoryOS?.pagination ?? undefined,
            }}
            baseFilters={baseFilters}
            updateQueryInput={updateQueryInput}
            sorting={queryInput.sorting ?? undefined}
            isDataRefetching={isRefetching}
            onExport={handleExport}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isSendingEmail={isSendingEmail}
            setSendingEmail={setIsSendingEmail}
            rowsPerPage={data?.findDetentionHistoryOS?.pagination?.limit}
            totalCount={data?.findDetentionHistoryOS?.pagination?.total}
            tableFiltersToSkip={[
              StringTableFilterValue.DoesNotContain,
              StringTableFilterValue.IsAnyOf,
            ]}
          />
          <Spinner counter={Number(isExporting) || Number(isSendingEmail)} />
        </Box>
      </Box>
    );
  }
);
