import { MutationKey, UseMutationOptions } from "@tanstack/react-query";
import {
  UpdateAssetOsMutation,
  useUpdateAssetOsMutation,
  UpdateAssetOsMutationVariables,
} from "../../../graphql/operations";

type UpdateAssetMutationOptions = UseMutationOptions<
  UpdateAssetOsMutation,
  unknown,
  UpdateAssetOsMutationVariables,
  MutationKey
>;

export const useUpdateAsset = (options: UpdateAssetMutationOptions) => {
  const { mutate, mutateAsync, isLoading } = useUpdateAssetOsMutation(options);

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};
