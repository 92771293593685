import { FC } from "react";
import { Card, CardHeader, CardContent, Typography, Chip } from "@mui/material";
import capitalize from "lodash/capitalize";
import { TRIP_STATUS_LABELS } from "../../../../../../../../constants/map";
import {
  AssetOs,
  SensorStatusUppercase,
} from "../../../../../../../../graphql/operations";
import { CustomTooltip } from "../../../../../../../../shared/components/CustomTooltip/CustomTooltip";
import { useDateInPreferredTimezone } from "../../../../../../../../shared/hooks/useDateInPreferredTimezone";
import { useGetPressureUnitPreference } from "../../../../../../../../shared/hooks/useGetPressureUnitPreference";
import { getConvertedPressureValueWithUnit } from "../../../../../../../../utils/convertPressure";
import { useGetSensorStateWidget } from "../../../../AssetShortTooltip/hooks/useGetSensorStateWidget";
import { getElapsedTimeSinceLabel } from "../../../helpers";
import { RegulatorSensorStatus } from "./RegulatorSensorStatus";

export interface RegulatorStatusCardProps {
  asset: AssetOs;
}

const RegulatorStatusCard: FC<RegulatorStatusCardProps> = ({ asset }) => {
  const pressureUnit = useGetPressureUnitPreference();

  const tripStatus = asset.tripStatus?.tripState;
  const regulatorReportDate = asset?.sensors?.psiAirSupply?.receivedDate;
  const psiAirSupplyData = asset?.sensors?.psiAirSupply?.data;
  const regulatorStatus =
    psiAirSupplyData?.statusSupplyPressure ?? SensorStatusUppercase.Unknown;
  const regulatorPressure = Number(psiAirSupplyData?.supplyPressure ?? 0);

  const timezoneDate = useDateInPreferredTimezone(regulatorReportDate);

  const { getTireSensorChip } = useGetSensorStateWidget();
  const statusDetails = getTireSensorChip(regulatorStatus, {
    iconWidth: "20px",
    iconHeight: "20px",
  });

  return (
    <Card
      className={`background-white border min-w-[280px]`}
      sx={{
        boxShadow: "none",
        borderRadius: "8px",
        borderColor: "var(--asset-card-border)",
      }}
      data-testid="asset-dashboard--tires-tab--card-regulator-status"
    >
      <CardHeader
        title={
          <Typography className="!text-lg !font-semibold text-brand">
            Regulator Sensor
          </Typography>
        }
      ></CardHeader>
      <CardContent>
        <RegulatorSensorStatus status={regulatorStatus} />
        <Typography
          className="text-card__typography"
          sx={{
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 400,
          }}
        >
          Current Status:
        </Typography>
        <Typography
          className="text-base text-card__typography"
          sx={{
            marginBottom: "10px",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 400,
          }}
        >
          <span data-testid="card-regulator-status--trip-status">
            {tripStatus
              ? TRIP_STATUS_LABELS[tripStatus] ?? capitalize(tripStatus)
              : ""}
            :
          </span>
          <span
            className="ml-1 -top-[2px] relative"
            data-testid="card-regulator-status--status-icon"
          >
            {statusDetails.icon}
          </span>
          <span
            className="ml-1 font-bold"
            data-testid="card-regulator-status--pressure"
          >
            {`${getConvertedPressureValueWithUnit(
              regulatorPressure,
              pressureUnit
            )}`}
          </span>
          <span
            className="ml-2"
            data-testid="card-regulator-status--report-date"
          >
            {timezoneDate && (
              <CustomTooltip title={timezoneDate} arrow>
                <Chip
                  component="span"
                  label={getElapsedTimeSinceLabel(
                    new Date(regulatorReportDate)
                  )}
                  sx={{
                    backgroundColor: "var(--primary-chip-bg)",
                    color: "var(--primary-chip-text)",
                    fontWeight: 700,
                    fontSize: "12px",
                    lineHeight: "12px",
                    borderRadius: "4px",
                    height: "18px",
                    "& .MuiChip-label ": {
                      padding: "0px 8px",
                    },
                  }}
                />
              </CustomTooltip>
            )}
          </span>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default RegulatorStatusCard;
