import {
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import {
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  ValueOptions,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../context/AppContext";
import {
  Dashboard,
  Maybe,
  TableViewType,
} from "../../../../graphql/operations";
import {
  getRows,
  Table,
  TableDataModes,
} from "../../../../shared/components/Table";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import { useExportedFileName } from "../../../../shared/hooks/useExportedFileName";
import { useUserData } from "../../../../shared/hooks/useUserData";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../AdminPanel/tabs/constants";
import { getColumns, tableKeyList } from "../utils";

export interface DashboardTableProps {
  apiRef: MutableRefObject<GridApiPremium>;
  dashboards: Maybe<Dashboard>[];
  rowSelectionModel?: Array<number>;
  isLoading: boolean;
  isError: boolean;
  onFilterChange: (filters: GridFilterModel) => void;
  onSortModelChange: (model: GridSortModel) => void;
  onSearchChange?: (searchQuery: string) => void;
  onPageChange: (page: number) => void;
  isRefetching: boolean;
  timezone: string;
  availableOrgsOptions: ValueOptions[];
  totalTableRowsCount: number;
  currentPage: number;
  dataPerPage: number;
}

const DashboardsTable: FC<DashboardTableProps> = ({
  apiRef,
  dashboards,
  onFilterChange,
  onSortModelChange,
  onPageChange,
  isRefetching,
  totalTableRowsCount,
  isLoading,
  isError,
  timezone,
  availableOrgsOptions,
  currentPage,
  dataPerPage,
  onSearchChange,
}) => {
  const navigate = useNavigate();
  const currentOrganization = useCurrentOrg();

  const {
    state: { appConfig },
  } = useAppContext();

  const [tableRows, setTableRows] = useState<Dashboard[]>([]);

  const fileName = useExportedFileName("Dashboards");
  const userData = useUserData();

  const columns = useMemo(() => {
    return getColumns(timezone, availableOrgsOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  const onRowClick = (row: GridRowParams<any>) => {
    navigate(`${NavigationRoutes.Dashboard}/${row.id}`);
  };

  const openDashboardInNewTab = useCallback(
    (rowId: string, event?: React.MouseEvent) => {
      if (event?.button === 1) {
        const assetUrl = `${NavigationRoutes.Dashboard}/${rowId}`;
        event.preventDefault();
        window.open(assetUrl, "_blank");
      }
    },
    []
  );

  // Update the columns when availableOrgs or timezone changes
  useEffect(() => {
    if (availableOrgsOptions)
      apiRef.current.updateColumns(getColumns(timezone, availableOrgsOptions));
  }, [availableOrgsOptions, timezone, apiRef]);

  useEffect(() => {
    if (dashboards && userData) {
      const tableDataExtended = dashboards.map((dashboard) => {
        return {
          ...dashboard,
          rowMetadata: {
            userFavoriteDashboards:
              userData?.user_preferences?.favorite_dashboards ?? [],
            organizationFavoriteDashboards:
              currentOrganization?.favorite_dashboards ?? [],
          },
        };
      });

      setTableRows(
        getRows<typeof dashboards>(tableDataExtended, columns, "", "", "id")
      );
    }
  }, [dashboards, columns, userData, currentOrganization]);

  return (
    <Box className="h-full w-full">
      <Table
        tableType={TableViewType.Dashboard}
        showToolbar
        rows={tableRows}
        columns={columns}
        apiRef={apiRef}
        loading={isLoading}
        error={isError}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchMinLength={appConfig.searchMinLength}
        tableName="dashboards"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        disableSelectionOnClick
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
        //pagination props
        // semi-server used for export to work with other server props
        handleDataMode={TableDataModes.SemiServer}
        onFilterModelChange={onFilterChange}
        onSortModelChange={onSortModelChange}
        onSearch={onSearchChange}
        onPageChange={onPageChange}
        searchKeys={tableKeyList}
        sortKeys={tableKeyList}
        onPaginationModelChange={onPageChange}
        isDataRefetching={isRefetching}
        rowCount={totalTableRowsCount ?? 0}
        onRowClick={onRowClick}
        onMouseDown={openDashboardInNewTab}
        currentPage={currentPage}
        pageSize={dataPerPage}
      />
    </Box>
  );
};

export default memo(DashboardsTable);
