import { UseFormReturn } from "react-hook-form";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button as MUIButton, Grid, ThemeProvider } from "@mui/material";
import { useAuthContext } from "../../../../context/AuthContext";
import { Button } from "../../../../shared/components/Button";
import { useAlertTheme } from "../../../AlertView/hooks/useAlertTheme";
import { AutomationForm } from "./AutomationForm";
import { AutomationInitialValues } from "./hooks/useAutomationForm";

interface AutomationSettingsProps {
  form: UseFormReturn<Partial<AutomationInitialValues>>;
  isTemplate: boolean;
  onSave: () => void;
  onDelete: () => void;
}

export const AutomationSettings = ({
  form,
  isTemplate,
  onSave,
  onDelete,
}: AutomationSettingsProps) => {
  const automationTheme = useAlertTheme();
  const { userRolePermissions } = useAuthContext();
  const automationPermissions = userRolePermissions.automations;

  const hasPermission = isTemplate
    ? automationPermissions.create
    : automationPermissions.edit;
  const disabled = !hasPermission;

  return (
    <ThemeProvider theme={automationTheme}>
      <AutomationForm form={form} isTemplate={isTemplate} disabled={disabled} />
      <Grid item className="px-16 py-8 flex justify-between items-baseline">
        <div>
          {!isTemplate && (
            <MUIButton
              className="global-text-btn global-text-btn--medium global-text-btn__theme--red !text-md !px-3"
              onClick={onDelete}
              color="error"
              size="small"
              data-testid="delete-automation-button"
              startIcon={<DeleteOutlineIcon fontSize="medium" />}
              disabled={disabled}
            >
              Delete
            </MUIButton>
          )}
        </div>
        <Button
          dataTestid="save-automation-button"
          className="!text-sm"
          text="Save"
          size="medium"
          theme="blue"
          variant="default"
          type="button"
          onClick={onSave}
          disabled={disabled}
        />
      </Grid>
    </ThemeProvider>
  );
};
