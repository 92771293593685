import { MutationKey, UseMutationOptions } from "@tanstack/react-query";
import {
  InitiateAssetsShareMutationVariables,
  InitiateAssetsShareOsMutation,
  useInitiateAssetsShareOsMutation,
} from "../../../graphql/operations";

type InitiateAssetsShareMutationOptions = UseMutationOptions<
  InitiateAssetsShareOsMutation,
  unknown,
  InitiateAssetsShareMutationVariables,
  MutationKey
>;

export const useInitiateAssetsShare = (
  options: InitiateAssetsShareMutationOptions
) => {
  const { mutate, mutateAsync, isLoading } =
    useInitiateAssetsShareOsMutation(options);

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};
