import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import {
  AssetOs,
  useFindAssetByIdOsQuery,
  FindAssetByIdOsQuery,
} from "../../../graphql/operations";

interface FindAssetByIdHook {
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isRefetching: boolean;
  isError: boolean;
  data: AssetOs | null;
  error: unknown;
}

type FindAssetByIdQueryInput = {
  assetId: string;
  timezone?: string;
  currentOrgId?: string;
};

type FindAssetByIdQueryOptions = UseQueryOptions<
  FindAssetByIdOsQuery,
  unknown,
  FindAssetByIdOsQuery,
  QueryKey
>;

export const useFindAssetById = (
  queryInput: FindAssetByIdQueryInput,
  queryOptions?: FindAssetByIdQueryOptions
): FindAssetByIdHook => {
  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    isRefetching,
    isError,
    error,
  } = useFindAssetByIdOsQuery({ input: queryInput }, queryOptions);

  return {
    isLoading,
    isFetching,
    isSuccess,
    isRefetching,
    isError,
    data: (data?.findAssetByIdOS as AssetOs) ?? null,
    error,
  };
};
