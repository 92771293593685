import {
  GetAssetsQueryInput,
  useGetAssetsTagsOsQuery,
} from "../../../graphql/operations";

export const useGetAssetsTags = (
  input: GetAssetsQueryInput
): {
  tags: string[];
  isLoading: boolean;
} => {
  const { isLoading, data } = useGetAssetsTagsOsQuery(
    { input },
    { enabled: !!input?.orgId }
  );

  return {
    tags: data?.getAssetsTagsOS ?? [],
    isLoading,
  };
};
