import { AssetUiConfigOs, Maybe } from "../../../../../graphql/operations";

export enum BATTERY_CHART_NAME {
  AllChart = "all_chart",
  Battery = "only_battery",
}

export enum BatteryUnitSymbol {
  Voltage = "V",
  Amperage = "A",
}

export interface ChartDataResult {
  date: string;
  tooltipLabel?: string;
  Battery?: Maybe<number>;
  "Primary Voltage"?: Maybe<number>;
  "Secondary Voltage"?: Maybe<number>;
  Solar?: Maybe<number>;
  min?: {
    Battery?: Maybe<number>;
    "Primary Voltage"?: Maybe<number>;
    "Secondary Voltage"?: Maybe<number>;
    Solar?: Maybe<number>;
  };
  max?: {
    Battery?: Maybe<number>;
    "Primary Voltage"?: Maybe<number>;
    "Secondary Voltage"?: Maybe<number>;
    Solar?: Maybe<number>;
  };
  units?: {
    Battery?: BatteryUnitSymbol;
    "Primary Voltage"?: BatteryUnitSymbol;
    "Secondary Voltage"?: BatteryUnitSymbol;
    Solar?: BatteryUnitSymbol;
  };
}

export interface BatteryChartsDataHookProps {
  imei: string;
  startDate: Maybe<Date>;
  endDate: Maybe<Date>;
  uiConfig: AssetUiConfigOs;
}
