import { FC, memo, Fragment } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import { Fields, JsonLogicResult } from "@react-awesome-query-builder/ui";
import isNil from "lodash/isNil";
import QueryBuilder from "../../QueryBuilder";
import { RequiredFlag } from "../../RequiredFlag";

interface QueryBuilderProps {
  label?: string;
  rules?: ControllerProps["rules"];
  name: string;
  control: Control<any>;
  labelClassName?: string;
  required?: boolean;
  configFields: Fields;
  shouldReset?: boolean;
  onReset?: () => void;
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
  disabled?: boolean;
}

export const helperText = (
  <Fragment>
    <div className="break-normal">
      "Add Group" creates groups of rules combined with conditions.
    </div>
    <div className="break-normal">
      "And", "Or" logical operators become available with multiple rules or
      groups.
    </div>
  </Fragment>
);

export const QueryBuilderElement: FC<QueryBuilderProps> = memo(
  ({
    label,
    rules,
    name,
    control,
    labelClassName = "!mb-2 !text-sm !font-bold bg-brand",
    required,
    handleUpdateField,
    fieldPath,
    disabled = false,
    ...rest
  }: QueryBuilderProps) => {
    return (
      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error, invalid },
        }) => {
          const onChangeHandler = ({
            logic,
            data,
            errors,
          }: JsonLogicResult) => {
            if (errors?.length) {
              console.error(errors);
            }
            const logicValue = !isNil(logic) ? JSON.stringify(logic) : "";
            onChange(logicValue);
            handleUpdateField && handleUpdateField(logicValue, fieldPath);
          };

          return (
            <FormControl
              fullWidth
              required={required}
              variant="standard"
              error={invalid}
            >
              {label && (
                <Typography
                  className={labelClassName}
                  data-testid="query-builder-element-label"
                >
                  {label}
                  {rules?.required && <RequiredFlag />}
                </Typography>
              )}
              <QueryBuilder
                {...rest}
                value={value ? JSON.parse(value) : ""}
                onQueryChange={onChangeHandler}
                disabled={disabled}
              />

              {error?.message && (
                <FormHelperText error={true}>{error?.message}</FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    );
  }
);
