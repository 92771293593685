import intersection from "lodash/intersection";
import { UserRole } from "../../../graphql/enums";

type Permissions = {
  view: boolean;
  create: boolean;
  edit: boolean;
  upload: boolean;
  export: boolean;
  remove: boolean;
};

type TabPermissions = {
  view: boolean;
};

type DashboardPermissions = {
  view: boolean;
  create: boolean;
  edit: boolean;
};

type ReportPermissions = Omit<Permissions, "upload"> & {
  run: boolean;
  schedule: boolean;
};

type DevicePermissions = Permissions & {
  check: boolean;
  transfer: boolean;
  viewHistory: boolean;
};

type AssetPermissions = Permissions & {
  check: boolean;
  share: boolean;
};

type AdminPanelPermissions = {
  view: boolean;
};
type AutomationsPermissions = Permissions & {
  run: boolean;
  pause: boolean;
};
type FaultCodePermissions = {
  create: boolean;
};

export type AssetDashboardTabPermissions = {
  summary: TabPermissions;
  battery: TabPermissions;
  cargo: TabPermissions;
  tires: TabPermissions;
  media: TabPermissions;
  brakes: TabPermissions;
  temperature: TabPermissions;
  lightCircuit: TabPermissions;
  liftgate: TabPermissions;
  otherSensors: TabPermissions;
  eventHistory: TabPermissions;
  detentionHistory: TabPermissions;
  dwell: TabPermissions;
  breadcrumbs: TabPermissions;
  alerts: TabPermissions;
  activityLog: TabPermissions;
  settings: TabPermissions;
};

type ConfigurationsPermissions = {
  view: boolean;
  edit: boolean;
  create: boolean;
};

type ProfilesPermissions = Permissions;

type SensorsPermissions = Permissions & {
  check: boolean;
};

type HelpPermissions = {
  view: boolean;
};

type RegionsPermissions = {
  view: boolean;
  create: boolean;
  edit: boolean;
};

type ZonesPermissions = {
  view: boolean;
  create: boolean;
  edit: boolean;
};

export type UserRolePermissionsByScope = {
  dashboard: DashboardPermissions;
  asset: AssetPermissions;
  home: TabPermissions;
  user: Permissions;
  org: Permissions;
  configurations: ConfigurationsPermissions;
  device: DevicePermissions;
  reports: ReportPermissions;
  geofences: Permissions;
  batches: Permissions;
  brands: Permissions;
  profiles: ProfilesPermissions;
  alerts: ReportPermissions;
  sensors: SensorsPermissions;
  help: HelpPermissions;
  adminPanel: AdminPanelPermissions;
  assetDashboardTabs: AssetDashboardTabPermissions;
  regions: RegionsPermissions;
  zones: ZonesPermissions;
  automations: AutomationsPermissions;
  faultCode: FaultCodePermissions;
};

export type UserAccessScope = `${keyof UserRolePermissionsByScope}.${
  | keyof Permissions
  | keyof DevicePermissions
  | keyof AssetPermissions
  | keyof ReportPermissions}`;

export const defaultUserRolePermissions: UserRolePermissionsByScope = {
  dashboard: {
    view: true,
    create: false,
    edit: false,
  },
  home: {
    view: false,
  },
  asset: {
    view: false,
    create: false,
    edit: false,
    upload: false,
    export: false,
    remove: false,
    check: false,
    share: false,
  },
  assetDashboardTabs: {
    summary: {
      view: false,
    },
    battery: {
      view: false,
    },
    cargo: {
      view: false,
    },
    tires: {
      view: false,
    },
    media: {
      view: false,
    },
    brakes: {
      view: false,
    },
    temperature: {
      view: false,
    },
    lightCircuit: {
      view: false,
    },
    liftgate: {
      view: false,
    },
    otherSensors: {
      view: false,
    },
    eventHistory: {
      view: false,
    },
    detentionHistory: {
      view: false,
    },
    dwell: {
      view: false,
    },
    breadcrumbs: {
      view: false,
    },
    alerts: {
      view: false,
    },
    activityLog: {
      view: false,
    },
    settings: {
      view: false,
    },
  },
  user: {
    view: false,
    create: false,
    edit: false,
    upload: false,
    export: false,
    remove: false,
  },
  org: {
    view: false,
    create: false,
    edit: false,
    upload: false,
    export: false,
    remove: false,
  },
  configurations: {
    view: false,
    create: false,
    edit: false,
  },
  device: {
    view: false,
    create: false,
    edit: false,
    upload: false,
    export: false,
    remove: false,
    check: false,
    transfer: false,
    viewHistory: false,
  },
  reports: {
    view: false,
    create: false,
    edit: false,
    run: false,
    schedule: false,
    export: false,
    remove: false,
  },
  geofences: {
    view: false,
    create: false,
    edit: false,
    upload: false,
    export: false,
    remove: false,
  },
  batches: {
    view: false,
    create: false,
    edit: false,
    upload: false,
    export: false,
    remove: false,
  },
  alerts: {
    view: false,
    create: false,
    edit: false,
    run: false,
    schedule: false,
    export: false,
    remove: false,
  },
  brands: {
    view: false,
    create: false,
    edit: false,
    upload: false,
    export: false,
    remove: false,
  },
  profiles: {
    view: false,
    create: false,
    edit: false,
    export: false,
    remove: false,
    upload: false,
  },
  sensors: {
    view: false,
    create: false,
    edit: false,
    upload: false,
    export: false,
    remove: false,
    check: false,
  },
  regions: {
    view: false,
    create: false,
    edit: false,
  },
  zones: {
    view: false,
    create: false,
    edit: false,
  },
  help: {
    view: true,
  },
  adminPanel: {
    //TODO: complete all the needed action
    view: false,
  },
  automations: {
    create: false,
    edit: false,
    export: false,
    pause: false,
    remove: false,
    run: false,
    upload: false,
    view: false,
  },
  faultCode: {
    create: false,
  },
};

export const isAdmin = (roles: UserRole[] | string[]) =>
  Boolean(
    intersection(roles, [UserRole.Superadmin, UserRole.Clientadmin]).length
  );

export const isSuperAdmin = (roles: string[]) =>
  Boolean(intersection(roles, [UserRole.Superadmin]).length);

export const getPermissionsMap = (
  roles: string[] | UserRole[]
): UserRolePermissionsByScope => {
  const admin = isAdmin(roles);
  const superAdmin = isSuperAdmin(roles);
  const assetManager = Boolean(
    intersection(roles, [
      UserRole.Clientadmin,
      UserRole.Superadmin,
      UserRole.Equipmentmanager,
    ]).length
  );

  const reportsManager = Boolean(
    intersection(roles, [
      UserRole.Clientadmin,
      UserRole.Superadmin,
      UserRole.Equipmentmanager,
      UserRole.Fleetmanager,
      UserRole.Supervisor,
      UserRole.Leaseagent,
      UserRole.Loadplanner,
      UserRole.Customerservice,
    ]).length
  );

  const batchesPermission = Boolean(
    intersection(roles, [UserRole.Clientadmin, UserRole.Superadmin]).length
  );

  const uploadAssetPermission = Boolean(
    intersection(roles, [
      UserRole.Clientadmin,
      UserRole.Superadmin,
      UserRole.Equipmentmanager,
      UserRole.Customerservice,
      UserRole.Fleetmanager,
      UserRole.Supervisor,
    ]).length
  );

  const transferAssetPermission = Boolean(
    intersection(roles, [
      UserRole.Clientadmin,
      UserRole.Superadmin,
      UserRole.Equipmentmanager,
      UserRole.Installer,
    ]).length
  );

  return {
    faultCode: {
      create: Boolean(intersection(roles, [UserRole.Superadmin]).length),
    },
    home: {
      view: Boolean(
        intersection(roles, [UserRole.Superadmin, UserRole.Clientadmin]).length
      ),
    },
    dashboard: {
      view: Boolean(
        intersection(roles, [
          UserRole.Loadplanner,
          UserRole.Fleetmanager,
          UserRole.Leaseagent,
          UserRole.Equipmentmanager,
          UserRole.Clientadmin,
          UserRole.Supervisor,
          UserRole.Fleetviewer,
          UserRole.Installer,
          UserRole.Superadmin,
          UserRole.Customerservice,
          UserRole.Maintenancemanager,
          UserRole.Shoptechnician,
          UserRole.Driver,
        ]).length
      ),
      create: Boolean(
        intersection(roles, [
          UserRole.Loadplanner,
          UserRole.Fleetmanager,
          UserRole.Leaseagent,
          UserRole.Equipmentmanager,
          UserRole.Clientadmin,
          UserRole.Supervisor,
          UserRole.Fleetviewer,
          UserRole.Installer,
          UserRole.Superadmin,
          UserRole.Customerservice,
          UserRole.Maintenancemanager,
          UserRole.Shoptechnician,
          UserRole.Driver,
        ]).length
      ),
      edit: Boolean(
        intersection(roles, [
          UserRole.Loadplanner,
          UserRole.Fleetmanager,
          UserRole.Leaseagent,
          UserRole.Equipmentmanager,
          UserRole.Clientadmin,
          UserRole.Supervisor,
          UserRole.Fleetviewer,
          UserRole.Installer,
          UserRole.Superadmin,
          UserRole.Customerservice,
          UserRole.Maintenancemanager,
          UserRole.Shoptechnician,
          UserRole.Driver,
        ]).length
      ),
    },
    asset: {
      view: Boolean(
        intersection(roles, [
          UserRole.Loadplanner,
          UserRole.Fleetmanager,
          UserRole.Leaseagent,
          UserRole.Equipmentmanager,
          UserRole.Clientadmin,
          UserRole.Supervisor,
          UserRole.Fleetviewer,
          UserRole.Installer,
          UserRole.Superadmin,
          UserRole.Customerservice,
          UserRole.Maintenancemanager,
          UserRole.Shoptechnician,
          UserRole.Driver,
        ]).length
      ),
      create: assetManager,
      edit:
        assetManager ||
        Boolean(intersection(roles, [UserRole.Installer]).length),
      upload: assetManager,
      export: uploadAssetPermission,
      remove: assetManager,
      check: transferAssetPermission,
      share: Boolean(
        intersection(roles, [UserRole.Clientadmin, UserRole.Superadmin]).length
      ),
    },
    assetDashboardTabs: {
      summary: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      battery: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      cargo: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      tires: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      media: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      brakes: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      temperature: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      lightCircuit: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      liftgate: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      otherSensors: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      eventHistory: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      detentionHistory: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      dwell: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      breadcrumbs: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      alerts: {
        view: Boolean(
          intersection(roles, [
            UserRole.Loadplanner,
            UserRole.Fleetmanager,
            UserRole.Leaseagent,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Supervisor,
            UserRole.Fleetviewer,
            UserRole.Installer,
            UserRole.Superadmin,
            UserRole.Customerservice,
            UserRole.Maintenancemanager,
            UserRole.Shoptechnician,
            UserRole.Driver,
          ]).length
        ),
      },
      activityLog: {
        view: Boolean(
          intersection(roles, [
            UserRole.Superadmin,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Installer,
          ]).length
        ),
      },
      settings: {
        view: Boolean(
          intersection(roles, [
            UserRole.Superadmin,
            UserRole.Equipmentmanager,
            UserRole.Clientadmin,
            UserRole.Installer,
          ]).length
        ),
      },
    },
    user: {
      view: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
        ]).length
      ),
      create: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
        ]).length
      ),
      edit: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
        ]).length
      ),
      upload:
        admin ||
        Boolean(intersection(roles, [UserRole.Maintenancemanager]).length),
      export: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
        ]).length
      ),
      remove: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
        ]).length
      ),
    },
    org: {
      view: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Equipmentmanager,
          UserRole.Maintenancemanager,
        ]).length
      ),
      create: admin,
      edit: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Equipmentmanager,
          UserRole.Maintenancemanager,
        ]).length
      ),
      upload: admin,
      export: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Equipmentmanager,
          UserRole.Maintenancemanager,
        ]).length
      ),
      remove: admin,
    },
    configurations: {
      view: Boolean(
        intersection(roles, [
          UserRole.Customerservice,
          UserRole.Loadplanner,
          UserRole.Fleetmanager,
          UserRole.Leaseagent,
          UserRole.Equipmentmanager,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
          UserRole.Clientadmin,
          UserRole.Superadmin,
        ]).length
      ),
      create: admin,
      edit: admin,
    },
    device: {
      view: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Installer,
        ]).length
      ),
      create: superAdmin,
      edit: superAdmin,
      upload: superAdmin,
      export: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Installer,
        ]).length
      ),
      remove: Boolean(
        intersection(roles, [UserRole.Clientadmin, UserRole.Superadmin]).length
      ),
      check: superAdmin,
      transfer: superAdmin,
      viewHistory: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Installer,
        ]).length
      ),
    },
    geofences: {
      view: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Equipmentmanager,
          UserRole.Customerservice,
          UserRole.Leaseagent,
        ]).length
      ),
      create: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Equipmentmanager,
          UserRole.Customerservice,
          UserRole.Leaseagent,
        ]).length
      ),
      edit: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Equipmentmanager,
          UserRole.Customerservice,
          UserRole.Leaseagent,
        ]).length
      ),
      upload: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Equipmentmanager,
          UserRole.Customerservice,
          UserRole.Leaseagent,
        ]).length
      ),
      export: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Equipmentmanager,
          UserRole.Customerservice,
          UserRole.Leaseagent,
        ]).length
      ),
      remove: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Equipmentmanager,
          UserRole.Customerservice,
          UserRole.Leaseagent,
        ]).length
      ),
    },
    reports: {
      view: reportsManager,
      create: reportsManager,
      edit: reportsManager,
      run: reportsManager,
      schedule: reportsManager,
      export: reportsManager,
      remove: reportsManager,
    },
    batches: {
      view: batchesPermission,
      create: batchesPermission,
      edit: batchesPermission,
      upload: batchesPermission,
      export: batchesPermission,
      remove: batchesPermission,
    },
    alerts: {
      view: reportsManager,
      create: reportsManager,
      edit: reportsManager,
      run: reportsManager,
      schedule: reportsManager,
      export: reportsManager,
      remove: reportsManager,
    },
    brands: {
      view: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
        ]).length
      ),
      create: superAdmin,
      upload: superAdmin,
      export: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
        ]).length
      ),
      edit: superAdmin,
      remove: superAdmin,
    },
    profiles: {
      view: Boolean(
        intersection(roles, [
          UserRole.Customerservice,
          UserRole.Loadplanner,
          UserRole.Fleetmanager,
          UserRole.Leaseagent,
          UserRole.Equipmentmanager,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
          UserRole.Clientadmin,
          UserRole.Superadmin,
        ]).length
      ),
      create: Boolean(
        intersection(roles, [UserRole.Superadmin, UserRole.Clientadmin]).length
      ),
      upload: Boolean(
        intersection(roles, [UserRole.Superadmin, UserRole.Clientadmin]).length
      ),
      edit: Boolean(
        intersection(roles, [UserRole.Superadmin, UserRole.Clientadmin]).length
      ),
      export: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
        ]).length
      ),
      remove: Boolean(
        intersection(roles, [UserRole.Superadmin, UserRole.Clientadmin]).length
      ),
    },
    sensors: {
      view: Boolean(
        intersection(roles, [
          UserRole.Customerservice,
          UserRole.Loadplanner,
          UserRole.Fleetmanager,
          UserRole.Leaseagent,
          UserRole.Equipmentmanager,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
          UserRole.Clientadmin,
          UserRole.Superadmin,
        ]).length
      ),
      create: Boolean(
        intersection(roles, [UserRole.Superadmin, UserRole.Clientadmin]).length
      ),
      edit: Boolean(
        intersection(roles, [UserRole.Superadmin, UserRole.Clientadmin]).length
      ),
      upload: superAdmin,
      export: Boolean(
        intersection(roles, [
          UserRole.Customerservice,
          UserRole.Loadplanner,
          UserRole.Fleetmanager,
          UserRole.Leaseagent,
          UserRole.Equipmentmanager,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
          UserRole.Clientadmin,
          UserRole.Superadmin,
        ]).length
      ),
      remove: Boolean(
        intersection(roles, [UserRole.Superadmin, UserRole.Clientadmin]).length
      ),
      check: Boolean(
        intersection(roles, [
          UserRole.Customerservice,
          UserRole.Loadplanner,
          UserRole.Fleetmanager,
          UserRole.Leaseagent,
          UserRole.Equipmentmanager,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
          UserRole.Clientadmin,
          UserRole.Superadmin,
        ]).length
      ),
    },
    help: {
      view: Boolean(
        intersection(roles, [
          UserRole.Customerservice,
          UserRole.Loadplanner,
          UserRole.Fleetmanager,
          UserRole.Leaseagent,
          UserRole.Equipmentmanager,
          UserRole.Supervisor,
          UserRole.Maintenancemanager,
          UserRole.Clientadmin,
          UserRole.Superadmin,
        ]).length
      ),
    },
    adminPanel: {
      view: Boolean(
        intersection(roles, [
          UserRole.Clientadmin,
          UserRole.Superadmin,
          UserRole.Supervisor,
          UserRole.Fleetmanager,
          UserRole.Equipmentmanager,
          UserRole.Loadplanner,
          UserRole.Leaseagent,
          UserRole.Maintenancemanager,
          UserRole.Customerservice,
        ]).length
      ),
    },
    regions: {
      view: admin,
      create: admin,
      edit: admin,
    },
    zones: {
      view: admin,
      create: admin,
      edit: admin,
    },
    automations: {
      create: superAdmin,
      edit: superAdmin,
      export: superAdmin,
      pause: superAdmin,
      remove: superAdmin,
      run: superAdmin,
      upload: superAdmin,
      view: admin,
    },
  };
};
