import { FC, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as AirbagSensorDark } from "../../../../../assets/svgs/airbagSensorDark.svg";
import { ReactComponent as AirbagSensorLight } from "../../../../../assets/svgs/airbagSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AssetOs,
  SensorProfileConfigType,
  useSetAssetSensorProfilesMutation,
  SetAssetSensorProfilesInput,
  AssetSensorProfilesResponseStatus,
  SetAssetSensorProfilesMutation,
  MergedProfileForAsset,
  SetAssetSensorProfilesResponse,
} from "../../../../../graphql/operations";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractRules,
  airbagDefaultValues,
  prepareRulesPayload,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Spinner from "../../../../../shared/components/Spinner";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { SensorValueType } from "../../../../../shared/helpers/sensors.utils";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { useUserPermission } from "../../../../../shared/hooks/useUserPermission";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";
import {
  getConvertedPressureValues,
  getPressureUnitLabel,
  prepareConvertedMbarPressuresPayload,
} from "../../../../../utils/convertPressure";
import {
  analyzeResponse,
  handleAnalyzedResponse,
} from "../../../../AdminPanel/tabs/Sensors/sensorsUtils";
import SaveCancelButtons from "../SaveCancelButtons/SaveCancelButtons";

interface OtherSensorsProps {
  asset: AssetOs;
  assetMergedSensorProfile: MergedProfileForAsset;
  isAssetRefetching: boolean;
}

export const OtherSensorsComponent: FC<OtherSensorsProps> = ({
  asset,
  assetMergedSensorProfile,
  isAssetRefetching,
}: OtherSensorsProps) => {
  const pressureUnit = useGetPressureUnitPreference();
  const [minAirbagPressureConverted, maxAirbagPressureConverted] =
    getConvertedPressureValues(
      [MinValuesBySensorType.airbag, MaxValuesBySensorType.airbag],
      pressureUnit
    );

  const { configuration } = assetMergedSensorProfile;

  const initialAirbagData = getConvertedPressureValues(
    extractRules(configuration?.airbag?.measures?.match?.thresholds) ||
      airbagDefaultValues,
    pressureUnit
  );

  // State
  const [airbagSettings, setAirbagSettings] = useState(initialAirbagData);

  const queryClient = useQueryClient();
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
  };
  const isUserAllowed = useUserPermission("assetManagement.editSettings");

  // GraphQL hooks
  const { mutate: mutateOtherSensors, isLoading } =
    useSetAssetSensorProfilesMutation({
      onSuccess: async (data: SetAssetSensorProfilesMutation) => {
        const response = analyzeResponse(
          data?.setAssetSensorProfiles as SetAssetSensorProfilesResponse[],
          AssetSensorProfilesResponseStatus.Success
        );
        handleAnalyzedResponse(
          response,
          dispatchErrorMessage,
          dispatchSuccessMessage,
          queryClient
        );
        queryClient.invalidateQueries({
          queryKey: ["getMergedProfileForAsset"],
        });
      },
    });

  // Handlers
  const dispatchSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success",
        text: "Other Sensor Settings Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Settings Uploading Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleSubmit = async () => {
    if (asset.imei) {
      const input: SetAssetSensorProfilesInput = {
        selectedImeis: [asset.imei],
        orgId: asset.customer_orgs_id ?? "",
        sensors: {
          airbag: {
            measures: {
              sensorType: SensorValueType.Pressure,
              ...prepareRulesPayload(
                SensorProfileConfigType.Airbag,
                prepareConvertedMbarPressuresPayload(
                  airbagSettings,
                  pressureUnit
                ),
                MinValuesBySensorType.airbag,
                MaxValuesBySensorType.airbag,
                1
              ),
            },
          },
        },
      };

      mutateOtherSensors({ input });
    }
  };

  const handleReset = () => {
    setAirbagSettings(initialAirbagData);
  };

  const isAnySliderDirty =
    airbagSettings.toString() !== initialAirbagData.toString();
  const isDisabled = isAssetRefetching || isLoading || !isAnySliderDirty;

  return (
    <>
      <Grid container className="mb-10">
        <Grid item xs={12} data-testid="settings-air-bag-pressure">
          <Box className="mb-16">
            <Typography className="assetSettingsSectionTitle">
              Air Bag Pressure Settings
            </Typography>
            {isLightTheme ? (
              <AirbagSensorDark
                style={svgIconSettings}
                data-testid="temperature-profile-drawer-thermostat"
              />
            ) : (
              <AirbagSensorLight
                style={svgIconSettings}
                data-testid="temperature-profile-drawer-thermostat"
              />
            )}
          </Box>
          <SensorSlider
            values={airbagSettings}
            min={minAirbagPressureConverted}
            max={maxAirbagPressureConverted}
            marks={sliderMarks(
              minAirbagPressureConverted,
              maxAirbagPressureConverted,
              getPressureUnitLabel(pressureUnit)
            )}
            disabled={isLoading}
            onChange={setAirbagSettings}
          />
        </Grid>
      </Grid>

      {isUserAllowed && (
        <SaveCancelButtons
          handleCancelClick={handleReset}
          handleSubmitClick={handleSubmit}
          isLoading={isLoading}
          areButtonsDisabled={isDisabled}
          isUserAllowed={isUserAllowed}
        />
      )}
      <Spinner counter={Number(isLoading)} />
    </>
  );
};
