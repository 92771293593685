import { useMemo } from "react";
import { QueryObserverResult } from "@tanstack/react-query";
import { capitalize, lowerFirst } from "lodash";
import { TRIP_STATUS, TRIP_STATUS_LABELS } from "../../../constants/map";
import {
  AccountsReportDataResponse,
  AssetAbsFaultCodesDataResponseOs,
  AssetDetentionReportDataResponseOs,
  AssetInstallTableDataResponse,
  AssetTransferTableDataResponse,
  DwellHierarchyReportDataResponseOs,
  GetDwellHierarchyTableDataResponse,
  GetTableDataInput,
  MediaActivitiesReportDataResponse,
  Pagination,
  ReportAlertHistoryTableDataResponse,
  ReportType,
  SensorsReportDataResponse,
  SensorsReportDataResponseOs,
  TableFilterLinkOperator,
  useFindAssetAbsFaultCodesReportDataOsQuery,
  useFindAssetDetentionReportDataOsQuery,
  useFindAssetInventoryOsQuery,
  useGetAssetTransferTableDataQuery,
  useFindAssetYardOsQuery,
  useFindDwellHierarchyOsQuery,
  useFindMediaActivitiesQuery,
  useFindOutsideGeofenceOsQuery,
  useFindSensorsOsQuery,
  useGetAccountsReportDataQuery,
  useGetAssetInstallTableDataQuery,
  useGetMileageReportDataOsQuery,
  useGetReportAlertHistoryTableDataQuery,
  MileageReportDataRow,
  AssetTripStatusOs,
  AssetReportDataRowOs,
  AssetReportRowDataResponseOs,
  MileageReportDataResponseOs,
} from "../../../graphql/operations";
import { prepareDefaultDwellHierarchyFilter } from "../helpers/helpers";

interface IUseReportHistoryApi {
  orgId?: string;
  reportType: string;
  reportQueryParameters: Record<string, any>;
  trigger?: string;
  queryInput: GetTableDataInput;
}

interface IUseReportHistoryApiResult {
  data:
    | AssetTransferTableDataResponse
    | AssetAbsFaultCodesDataResponseOs
    | AssetReportRowDataResponseOs
    | AccountsReportDataResponse
    | ReportAlertHistoryTableDataResponse
    | GetDwellHierarchyTableDataResponse
    | DwellHierarchyReportDataResponseOs
    | MediaActivitiesReportDataResponse
    | MileageReportDataResponseOs
    | SensorsReportDataResponse
    | SensorsReportDataResponseOs
    | AssetDetentionReportDataResponseOs
    | AssetInstallTableDataResponse;
  isLoading: boolean;
  isRefetching: boolean;
  isSuccess: boolean;
  refetch: () => Promise<QueryObserverResult>;
}

export const useReportHistoryApi = (
  {
    orgId,
    reportType,
    reportQueryParameters,
    trigger = "",
    queryInput,
  }: IUseReportHistoryApi,
  shouldFetch = true
): IUseReportHistoryApiResult => {
  const defaultReportQueryParameters = {
    orgIds: reportQueryParameters.orgIds ?? null,
    searchText: reportQueryParameters.searchText,
    filterList: reportQueryParameters.filterList,
    sorting: reportQueryParameters.sorting,
    pagination: reportQueryParameters.pagination,
  };
  const paramTableFilters = queryInput.tableFilters;

  const defaultReportTableDataInput = {
    // searchText, sorting, pagination
    ...queryInput,
    tableFilters: {
      linkOperator:
        paramTableFilters?.linkOperator ?? TableFilterLinkOperator.And,
      filters: paramTableFilters?.filters ?? [],
    },
  };

  const defaultDateRangeQueryParameters = {
    startDate: reportQueryParameters.startDate ?? null,
    endDate: reportQueryParameters.endDate ?? null,
  };
  const defaultPaginationResponse: Pagination = useMemo(
    () => ({ total: 0, limit: 0, skip: 0 }),
    []
  );

  const assetInstallHistoryQuery = useGetAssetInstallTableDataQuery(
    {
      input: {
        ...defaultReportTableDataInput,
        ...defaultDateRangeQueryParameters,
        orgIds: reportQueryParameters.orgIds,
        jsonLogic: trigger,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.AssetInstall && !!orgId && shouldFetch,
      select: ({ getAssetInstallTableData }) => getAssetInstallTableData,
    }
  );

  // Toggle between results based on the feature flag
  const {
    data: installQueryData,
    isLoading: isInstallReportLoading,
    isRefetching: isInstallReportRefetching,
    isSuccess: isInstallReportSuccess,
    refetch: refetchInstallReport,
  } = assetInstallHistoryQuery;

  // should be enabled only for asset transfer report
  const assetTransferHistoryQuery = useGetAssetTransferTableDataQuery(
    {
      input: {
        ...defaultReportTableDataInput,
        ...defaultDateRangeQueryParameters,
        orgIds: reportQueryParameters.orgIds,
        jsonLogic: trigger,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.AssetTransfer && !!orgId && shouldFetch,
      select: ({ getAssetTransferTableData }) => getAssetTransferTableData,
    }
  );
  const {
    data: transferQueryData,
    isLoading: isTransferReportLoading,
    isRefetching: isTransferReportRefetching,
    isSuccess: isTransferReportSuccess,
    refetch: refetchTransferReport,
  } = assetTransferHistoryQuery;

  const {
    data: accountsReportData,
    isLoading: isAccountsReportLoading,
    isRefetching: isAccountsReportRefetching,
    isSuccess: isAccountsReportSuccess,
    refetch: refetchAccountsReport,
  } = useGetAccountsReportDataQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        searchText: queryInput?.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.Accounts && !!orgId && shouldFetch,
    }
  );

  // should be enabled only for alert history report
  const {
    data: alertHistoryReportData,
    isLoading: isAlertHistoryReportLoading,
    isRefetching: isAlertHistoryReportRefetching,
    isSuccess: isAlertHistoryReportSuccess,
    refetch: refetchAlertHistoryReport,
  } = useGetReportAlertHistoryTableDataQuery(
    {
      input: {
        ...defaultReportTableDataInput,
        ...defaultDateRangeQueryParameters,
        orgIds: reportQueryParameters.orgIds,
        jsonLogic: trigger,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.AlertHistory && !!orgId && shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getReportAlertHistoryTableData }) =>
        getReportAlertHistoryTableData,
    }
  );

  const {
    data: inventoryReportData,
    isLoading: isInventoryReportLoading,
    isRefetching: isInventoryReportRefetching,
    isSuccess: isInventoryReportSuccess,
    refetch: refetchInventoryReport,
  } = useFindAssetInventoryOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.AssetInventory && !!orgId && shouldFetch,
    }
  );

  const {
    data: yardReportData,
    isLoading: yardReportLoading,
    isRefetching: yardReportRefetching,
    isSuccess: yardReportSuccess,
    refetch: refetchYardReport,
  } = useFindAssetYardOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.AssetYard && !!orgId && shouldFetch,
    }
  );

  const {
    data: outsideGeofenceReportData,
    isLoading: outsideGeofenceReportLoading,
    isRefetching: outsideGeofenceReportRefetching,
    isSuccess: outsideGeofenceReportSuccess,
    refetch: outsideGeofenceReportRefetch,
  } = useFindOutsideGeofenceOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.OutsideGeofence && !!orgId && shouldFetch,
    }
  );

  const outsideGeofenceAssets = useMemo(() => {
    const outsideGeofenceQueryDataOS =
      outsideGeofenceReportData?.findOutsideGeofenceOS;

    const data = (outsideGeofenceQueryDataOS?.data ?? []).map(
      (outsideGeofenceAsset) => {
        const { tripStatus } = outsideGeofenceAsset;
        const tripState = tripStatus?.tripState
          ? TRIP_STATUS_LABELS[tripStatus.tripState as AssetTripStatusOs] ??
            capitalize(tripStatus.tripState)
          : "";

        return {
          ...outsideGeofenceAsset,
          device_associated: "No",
          tripStatus: {
            ...outsideGeofenceAsset.tripStatus,
            tripState,
          },
        };
      }
    );
    const pagination =
      outsideGeofenceQueryDataOS?.pagination ?? defaultPaginationResponse;

    return {
      data,
      pagination,
    };
  }, [outsideGeofenceReportData, defaultPaginationResponse]);

  const {
    data: mileageReportData,
    isLoading: isMileageReportLoading,
    isRefetching: isMileageReportRefetching,
    isSuccess: isMileageReportSuccess,
    refetch: refetchMileageReport,
  } = useGetMileageReportDataOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.Mileage && !!orgId && shouldFetch,
    }
  );

  const {
    data: sensorsReportData,
    isLoading: isSensorsReportLoading,
    isRefetching: isSensorsReportRefetching,
    isSuccess: isSensorsReportSuccess,
    refetch: refetchSensorsReport,
  } = useFindSensorsOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.Sensors && !!orgId && shouldFetch,
    }
  );

  const {
    data: mediaActivitiesReportData,
    isLoading: isMediaActivitiesReportLoading,
    isRefetching: isMediaActivitiesReportRefetching,
    isSuccess: isMediaActivitiesReportSuccess,
    refetch: refetchMediaActivitiesReport,
  } = useFindMediaActivitiesQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        ...defaultDateRangeQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.MediaActivities && !!orgId && shouldFetch,
    }
  );

  const {
    data: AssetAbsFaultCodesReportData,
    isLoading: isAssetAbsFaultCodesReportLoading,
    isRefetching: isAssetAbsFaultCodesReportRefetching,
    isSuccess: isAssetAbsFaultCodesReportSuccess,
    refetch: refetchAssetAbsFaultCodesReport,
  } = useFindAssetAbsFaultCodesReportDataOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        ...defaultDateRangeQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.AssetAbsFaultHistory &&
        !!orgId &&
        shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ findAssetABSFaultCodesReportDataOS }) =>
        findAssetABSFaultCodesReportDataOS,
    }
  );

  const {
    data: assetDetentionReportData,
    isLoading: isAssetDetentionReportLoading,
    isRefetching: isAssetDetentionReportRefetching,
    isSuccess: isAssetDetentionReportReportSuccess,
    refetch: refetchAssetDetentionReportReport,
  } = useFindAssetDetentionReportDataOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.AssetDetention && !!orgId && shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ findAssetDetentionReportDataOS }) =>
        findAssetDetentionReportDataOS,
    }
  );

  const {
    data: dwellHierarchyReportData,
    isLoading: isDwellHierarchyReportLoading,
    isRefetching: isDwellHierarchyReportRefetching,
    isSuccess: isDwellHierarchyReportSuccess,
    refetch: refetchDwellHierarchyReport,
  } = useFindDwellHierarchyOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        searchText: queryInput.searchText,
        filterList: {
          linkOperator:
            defaultReportQueryParameters.filterList?.linkOperator ||
            TableFilterLinkOperator.And,
          filters: defaultReportQueryParameters.filterList
            ? [
                ...defaultReportQueryParameters.filterList.filters,
                prepareDefaultDwellHierarchyFilter(),
              ]
            : [prepareDefaultDwellHierarchyFilter()],
        },
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.DwellHierarchy && !!orgId && shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
    }
  );

  const transferHistory = useMemo(() => {
    return {
      data: transferQueryData?.data ?? [],
      pagination: transferQueryData?.pagination ?? defaultPaginationResponse,
    };
  }, [
    defaultPaginationResponse,
    transferQueryData?.data,
    transferQueryData?.pagination,
  ]);

  const installHistory = useMemo(() => {
    return {
      data: installQueryData?.data ?? [],
      pagination: installQueryData?.pagination ?? defaultPaginationResponse,
    };
  }, [
    defaultPaginationResponse,
    installQueryData?.data,
    installQueryData?.pagination,
  ]);

  const alertHistoryReportHistory = useMemo(
    () => ({
      data: alertHistoryReportData?.data ?? [],
      pagination:
        alertHistoryReportData?.pagination ?? defaultPaginationResponse,
    }),
    [alertHistoryReportData, defaultPaginationResponse]
  );

  const assetInventory = useMemo(() => {
    const data = (inventoryReportData?.findAssetInventoryOS.data ?? []).map(
      (inventory) => {
        const { tripStatus } = inventory;
        const tripState = tripStatus?.tripState
          ? TRIP_STATUS_LABELS[tripStatus.tripState as AssetTripStatusOs] ??
            capitalize(tripStatus.tripState)
          : "";

        return {
          ...inventory,
          device_associated: "No",
          tripStatus: {
            ...inventory.tripStatus,
            tripState,
          },
        };
      }
    ) as AssetReportDataRowOs[];
    const pagination =
      inventoryReportData?.findAssetInventoryOS?.pagination ??
      defaultPaginationResponse;

    return {
      data,
      pagination,
    };
  }, [inventoryReportData, defaultPaginationResponse]);

  const assetYard = useMemo(() => {
    const data = (yardReportData?.findAssetYardOS?.data ?? []).map(
      (assetYard) => {
        const { tripStatus } = assetYard;
        const tripState = tripStatus?.tripState
          ? TRIP_STATUS_LABELS[tripStatus.tripState as AssetTripStatusOs] ??
            capitalize(tripStatus.tripState)
          : "";

        return {
          ...assetYard,
          device_associated: "No",
          tripStatus: {
            ...assetYard.tripStatus,
            tripState,
          },
        };
      }
    );
    const pagination =
      yardReportData?.findAssetYardOS?.pagination ?? defaultPaginationResponse;

    return {
      data,
      pagination,
    };
  }, [yardReportData, defaultPaginationResponse]);

  const mileageReportHistory = useMemo(() => {
    const mileageQueryDataOS = mileageReportData?.getMileageReportDataOS;

    const data: MileageReportDataRow[] = (mileageQueryDataOS?.data ?? []).map(
      (item) => {
        return {
          ...item,
          tripState: item.tripState
            ? TRIP_STATUS_LABELS[lowerFirst(item.tripState) as TRIP_STATUS] ??
              capitalize(item.tripState)
            : "",
        };
      }
    );
    const pagination =
      mileageQueryDataOS?.pagination ?? defaultPaginationResponse;

    return {
      data,
      pagination,
    };
  }, [mileageReportData, defaultPaginationResponse]);

  const sensorsReportHistory = useMemo(() => {
    const sensorsQueryDataOS = sensorsReportData?.findSensorsOS;

    return {
      data: sensorsQueryDataOS?.data ?? [],
      pagination: sensorsQueryDataOS?.pagination ?? defaultPaginationResponse,
    };
  }, [sensorsReportData, defaultPaginationResponse]);

  const mediaActivitiesReportHistory = useMemo(
    () => ({
      data: mediaActivitiesReportData?.findMediaActivities?.data ?? [],
      pagination:
        mediaActivitiesReportData?.findMediaActivities?.pagination ??
        defaultPaginationResponse,
    }),
    [mediaActivitiesReportData?.findMediaActivities, defaultPaginationResponse]
  );

  const accountsReport = useMemo(
    () => ({
      data: accountsReportData?.getAccountsReportData?.data ?? [],
      pagination:
        accountsReportData?.getAccountsReportData?.pagination ??
        defaultPaginationResponse,
    }),
    [accountsReportData?.getAccountsReportData, defaultPaginationResponse]
  );

  const dwellHierarchyReportHistory = useMemo(() => {
    return {
      data: dwellHierarchyReportData?.findDwellHierarchyOS?.data ?? [],
      pagination:
        dwellHierarchyReportData?.findDwellHierarchyOS.pagination ??
        defaultPaginationResponse,
    };
  }, [dwellHierarchyReportData, defaultPaginationResponse]);

  const assetABSFaultCodesReportHistory = useMemo(() => {
    return {
      data: AssetAbsFaultCodesReportData?.data ?? [],
      pagination:
        AssetAbsFaultCodesReportData?.pagination ?? defaultPaginationResponse,
    };
  }, [
    AssetAbsFaultCodesReportData?.data,
    AssetAbsFaultCodesReportData?.pagination,
    defaultPaginationResponse,
  ]);

  const assetDetentionReportDataHistory = useMemo(() => {
    return {
      data: assetDetentionReportData?.data ?? [],
      pagination:
        assetDetentionReportData?.pagination ?? defaultPaginationResponse,
    };
  }, [
    assetDetentionReportData?.data,
    assetDetentionReportData?.pagination,
    defaultPaginationResponse,
  ]);

  switch (reportType) {
    case ReportType.AssetTransfer: {
      return {
        data: transferHistory,
        isLoading: isTransferReportLoading,
        isRefetching: isTransferReportRefetching,
        isSuccess: isTransferReportSuccess,
        refetch: refetchTransferReport,
      };
    }
    case ReportType.AssetInstall: {
      return {
        data: installHistory,
        isLoading: isInstallReportLoading,
        isRefetching: isInstallReportRefetching,
        isSuccess: isInstallReportSuccess,
        refetch: refetchInstallReport,
      };
    }
    case ReportType.AssetInventory: {
      return {
        data: assetInventory,
        isLoading: isInventoryReportLoading,
        isRefetching: isInventoryReportRefetching,
        isSuccess: isInventoryReportSuccess,
        refetch: refetchInventoryReport,
      };
    }
    case ReportType.AlertHistory: {
      return {
        data: alertHistoryReportHistory,
        isLoading: isAlertHistoryReportLoading,
        isRefetching: isAlertHistoryReportRefetching,
        isSuccess: isAlertHistoryReportSuccess,
        refetch: refetchAlertHistoryReport,
      };
    }
    case ReportType.Mileage: {
      return {
        data: mileageReportHistory,
        isLoading: isMileageReportLoading,
        isRefetching: isMileageReportRefetching,
        isSuccess: isMileageReportSuccess,
        refetch: refetchMileageReport,
      };
    }
    case ReportType.Sensors: {
      return {
        data: sensorsReportHistory,
        isLoading: isSensorsReportLoading,
        isRefetching: isSensorsReportRefetching,
        isSuccess: isSensorsReportSuccess,
        refetch: refetchSensorsReport,
      };
    }
    case ReportType.AssetYard: {
      return {
        data: assetYard as any,
        isLoading: yardReportLoading,
        isRefetching: yardReportRefetching,
        isSuccess: yardReportSuccess,
        refetch: refetchYardReport,
      };
    }
    case ReportType.OutsideGeofence: {
      return {
        data: outsideGeofenceAssets as any,
        isLoading: outsideGeofenceReportLoading,
        isRefetching: outsideGeofenceReportRefetching,
        isSuccess: outsideGeofenceReportSuccess,
        refetch: outsideGeofenceReportRefetch,
      };
    }
    case ReportType.Accounts: {
      return {
        data: accountsReport,
        isLoading: isAccountsReportLoading,
        isRefetching: isAccountsReportRefetching,
        isSuccess: isAccountsReportSuccess,
        refetch: refetchAccountsReport,
      };
    }
    case ReportType.DwellHierarchy: {
      return {
        data: dwellHierarchyReportHistory,
        isLoading: isDwellHierarchyReportLoading,
        isRefetching: isDwellHierarchyReportRefetching,
        isSuccess: isDwellHierarchyReportSuccess,
        refetch: refetchDwellHierarchyReport,
      };
    }
    case ReportType.MediaActivities: {
      return {
        data: mediaActivitiesReportHistory,
        isLoading: isMediaActivitiesReportLoading,
        isRefetching: isMediaActivitiesReportRefetching,
        isSuccess: isMediaActivitiesReportSuccess,
        refetch: refetchMediaActivitiesReport,
      };
    }
    case ReportType.AssetAbsFaultHistory: {
      return {
        data: assetABSFaultCodesReportHistory,
        isLoading: isAssetAbsFaultCodesReportLoading,
        isRefetching: isAssetAbsFaultCodesReportRefetching,
        isSuccess: isAssetAbsFaultCodesReportSuccess,
        refetch: refetchAssetAbsFaultCodesReport,
      };
    }
    case ReportType.AssetDetention: {
      return {
        data: assetDetentionReportDataHistory,
        isLoading: isAssetDetentionReportLoading,
        isRefetching: isAssetDetentionReportRefetching,
        isSuccess: isAssetDetentionReportReportSuccess,
        refetch: refetchAssetDetentionReportReport,
      };
    }

    default: {
      return {
        data: { data: [], pagination: defaultPaginationResponse },
        isLoading: false,
        isRefetching: false,
        isSuccess: false,
        refetch: () => Promise.resolve({} as QueryObserverResult),
      };
    }
  }
};
