import { FC } from "react";
import SharedIcon from "@mui/icons-material/Share";
import { Divider } from "@mui/material";
import { useAppContext } from "../../../../context/AppContext";
import { Asset, AssetOs } from "../../../../graphql/operations";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { AssetShortTooltipSensors } from "../../MapView/Assets/AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors";
import { LastEventChip } from "../../MapView/Assets/AssetShortTooltip/components/LastEventChip/LastEventChip";
import {
  formatSensorEventName,
  getTooltipStatus,
} from "../../MapView/Assets/AssetShortTooltip/helpers";
import { useGetEnabledSensors } from "../../MapView/Assets/AssetShortTooltip/hooks/useGetEnabledSensors";
import AssetIcon from "../../TableView/components/AssetIcon/AssetIcon";

export interface AssetGalleryCardProps {
  asset: Asset;
  isFetchingAssetById?: boolean;
  handleAssetClick: (asset: Asset) => void;
}
export const AssetGalleryCard: FC<AssetGalleryCardProps> = ({
  asset,
  isFetchingAssetById,
  handleAssetClick,
}) => {
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const { enabledSensors } = useGetEnabledSensors(asset);

  const statusString = getTooltipStatus(
    asset?.tripStatus?.tripState ?? undefined,
    asset?.dwelling?.dwellingDays,
    asset?.sensors?.chassis?.data?.cargoState ?? undefined,
    asset?.tripStatus?.signal ?? undefined
  );
  const handleMiddleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.button === 1) {
      event.preventDefault();
      window.open(`${NavigationRoutes.Assets}/${asset._id}`, "_blank");
    }
  };
  const handleCardClick = () => {
    handleAssetClick(asset);
  };

  return (
    <button
      className="grid w-[100%] text-start gap-2 text-typography-tooltip p-4 rounded-lg cursor-pointer grow"
      onClick={handleCardClick}
      onMouseDown={handleMiddleClick}
      data-testid={`asset-gallery-card-${asset._id}`}
      style={{ backgroundColor: "var(--gallery-cards-background)" }}
    >
      <div className="flex justify-between items-start">
        <div className="flex items-center mr-4" data-testid="asset-icon">
          <AssetIcon
            dwellingDays={Number(asset.dwelling?.dwellingDays)}
            signal={Boolean(asset.tripStatus?.signal)}
            cargo_state={String(asset?.sensors?.chassis?.data?.cargoState)}
            trip_st={String(asset?.tripStatus?.tripState)}
            svgProps={{ height: 48, width: 48 }}
          />
        </div>
        <div className="flex flex-grow flex-col">
          <div className="flex flex-row items-center justify-between gap-2 text-2xs pb-2">
            <div className="flex flex-grow cursor-pointer">
              <p
                title={`${asset.category} ${asset.name?.toUpperCase()}`}
                data-testid="asset-name-category"
                className="font-bold text-base text-sub-header-text leading-none capitalize relative text-ellipsis overflow-hidden whitespace-nowrap w-[0] flex-1"
              >
                {asset.category && <span>{asset.category}&nbsp;</span>}#
                {asset.name?.toUpperCase()}
              </p>
            </div>

            <div className="h-4">
              <LastEventChip lastEvent={new Date(asset.lst_evnt_t)} />
            </div>
          </div>

          {asset?.shared?.status === "Shared" && (
            <div
              className="flex font-bold text-sub-header-text text-sm"
              data-testid="shared-info"
            >
              <SharedIcon
                sx={{
                  width: 15,
                  height: 15,
                  marginRight: "3px",
                  marginTop: "3px",
                }}
                data-testid="shared-icon"
              />
              {(asset as AssetOs).shared?.shareType}{" "}
              {(asset as AssetOs).shared?.shareOrganizationName}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="font-medium text-[10px] pb-2">{`${statusString}`}</div>
        <div className="font-medium text-[10px] pb-2">{`${asset.fullAddress?.city}, ${asset.fullAddress?.state}`}</div>
        <div className="font-medium text-[10px]">
          {formatSensorEventName(asset.event_name ?? "")}
        </div>
      </div>
      <Divider className="opacity-50 !my-2" />
      <AssetShortTooltipSensors
        sensors={enabledSensors}
        isFetchingAssetById={isFetchingAssetById}
      />
    </button>
  );
};
