import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useWatch } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { Box, Chip, TextField, ThemeProvider, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { isEmpty } from "lodash";
import { useAppContext } from "../../../../context/AppContext";
import { ReportType } from "../../../../graphql/operations";
import { ActionDialog } from "../../../../shared/components/ActionDialog/ActionDialog";
import { RequiredFlag } from "../../../../shared/components/RequiredFlag";
import {
  DatePickerElement,
  HierarchySelectElement,
} from "../../../../shared/components/react-hook-form-mui";
import { useFormTheme } from "../../../../shared/hooks/theme/useFormTheme";
import { useAssetTypes } from "../../../../shared/hooks/useAssetTypes";
import { useAvailableOrgs } from "../../../../shared/hooks/useAvailableOrgs";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import {
  buildWildcardStructure,
  formatDateToTimezoneAsUTCISOString,
  getBeginningOfDayISOString,
  getEndingOfDayISOString,
  mapTimezoneToIANAValue,
  validateYear,
} from "../../../../utils";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import { useRunReportForm } from "../../../ReportView/AssetReport/components/RunReportForm";
import { getIsDateDisabled } from "../../../ReportView/components/ReportTemplate/utils";
import { getOrgsHierarchy } from "../../../ReportView/helpers/getOrgsHierarchy";
import { ReportParameters } from "../../../ReportView/interfaces";

interface OptionType {
  label: string;
  id: string;
}

interface ICreateDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (reportType: ReportType, params: ReportParameters) => void;
  setMaxExceededModalOpened: (value: boolean) => void;
}

const reportsWithoutDateRange = [
  ReportType.AssetInventory,
  ReportType.DwellHierarchy,
  ReportType.Mileage,
  ReportType.AssetYard,
  ReportType.OutsideGeofence,
  ReportType.Accounts,
  ReportType.Sensors,
  ReportType.AssetDetention,
];

export const CreateReportDialog: FC<ICreateDialogProps> = ({
  open,
  onClose,
  onSubmit,
  setMaxExceededModalOpened,
}) => {
  const formTheme = useFormTheme();
  const name = "create-report";
  const availableFeatures = useMemo(
    () => [
      "Asset Detention",
      "Asset Transfer",
      "Asset Balance",
      "Asset Install",
      "Asset Inventory",
      "Alert History",
      "Mileage",
      "Asset Yard",
      "Outside of Geofence",
      "Accounts",
      "Dwell Hierarchy",
      "Media Activities",
      "Sensors",
      "Asset ABS Fault History",
    ],
    []
  );
  const title = "Generate Report";
  const textContent = "Please select type of the report";
  const selectLabel = "Report Type";
  const submitButtonText = "Generate Report";

  const {
    state: { appConfig },
  } = useAppContext();
  const { maxExceededOrgsCount } = appConfig.reports;
  const reportTypes = useNomenclatures(NOMENCLATURE_NAMES.reportType);
  const orgData = useCurrentOrg();
  const availableOrgs = useAvailableOrgs();
  const { form } = useRunReportForm(
    { startDate: new Date(), endDate: new Date() },
    true
  );
  const watchStartDate = useWatch({ name: "startDate", control: form.control });
  const watchEndDate = useWatch({ name: "endDate", control: form.control });

  const [reportType, setReportType] = useState<ReportType | undefined>();
  const [displayReportTypeErrorMessage, setDisplayReportTypeErrorMessage] =
    useState(false);

  const { assetTypeOptionsState: assetTypeOptions } = useAssetTypes();
  const reportTypeOptions = useMemo(
    () =>
      reportTypes
        .filter((x) => x.value !== "DWELL") // Remove the 'DWELL' item
        .map((x) => ({ id: x.value, label: x.label }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [reportTypes]
  );
  const timezone = useMemo(
    () => mapTimezoneToIANAValue(orgData?.time_zones),
    [orgData]
  );
  const availableOrgsHierarchy = useMemo(
    () => getOrgsHierarchy(availableOrgs),
    [availableOrgs]
  );

  const isDateDisabled = getIsDateDisabled(reportType as ReportType);
  const isSubmitDisabled =
    (!reportType && isEmpty(form.formState.dirtyFields)) ||
    (reportType === ReportType.AssetBalance &&
      (!form.getValues().assetTypes || !form.getValues().assetTypes.length));

  const handleClose = useCallback(() => {
    setReportType(undefined);
    setDisplayReportTypeErrorMessage(false);
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      setDisplayReportTypeErrorMessage(!reportType);
      return;
    }

    if (reportType) {
      if (!form.getValues().orgIds?.length) {
        form.setValue(
          "orgIds",
          availableOrgs.map((org) => org._id)
        );
      }
      const params = form.getValues();

      if (!params) {
        return;
      }

      const startDate = params.startDate
        ? formatDateToTimezoneAsUTCISOString(
            getBeginningOfDayISOString(params.startDate),
            timezone
          )
        : undefined;
      const endDate = params.endDate
        ? formatDateToTimezoneAsUTCISOString(
            getEndingOfDayISOString(params.endDate),
            timezone
          )
        : undefined;

      // Check if generated organizations wildcard structure is greater than maxExceededOrgsCount
      if (
        params.orgIds &&
        buildWildcardStructure(params.orgIds, availableOrgsHierarchy).length >
          maxExceededOrgsCount
      ) {
        setMaxExceededModalOpened(true);
        return;
      }

      onSubmit(reportType, {
        orgIds: params.orgIds,
        startDate,
        endDate,
        assetTypes: params.assetTypes,
      });
      handleClose();
    } else {
      setDisplayReportTypeErrorMessage(true);
    }
  }, [
    maxExceededOrgsCount,
    onSubmit,
    reportType,
    handleClose,
    form,
    availableOrgs,
    timezone,
    availableOrgsHierarchy,
    setMaxExceededModalOpened,
  ]);

  const isOptionEqualToValue = useCallback(
    (option: OptionType, value: OptionType) => {
      return option.id === value.id;
    },
    []
  );

  const handleSelectReportType = useCallback(
    (_event: SyntheticEvent, option: OptionType) => {
      setReportType(option.id as ReportType);

      if (reportsWithoutDateRange.includes(option.id as ReportType)) {
        form.setValue("startDate", undefined);
        form.setValue("endDate", undefined);
      }

      setDisplayReportTypeErrorMessage(false);
    },
    [setReportType, form, setDisplayReportTypeErrorMessage]
  );

  const isOptionDisabled = useCallback(
    (option: OptionType) => !availableFeatures.includes(option.label),
    [availableFeatures]
  );

  return (
    <ActionDialog
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      name={name}
      title={title}
      textContent={textContent}
      submitButtonText={submitButtonText}
      isSubmitDisabled={isSubmitDisabled}
    >
      <ThemeProvider theme={formTheme}>
        <form autoComplete="off" data-testid="create-report-dialog-form">
          <Box sx={{ marginBottom: "16px" }}>
            <Typography
              data-testid={`${name}-type-autocomplete-label`}
              className={`!mb-1 !text-sm !font-bold ${
                displayReportTypeErrorMessage ? "text-error" : ""
              }`}
            >
              {selectLabel}
              <RequiredFlag />
            </Typography>
            <Autocomplete
              options={reportTypeOptions}
              getOptionDisabled={isOptionDisabled}
              isOptionEqualToValue={isOptionEqualToValue}
              data-testid={`${name}-type-autocomplete`}
              onChange={handleSelectReportType}
              disableClearable
              renderInput={(params) => (
                <TextField
                  error={displayReportTypeErrorMessage}
                  helperText={
                    displayReportTypeErrorMessage
                      ? "Please make a selection before continuing"
                      : ""
                  }
                  {...params}
                  data-testid={`${name}-type-autocomplete-field`}
                  variant="standard"
                />
              )}
            />
          </Box>
          {reportType === ReportType.AssetBalance && (
            <Box
              sx={{ marginBottom: "16px" }}
              data-testid={`${name}-asset-types-autocomplete`}
            >
              <AutocompleteElement
                label="Asset Type"
                name="assetTypes"
                required
                matchId
                multiple
                control={form.control}
                options={assetTypeOptions}
                autocompleteProps={{
                  renderTags: (values, getTagProps) =>
                    values.map((option, index) => (
                      <Chip label={option?.label} {...getTagProps({ index })} />
                    )),
                  isOptionEqualToValue: (option, value) =>
                    option.id === value.id,
                }}
              />
            </Box>
          )}
          <Box sx={{ marginBottom: "16px" }}>
            <HierarchySelectElement
              hierarchy={availableOrgsHierarchy}
              loading={!availableOrgsHierarchy.length}
              label="Organization"
              control={form.control}
              name="orgIds"
              form={form}
            />
          </Box>
          <Box sx={{ marginBottom: "16px" }}>
            <DatePickerElement
              label="Start Date"
              title="Start Date"
              control={form.control}
              name="startDate"
              placeholder="MM/DD/YYYY"
              disabled={isDateDisabled}
              maxDate={watchEndDate}
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [24, 2] } }],
              }}
              fullWidth
              disableHighlightToday
              validate={validateYear}
            />
          </Box>
          <Box sx={{ marginBottom: "16px" }}>
            <DatePickerElement
              label="End Date"
              title="End Date"
              control={form.control}
              name="endDate"
              placeholder="MM/DD/YYYY"
              disabled={isDateDisabled}
              minDate={watchStartDate}
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [24, 2] } }],
              }}
              fullWidth
              disableHighlightToday
              validate={validateYear}
            />
          </Box>
        </form>
      </ThemeProvider>
    </ActionDialog>
  );
};
