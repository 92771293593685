import { FC, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import isFinite from "lodash/isNumber";
import {
  AssetOs,
  useSearchRegulatorDetailsEventHistoryQuery,
} from "../../../../../../../../graphql/operations";
import { useApplicationTheme } from "../../../../../../../../shared/hooks/theme/useApplicationTheme";
import { useGetPressureUnitPreference } from "../../../../../../../../shared/hooks/useGetPressureUnitPreference";
import { getConvertedPressureValueWithUnit } from "../../../../../../../../utils/convertPressure";
import {
  getStartOfDay,
  getStartOfToday,
  getSubDays,
} from "../../../../../../../../utils/date";
import DarkThemeRegulatorIcon from "./DarkThemeRegulatorIcon";
import RegulatorIcon from "./RegulatorIcon";

export interface RegulatorStatusCardProps {
  asset: AssetOs;
}

const RegulatorAveragePressureCard: FC<RegulatorStatusCardProps> = ({
  asset,
}) => {
  const { applicationTheme } = useApplicationTheme();
  const [isDarkTheme, setIsDarkTheme] = useState(applicationTheme === "dark");

  useEffect(() => {
    setIsDarkTheme(applicationTheme === "dark");
  }, [applicationTheme]);

  const pressureUnit = useGetPressureUnitPreference();

  const { data, isLoading } = useSearchRegulatorDetailsEventHistoryQuery(
    {
      input: {
        imei: asset?.imei,
        // NOTE: Currently the dates below are not used by the BE, they were added to pass the validation in the lambda
        startDate: getStartOfDay(getSubDays(new Date(), 30)), // from 30 days back
        endDate: getStartOfToday(),
      },
    },
    { enabled: Boolean(asset?.imei) }
  );

  // Moving
  const regulatorAverageMovingPressure =
    data?.searchEventHistory?.regulatorSensorDetails?.moving?.averagePressure;

  // Parked
  const regulatorAverageParkedPressure =
    data?.searchEventHistory?.regulatorSensorDetails?.parked?.averagePressure;

  const arePressureValuesValid =
    isFinite(regulatorAverageMovingPressure) &&
    isFinite(regulatorAverageParkedPressure);

  return (
    <>
      {arePressureValuesValid ? (
        <Card
          className={`background-white border min-w-[280px]`}
          sx={{
            boxShadow: "none",
            borderRadius: "8px",
            borderColor: "var(--asset-card-border)",
            flexGrow: 1,
          }}
          data-testid="asset-dashboard--tires-tab--card-regulator-average-status"
        >
          {isLoading ? (
            <CardContent className="h-full">
              <Box
                className="flex h-full w-full items-center justify-center"
                data-testid="tires-regulator-average-pressure-loading-screen"
              >
                <CircularProgress />
              </Box>
            </CardContent>
          ) : (
            <CardContent>
              <Box className="flex w-full justify-start mb-5 ">
                <Box data-testid="regulator-average-status-icon">
                  {isDarkTheme ? (
                    <span data-testid="dark-theme-regulator-status-icon">
                      <DarkThemeRegulatorIcon />
                    </span>
                  ) : (
                    <RegulatorIcon />
                  )}
                </Box>

                <Typography
                  className="text-brand !text-lg	!ml-3"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Regulator Sensor Details
                </Typography>
              </Box>
              <Typography
                className="text-card__typography"
                sx={{
                  marginBottom: "10px",
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: 400,
                }}
              >
                30 Day Average Pressure:
              </Typography>
              <Typography
                className="text-base text-card__typography"
                sx={{
                  marginBottom: "10px",
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: 400,
                }}
              >
                <span data-testid="card-regulator-status--trip-status">
                  Moving:
                </span>
                <span
                  className="ml-1 font-bold"
                  data-testid="card-regulator-status-moving-pressure"
                >
                  {`${getConvertedPressureValueWithUnit(
                    regulatorAverageMovingPressure,
                    pressureUnit
                  )}`}
                </span>
              </Typography>
              <Typography
                className="text-base text-card__typography"
                sx={{
                  marginBottom: "10px",
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: 400,
                }}
              >
                <span data-testid="card-regulator-status--trip-status">
                  Parked:
                </span>
                <span
                  className="ml-1 font-bold"
                  data-testid="card-regulator-status-parked-pressure"
                >
                  {`${getConvertedPressureValueWithUnit(
                    regulatorAverageParkedPressure,
                    pressureUnit
                  )}`}
                </span>
              </Typography>
            </CardContent>
          )}
        </Card>
      ) : null}
    </>
  );
};

export default RegulatorAveragePressureCard;
