import { useMemo } from "react";
import { useGetNomenclaturesQuery } from "../../../../graphql/operations";
import { parseNomenclatures } from "../helpers";

export enum NOMENCLATURE_NAMES {
  assetDoorType = "assetDoorType",
  assetType = "assetType",
  assetWheels = "assetWheels",
  MFAMethod = "MFAMethod",
  sensorStatus = "sensorStatus",
  timeZones = "timeZones",
  tripStatus = "tripStatus",
  dwellStatus = "dwellStatus",
  defaultSensorState = "defaultSensorState",
  dwellSensorState = "dwellSensorState",
  assetSensorTypes = "assetSensorTypes",
  sensorTypes = "sensorTypes",
  assetNumberOfAxles = "assetNumberOfAxles",
  defaultConfigurationColors = "defaultConfigurationColors",
  partsManufacturer = "partsManufacturer",
  rolesAllowedForClientAdmin = "rolesAllowedForClientAdmin",
  rolesAllowedForSuperAdmin = "rolesAllowedForSuperAdmin",
  organizationType = "organizationType",
  alertType = "alertType",
  reportType = "reportType",
  distanceUnits = "distanceUnits",
  atisManufacturer = "atisManufacturer",
  aeroKitType = "aeroKitType",
  doorLatchType = "doorLatchType",
  gladhandType = "gladhandType",
  noseboxMountType = "noseboxMountType",
  landingGearManufacturer = "landingGearManufacturer",
  noseBodyType = "noseBodyType",
  suspensionType = "suspensionType",
  tireSize = "tireSize",
  tireRimType = "tireRimType",
  minValuesBySensorType = "minValuesBySensorType",
  maxValuesBySensorType = "maxValuesBySensorType",
  faultCodeType = "faultCodeType",
  pressureUnits = "pressureUnits",
  temperatureUnits = "temperatureUnits",
  territoriesUSA = "territoriesUSA",
  territoriesCanada = "territoriesCanada",
  territoriesMexico = "territoriesMexico",
  assetSubType = "assetSubType",
}

export const useNomenclatures = (name: NOMENCLATURE_NAMES) => {
  const getNomenclaturesQuery = useGetNomenclaturesQuery(
    {
      name: "*",
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  const nomenclatures = useMemo(
    () => getNomenclaturesQuery?.data?.getNomenclatures?.data ?? [],
    [getNomenclaturesQuery?.data?.getNomenclatures?.data]
  );

  const result = parseNomenclatures({
    nomenclatures,
    name: name,
  });

  return result;
};
