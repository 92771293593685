import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AssetOs } from "../../../../../../../../graphql/operations";
import useBreakpoint from "../../../../../../../../shared/hooks/useBreakpoint";
import { useGetTemperatureUnitPreference } from "../../../../../../../../shared/hooks/useGetTemperatureUnitPreference";
import { usePreferredTimezone } from "../../../../../../../../shared/hooks/usePreferredTimezone";
import {
  getConvertedTemperatureValue,
  getTemperatureUnitLabel,
} from "../../../../../../../../utils/convertTemperature";
import {
  DATE_TIME_FORMAT_SHORT,
  formatDateInTimezone,
  mapTimezoneToIANAValue,
} from "../../../../../../../../utils/date";
import { TIRE_CARD_PADDING } from "../../../constants";
import {
  mapPsiWheelEndSummaryToTableData,
  parsePsiWheelEndSummary,
} from "../../../helpers";
import { useTiresTabLayout } from "../../../hooks/useTiresTabLayout";
import { PsiWheelEndSummaryData } from "../../../interfaces";

export type PsiWheelEndSummaryTableData = Pick<
  PsiWheelEndSummaryData,
  "temperature" | "status" | "lastReported"
> & {
  name: string;
};

export interface TiresSummaryTableProps {
  asset: AssetOs;
}

// Note: This is a potential candidate for a shared component
const TiresSummaryTable: React.FC<TiresSummaryTableProps> = ({ asset }) => {
  const [rows, setRows] = useState<PsiWheelEndSummaryTableData[]>([]);
  const { psiWheelEndTableHeight: tableHeight } = useTiresTabLayout(asset);
  const isDesktop = useBreakpoint("up", "xl");

  const timezone = usePreferredTimezone();
  const tempUnitPreference = useGetTemperatureUnitPreference();

  useEffect(() => {
    if (
      asset?.sensors?.psiWheelEnd?.data?.psiWheelEndMeasure &&
      rows.length === 0
    ) {
      const sensorsSummary = parsePsiWheelEndSummary(asset);
      const tableData = mapPsiWheelEndSummaryToTableData(sensorsSummary);

      setRows(tableData);
    }
  }, [asset, rows]);

  return (
    <Box
      className="bg-card__bg border rounded-lg"
      sx={{
        borderColor: "var(--asset-card-border)",
        padding: isDesktop ? "16px" : "0px",
      }}
      style={tableHeight > 0 ? { height: tableHeight } : {}}
      data-testid="asset-dashboard--tires-tab--psi-wheel-end--tires-table-summary-section"
    >
      <TableContainer
        component={Paper}
        className="border"
        sx={{
          boxShadow: "none",
          borderRadius: "8px",
          borderColor: isDesktop ? "var(--asset-table-border)" : "transparent",
        }}
        // The height is fixed to match the axles cards, but exclude the padding defined in the parent container
        style={
          tableHeight > 0
            ? { maxHeight: tableHeight - 2 * TIRE_CARD_PADDING }
            : {}
        }
      >
        <Table stickyHeader aria-label="Tire Sensors Table">
          <TableHead data-testid="asset-dashboard--tires-tab--psi-wheel-end--tires-table-summary-section--header">
            <TableRow>
              <TableCell sx={{ fontWeight: "700" }}>Wheel End</TableCell>
              <TableCell
                sx={{ fontWeight: "700" }}
              >{`Temp (${getTemperatureUnitLabel(
                tempUnitPreference,
                true
              )})`}</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="asset-dashboard--tires-tab--psi-wheel-end--tires-table-summary-section--body">
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                data-testid="asset-dashboard--tires-tab--psi-wheel-end--tires-table-summary-section--row"
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>
                  {row?.temperature !== null
                    ? getConvertedTemperatureValue(
                        Number(row.temperature),
                        tempUnitPreference
                      )
                    : ""}
                </TableCell>
                <TableCell sx={{ textTransform: "capitalize" }}>
                  {row.status}
                </TableCell>
                <TableCell>
                  {row.lastReported &&
                    formatDateInTimezone(
                      row.lastReported,
                      DATE_TIME_FORMAT_SHORT,
                      mapTimezoneToIANAValue(timezone)
                    )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TiresSummaryTable;
