import { useEffect, useState } from "react";
import { JsonLogicResult } from "@react-awesome-query-builder/core";
import { useAppContext } from "../../../../context/AppContext";
import { MongoDBType } from "../../../../shared/components/QueryBuilder/QueryBuilder";
import { useAssetsDataContext } from "../AssetsDataContext/AssetsDataContext";

interface AssetsComplexFiltersHook {
  complexFiltersQuery: JsonLogicResult | MongoDBType;
}

export const useAssetsComplexFilters = (): AssetsComplexFiltersHook => {
  const [complexFiltersQuery, setComplexFiltersQuery] = useState<
    JsonLogicResult | MongoDBType
  >();

  const {
    currentFilter: { complexFilters },
  } = useAssetsDataContext();
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  useEffect(() => {
    const newFilters = { and: [complexFilters?.logic].filter(Boolean) };

    if (JSON.stringify(newFilters) !== JSON.stringify(complexFiltersQuery)) {
      setComplexFiltersQuery(newFilters);
    }
  }, [selectedOrganization, complexFilters, complexFiltersQuery]);

  return {
    complexFiltersQuery,
  };
};
