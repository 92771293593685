import { useEffect, useState } from "react";
import { AssetOs, AxleSensor } from "../../../../../../graphql/operations";
import {
  TIRE_CARD_HEIGHT,
  TIRE_CARD_PADDING,
  TPMS_TAB_DESKTOP_BREAKPOINT,
  WHEEL_END_CARD_HEIGHT,
} from "../constants";

interface TiresTabLayoutHook {
  axles: number;
  tires: number;
  tpmsTableHeight: number;
  psiWheelEndTableHeight: number;
  isDesktop: boolean;
}

export function useTiresTabLayout(asset: AssetOs | null): TiresTabLayoutHook {
  const [axles, setAxles] = useState<number>(0);
  const [tires, setTires] = useState<number>(0);

  const [tpmsTableHeight, setTpmsTableHeight] = useState<number>(0);
  const [psiWheelEndTableHeight, setPsiWheelEndTableHeight] =
    useState<number>(0);

  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > TPMS_TAB_DESKTOP_BREAKPOINT
  );

  useEffect(() => {
    if (asset?.sensors?.tpmsBeta?.data?.sensors?.length) {
      const sensors = asset?.sensors?.tpmsBeta?.data.sensors;

      determineAxlesCount(sensors as AxleSensor[]);
      determineTiresCount(sensors as AxleSensor[]);
    }

    if (asset?.sensors?.psiWheelEnd?.data?.axleElements) {
      const sensors = asset?.sensors?.psiWheelEnd?.data?.axleElements ?? 0;

      setAxles(sensors);
    }
  }, [asset]);

  // Determine the amount of axles based on the asset's sensors data
  const determineAxlesCount = (sensors: AxleSensor[]): number => {
    const axlesCount = sensors.filter(Boolean).length;

    setAxles(axlesCount);

    return axlesCount;
  };

  // Determine the amount of tires based on the asset's sensors data (whether we have inner tires or not)
  const determineTiresCount = (sensors: AxleSensor[]): number => {
    const tiresCount = sensors[0]?.inner_roadside_status ? 2 : 1;

    setTires(tiresCount);

    return tiresCount;
  };

  // Determine the columns sizes for the axles and tires sections
  useEffect(() => {
    if (asset?.sensors?.tpmsBeta?.data?.sensors?.length) {
      // Also, determine the table height, based oin the amount of axles
      setTpmsTableHeight(
        axles * TIRE_CARD_HEIGHT + (axles - 1) * TIRE_CARD_PADDING
      );
    }

    if (asset?.sensors?.psiWheelEnd?.data?.psiWheelEndMeasure) {
      // Determine the table height, based oin the amount of axles
      setPsiWheelEndTableHeight(
        axles * WHEEL_END_CARD_HEIGHT + (axles - 1) * TIRE_CARD_PADDING
      );
    }
  }, [asset, tires, axles, isDesktop]);

  // Determine the size of screen (mobile or desktop)
  const handleResize = () => {
    setIsDesktop(window.innerWidth > TPMS_TAB_DESKTOP_BREAKPOINT);
  };

  // Determine the size of screen on resize
  useEffect(() => {
    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    axles,
    tires,
    tpmsTableHeight,
    psiWheelEndTableHeight,
    isDesktop,
  };
}
