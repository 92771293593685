import { useMemo } from "react";
import {
  TableFilterLinkOperator,
  useGetAssetsForListOsQuery,
  AssetOs,
  TableFilterOperator,
  TableValueDataType,
} from "../../graphql/operations";
import { assetsListInputParams } from "../../views/AssetsView/helpers/helpers";

export const useFindAssetListOptions = (search: string, limit: number) => {
  const { data: assetDataList, isFetching } = useGetAssetsForListOsQuery(
    {
      input: {
        ...assetsListInputParams,
        skipSharedAssets: true,
        filterList: {
          filters: [
            {
              field: "asset_id",
              dataType: TableValueDataType.String,
              operator: TableFilterOperator.StartsWith,
              value: JSON.stringify({ value: search }),
            },
          ],
          linkOperator: TableFilterLinkOperator.And,
        },
        skip: 0,
        limit,
      },
    },
    { enabled: search.length > 2 }
  );

  const searchResult = useMemo<Array<{ id: string; label: string }>>(
    () =>
      assetDataList?.getAssetsOS?.assets
        ?.filter((item): item is AssetOs => !!item && !!item.asset_id)
        .map(({ _id, asset_id }) => ({ id: _id, label: asset_id! })) ?? [],
    [assetDataList]
  );

  return {
    isFetching,
    options: searchResult,
  };
};
