import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import {
  AssetInput,
  AssetInputOs,
  AssetOs,
  GetAssetsForListOsQuery,
  useGetAssetsForListOsQuery,
} from "../../../graphql/operations";

export interface AssetsListDataHook {
  isFetching: boolean;
  assets: AssetOs[];
  total: number;
}

type GetAssetsForListOSQueryOptions = UseQueryOptions<
  GetAssetsForListOsQuery,
  unknown,
  GetAssetsForListOsQuery,
  QueryKey
>;

export const useAssetsListData = (
  queryInput: AssetInput | AssetInputOs,
  queryOptions: GetAssetsForListOSQueryOptions
): AssetsListDataHook => {
  const { data, isFetching } = useGetAssetsForListOsQuery(
    {
      input: queryInput as AssetInputOs,
    },
    {
      ...queryOptions,
      enabled: queryOptions.enabled,
    } as GetAssetsForListOSQueryOptions
  );

  return {
    isFetching,
    assets: (data?.getAssetsOS?.assets as AssetOs[]) ?? [],
    total: data?.getAssetsOS?.total ?? 0,
  };
};
