import React from "react";
import {
  Control,
  Controller,
  ControllerProps,
  FieldValues,
  UseFormReturn,
} from "react-hook-form";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Select,
  Typography,
} from "@mui/material";
import { EscalationTimeSelect } from "../../EscalationTimeSelect";
import { RequiredFlag } from "../../RequiredFlag";

export type EscalationSelectElementProps = {
  label?: string;
  rules?: ControllerProps["rules"];
  name: string;
  control: Control<any>;
  labelClassName?: string;
  required?: boolean;
  form: UseFormReturn<FieldValues>;
  compact?: boolean;
  disabled?: boolean;
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
  isEscalation?: boolean;
  loading?: boolean;
};

export const EscalationSelectElement = ({
  label,
  rules,
  name,
  control,
  labelClassName = "!mb-2 !text-sm !font-bold",
  required,
  form,
  compact = false,
  disabled = false,
  handleUpdateField,
  fieldPath,
  isEscalation,
  ...rest
}: EscalationSelectElementProps) => {
  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error, invalid },
      }) => (
        <FormControl
          fullWidth
          required={required}
          variant="standard"
          error={invalid}
        >
          {label && (
            <Typography
              className={`${labelClassName} ${
                disabled ? "text-light-charcoal" : ""
              }`}
              data-testid="escalation-select-element-label"
            >
              {label}
              {rules?.required && <RequiredFlag />}
            </Typography>
          )}
          {!rest.loading ? (
            <EscalationTimeSelect
              {...rest}
              values={value}
              onBlur={onBlur}
              invalid={invalid}
              required={required}
              disabled={disabled}
              form={form}
            />
          ) : (
            <Box>
              <Box
                className="absolute bottom-0 right-6"
                data-testid="escalation-select-loader"
              >
                {rest.loading && <CircularProgress size={20} />}
              </Box>
              <Select fullWidth disabled value={""} />
            </Box>
          )}

          {error?.message && (
            <FormHelperText error={true}>{error?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
