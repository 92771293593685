import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { SELECTED_ORGANIZATION } from "../../../../constants";
import { useAppContext } from "../../../../context/AppContext";
import {
  SelectedOrganization,
  setSelectedOrganization,
} from "../../../../context/reducers/selectedOrganization";
import { useAssetsDataContext } from "../../../../views/AssetsView/shared/AssetsDataContext";

/**
 * Custom hook to handle organization changes.
 * Updates the selected organization in redux state, clears related filters, and sets a cookie.
 *
 * @returns {Object} An object containing the `handleOrganizationChange` function.
 */
export const useHandleOrganizationChange = () => {
  const { dispatch } = useAppContext();
  const [, setCookie] = useCookies([SELECTED_ORGANIZATION]);
  const { onChangeFilters } = useAssetsDataContext();

  const handleOrganizationChange = useCallback(
    (org: SelectedOrganization) => {
      // Set organization in reducer
      setSelectedOrganization(dispatch, org);
      // Clear organization related asset filters
      onChangeFilters({
        assetTags: [],
        geofenceNames: [],
        geofenceCategories: [],
        productNames: [],
      });
      setCookie(SELECTED_ORGANIZATION, org, {
        path: "/",
      });
    },
    [dispatch, onChangeFilters, setCookie]
  );

  return { handleOrganizationChange };
};
