import { FC, useState, useEffect } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import classNames from "classnames";
import {
  AssetOs,
  SortOrder,
  useSearchAggregatedEventHistoryCargoCameraQuery,
} from "../../../../../../graphql/operations";
import useBreakpoint from "../../../../../../shared/hooks/useBreakpoint";
import useChartExpand from "../../../../../../shared/hooks/useChartExpand";
import { convertToIsoStringIgnoringTimezoneOffset } from "../../../../../../utils/date";
import { AggregatedEventHistoryReportTypes } from "../../SummaryTabPanel/Charts/chartUtils";
import Body from "./components/Body";
import Header from "./components/Header";
import { useInternalCameraSpaceGraphContext } from "./context";
import { CargoCameraSpaceData, InternalCameraGraphType } from "./types";

export interface InternalCameraSpaceGraphProps {
  graphType: InternalCameraGraphType;
  selectedAsset: AssetOs | null;
  allowResize?: boolean;
}

const InternalCameraSpaceGraph: FC<InternalCameraSpaceGraphProps> = ({
  graphType,
  selectedAsset,
  allowResize = true,
}) => {
  const { startDate, endDate } = useInternalCameraSpaceGraphContext();

  const isDesktop = useBreakpoint("up", "lg");
  const { gridWidth, gridWidthIndicator, handleToggleGridWidth } =
    useChartExpand();

  const [
    aggregatedCargoCameraFloorSpaceData,
    setAggregatedCargoCameraFloorSpaceData,
  ] = useState<CargoCameraSpaceData[]>([]);

  const { data, isLoading } = useSearchAggregatedEventHistoryCargoCameraQuery(
    {
      input: {
        startDate: convertToIsoStringIgnoringTimezoneOffset(
          startDate ?? new Date()
        ),
        endDate: convertToIsoStringIgnoringTimezoneOffset(
          endDate ?? new Date()
        ),
        assetId: selectedAsset?.asset_id!,
        reportType:
          AggregatedEventHistoryReportTypes.DailyReportCargoFloorSpace,
        includeEmptyDates: true,
        sort: SortOrder.Asc,
      },
    },
    {
      enabled: !!selectedAsset?.asset_id && !!startDate && !!endDate,
    }
  );

  useEffect(() => {
    if (data?.searchAggregatedEventHistoryOS?.data?.length) {
      setAggregatedCargoCameraFloorSpaceData(
        data?.searchAggregatedEventHistoryOS?.data
      );
    }
  }, [data?.searchAggregatedEventHistoryOS?.data]);

  return (
    <Grid
      item
      lg={gridWidth}
      className={isDesktop ? "block" : "hidden"}
      data-testid={`asset--cargo-tab-panel--internal-camera-${graphType}-space-graph`}
    >
      <Box className="h-full w-full flex flex-col p-5 bg-dashboard_subheader__bg">
        <Header
          graphType={graphType}
          allowResize={allowResize}
          gridWidthIndicator={gridWidthIndicator}
          handleToggleGridWidth={handleToggleGridWidth}
        />

        {isLoading && (
          <Box
            className="flex justify-center items-center h-full"
            data-testid={`camera-${graphType}-space-graph-loading`}
          >
            <CircularProgress />
          </Box>
        )}

        {!isLoading && (
          <Body
            graphType={graphType}
            data={aggregatedCargoCameraFloorSpaceData}
          />
        )}

        {/* Sorry about this, but it seems that Tailwind is not generating these classes if they;re used only dynamically. */}
        <div
          className={classNames([
            "hidden",
            "bg-primary-blue-transparent-10",
            "bg-primary-blue-transparent-20",
            "bg-primary-blue-transparent-30",
            "bg-primary-blue-transparent-40",
            "bg-primary-blue-transparent-50",
            "bg-primary-blue-transparent-60",
            "bg-primary-blue-transparent-70",
            "bg-primary-blue-transparent-80",
            "bg-primary-blue-transparent-90",
          ])}
        ></div>
      </Box>
    </Grid>
  );
};

export default InternalCameraSpaceGraph;
