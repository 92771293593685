import { useCallback, useMemo } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { Box, IconButton } from "@mui/material";
import { isEmpty } from "lodash";
import { TextButton } from "../../../../../shared/components/Button";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useHorizontalScroll } from "../../../../../shared/hooks/useHorizontalScroll";
import {
  initialFilterState,
  initialGeofenceFilterState,
  useAssetsDataContext,
} from "../../AssetsDataContext";
import FilterChip from "./FilterChip/FilterChip";
import {
  mapFilterKeyToName,
  removeUntouchedFiltersAndQueryBuilder,
} from "./helpers";

const FiltersStrip = () => {
  const isMobile = useBreakpoint("down", "sm");
  const horizontalScrollRef = useHorizontalScroll();
  const {
    currentFilter,
    clearAllAssetFilters,
    currentGeofenceFilter,
    resetGeofenceFiltersToDefault,
    isGeofencesView,
  } = useAssetsDataContext();

  // Process the currently applied filters
  const filters = useMemo(() => {
    const baseFilters = isGeofencesView
      ? removeUntouchedFiltersAndQueryBuilder(
          currentGeofenceFilter,
          initialGeofenceFilterState
        )
      : {
          ...removeUntouchedFiltersAndQueryBuilder(
            currentFilter,
            initialFilterState
          ),
          ...(currentFilter?.complexFilters?.logic
            ? { filters: JSON.stringify(currentFilter.complexFilters.logic) }
            : {}),
        };

    if (baseFilters && "orgIds" in baseFilters) {
      // Remove orgIds since they're controlled by the global org filter and not
      // part of any of the designs for the filters strip
      delete baseFilters.orgIds;
    }

    return baseFilters;
  }, [currentFilter, currentGeofenceFilter, isGeofencesView]);
  const filtersAreEmpty = useMemo(() => isEmpty(filters), [filters]);
  const clearAllFilters = useCallback(() => {
    if (isGeofencesView) {
      resetGeofenceFiltersToDefault();
    } else {
      clearAllAssetFilters(initialFilterState);
    }
  }, [isGeofencesView, clearAllAssetFilters, resetGeofenceFiltersToDefault]);
  return (
    <Box
      className={`${
        filtersAreEmpty ? "hidden" : "visible"
      } self-start flex flex-1 overflow-hidden flex-row h-full items-center gap-x-2 pr-2`}
    >
      <Box
        ref={horizontalScrollRef}
        data-testid="filters-strip-filters-container"
        className={`${
          filtersAreEmpty ? "hidden" : "visible"
        } flex flex-1 overflow-x-auto flex-row shadow-inner h-full items-center px-4 gap-x-2`}
      >
        {Object.keys(filters ?? {})
          .map((key) => {
            const filterKey = key as keyof typeof filters;
            const filter = filters[filterKey];
            const filterDisplayName = mapFilterKeyToName(filterKey, filter);

            return {
              filter,
              filterKey,
              filterDisplayName,
            };
          })
          .sort(
            (
              { filterDisplayName: filterAName },
              { filterDisplayName: filterBName }
            ) => filterAName.localeCompare(filterBName)
          )
          .map(({ filter, filterKey, filterDisplayName }) => (
            <FilterChip
              key={`filter-strip-chip-${filterKey}`}
              filter={filter as any}
              filterKey={filterKey}
              filterDisplayName={filterDisplayName}
            />
          ))}
      </Box>
      {!filtersAreEmpty &&
        (isMobile ? (
          <IconButton
            title="Clear all filters"
            aria-label="clear all filters"
            onClick={() => {
              clearAllFilters();
            }}
            data-testid="filters-strip-clear-all-mobile"
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <TextButton
            text="Clear all"
            onClick={() => {
              clearAllFilters();
            }}
            size="medium"
            theme="blue"
            data-testid="filters-strip-clear-all-button"
          />
        ))}
    </Box>
  );
};

export default FiltersStrip;
