import { ReactComponent as AirbagSensorDarkIcon } from "../../../../../assets/svgs/airbagSensorDark.svg";
import { ReactComponent as AirbagSensorLightIcon } from "../../../../../assets/svgs/airbagSensorLight.svg";
import { ReactComponent as CameraSensorDarkIcon } from "../../../../../assets/svgs/cameraSensorDark.svg";
import { ReactComponent as CameraSensorLightIcon } from "../../../../../assets/svgs/cameraSensorLight.svg";
import { ReactComponent as CargoSensorDarkIcon } from "../../../../../assets/svgs/cargoSensorDark.svg";
import { ReactComponent as CargoSensorLightIcon } from "../../../../../assets/svgs/cargoSensorLight.svg";
import { ReactComponent as DoorSensorDarkIcon } from "../../../../../assets/svgs/doorSensorDark.svg";
import { ReactComponent as DoorSensorLightIcon } from "../../../../../assets/svgs/doorSensorLight.svg";
import { AssetOs, SensorStatus } from "../../../../../graphql/operations";
import { Sections } from "./DashboardSectionsMenu";

const ICON_STYLES = { width: "33px", height: "33px" };

export const generateSectionsListItems = (
  selectedAsset: AssetOs,
  isLightTheme: boolean,
  currentTabLabel: string
) => {
  return [
    {
      // TODO: Replace all hardcoded asset mocks with factories
      visible:
        Boolean(
          selectedAsset.sensors?.chassis?.data?.cargoState &&
            selectedAsset.sensors.chassis.data.cargoState !==
              SensorStatus.Unknown
        ) && currentTabLabel === "Cargo",
      icon: isLightTheme ? (
        <CargoSensorDarkIcon style={ICON_STYLES} />
      ) : (
        <CargoSensorLightIcon style={ICON_STYLES} />
      ),
      title: "Cargo Sensor",
      section: Sections.Cargo,
    },
    {
      visible:
        Boolean(
          selectedAsset?.sensors?.bleDoorSensor?.data?.doorState ||
            selectedAsset?.sensors?.bleDoorSensor?.readingDate
        ) && currentTabLabel === "Cargo",
      icon: isLightTheme ? (
        <DoorSensorDarkIcon style={ICON_STYLES} />
      ) : (
        <DoorSensorLightIcon style={ICON_STYLES} />
      ),
      title: "Door Sensor",
      section: Sections.Door,
    },
    {
      visible:
        Boolean(selectedAsset?.sensors?.airbag) &&
        currentTabLabel === "Other Sensors",
      icon: isLightTheme ? (
        <AirbagSensorDarkIcon style={ICON_STYLES} />
      ) : (
        <AirbagSensorLightIcon style={ICON_STYLES} />
      ),
      title: "Air Bag Sensor",
      section: Sections.Airbag,
    },
    {
      visible:
        Boolean(selectedAsset?.sensors?.cargoCamera) &&
        currentTabLabel === "Cargo",
      icon: isLightTheme ? (
        <CameraSensorDarkIcon style={ICON_STYLES} />
      ) : (
        <CameraSensorLightIcon style={ICON_STYLES} />
      ),
      title: "Camera Sensor",
      section: Sections.Camera,
    },
  ];
};
