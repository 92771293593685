import { useEffect, useMemo, useState } from "react";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import { IconButton } from "@mui/material";
import { UseMutateFunction } from "@tanstack/react-query";
import {
  Dashboard,
  Exact,
  MeUserData,
  UpdateMyAccountInput,
  UpdateMyAccountMutation,
} from "../../../../../graphql/operations";
import { isAdmin } from "../../../../../shared/components/WithPermissions/userRolePermissions";
import { useUserData } from "../../../../../shared/hooks/useUserData";

export interface DefaultDashboardButtonProps {
  dashboard?: Dashboard;
  mutateUserPreferences: UseMutateFunction<
    UpdateMyAccountMutation,
    Error | null,
    Exact<{
      user: UpdateMyAccountInput;
    }>,
    unknown
  >;
}

const getUserDefaultDashboard = (userData?: MeUserData) =>
  userData?.user_preferences?.default_dashboard ?? null;

const DefaultDashboardButton: React.FC<DefaultDashboardButtonProps> = ({
  dashboard,
  mutateUserPreferences,
}) => {
  const userData = useUserData();
  const isCurrentUserClientOrSuperAdmin = useMemo(
    () => isAdmin(userData?.groups ?? []),
    [userData?.groups]
  );

  const [defaultDashboard, setDefaultDashboard] = useState<number | null>(
    getUserDefaultDashboard(userData)
  );

  useEffect(() => {
    setDefaultDashboard((current: number | null): number | null => {
      return current ?? getUserDefaultDashboard(userData);
    });
  }, [userData, setDefaultDashboard]);

  const toggleDefaultDashboard = () => {
    const newDefaultDashboard =
      defaultDashboard === dashboard?.id ? null : dashboard?.id!;

    setDefaultDashboard(newDefaultDashboard);

    if (userData) {
      mutateUserPreferences({
        user: {
          name: userData.name,
          user_preferences: {
            default_dashboard: newDefaultDashboard,
          },
        },
      });
    }
  };

  if (!userData) {
    return null;
  }

  return (
    <IconButton
      size="large"
      className="!p-1 !mr-1"
      data-testid="default-dashboard-button"
      disabled={!isCurrentUserClientOrSuperAdmin}
      onClick={() => {
        toggleDefaultDashboard();
      }}
    >
      <CheckBoxRoundedIcon
        className={
          defaultDashboard === (dashboard?.id as number)
            ? "!text-brand"
            : "text-primary"
        }
      />
    </IconButton>
  );
};

export default DefaultDashboardButton;
