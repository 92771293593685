import { useEffect, useState } from "react";
import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import {
  AssetInputOs,
  AssetResult,
  GetAssetsForClustersOsQuery,
  useGetAssetsForClustersOsQuery,
} from "../../../graphql/operations";
import { mapClustersToMapFeaturesV2 } from "../../../utils/maps/core";
import { Feature } from "../../../views/AssetsView/shared/AssetsDataContext/AssetsDataContext";

export interface AssetsClustersDataHook {
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isRefetching: boolean;
  data: Feature[];
}

type GetAssetsForClustersOSQueryOptions = UseQueryOptions<
  GetAssetsForClustersOsQuery,
  unknown,
  GetAssetsForClustersOsQuery,
  QueryKey
>;

export const useAssetsClustersData = (
  queryInput: AssetInputOs,
  queryOptions: GetAssetsForClustersOSQueryOptions
): AssetsClustersDataHook => {
  const [data, setData] = useState<Feature[]>([]);

  const {
    data: assetsForClustersData,
    isLoading,
    isFetching,
    isSuccess,
    isRefetching,
  } = useGetAssetsForClustersOsQuery(
    {
      input: queryInput,
    },
    {
      ...queryOptions,
      enabled: queryOptions.enabled,
    }
  );

  useEffect(() => {
    setData(
      mapClustersToMapFeaturesV2(
        (assetsForClustersData?.getAssetsOS as AssetResult) ?? []
      )
    );
  }, [assetsForClustersData]);

  return {
    isLoading,
    isFetching,
    isSuccess,
    isRefetching,
    data,
  };
};
