import { useState } from "react";
import {
  Box,
  Grid,
  Input,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { ReactComponent as AirtankSensorDark } from "../../../../../assets/svgs/airTankSensorDark.svg";
import { ReactComponent as AirtankSensorLight } from "../../../../../assets/svgs/airtankSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  useSetAssetSensorProfilesMutation,
  SetAssetSensorProfilesInput,
  AssetSensorProfilesResponseStatus,
  SetAssetSensorProfilesMutation,
  MergedProfileForAsset,
  SetAssetSensorProfilesResponse,
  SensorThresholdShort,
  AssetOs,
} from "../../../../../graphql/operations";
import { Label } from "../../../../../shared/components/FormControlElement/styledElements";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractRules,
  airTankPressureDefaultValues,
  airTankMinSpeed,
  airTankMaxSpeed,
  prepareRulesPayload,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Spinner from "../../../../../shared/components/Spinner";
import WithAsterisk from "../../../../../shared/components/WithAsterisk";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { SensorValueType } from "../../../../../shared/helpers/sensors.utils";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { useUserPermission } from "../../../../../shared/hooks/useUserPermission";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";
import {
  getConvertedPressureValues,
  getPressureUnitLabel,
  prepareConvertedMbarPressuresPayload,
} from "../../../../../utils/convertPressure";
import { convertMilesToKm } from "../../../../../utils/convertUnits";
import {
  analyzeResponse,
  handleAnalyzedResponse,
} from "../../../../AdminPanel/tabs/Sensors/sensorsUtils";
import SaveCancelButtons from "../SaveCancelButtons/SaveCancelButtons";

interface BrakesProps {
  asset: AssetOs;
  assetMergedSensorProfile: MergedProfileForAsset;
  isAssetRefetching: boolean;
}

export const validationSchema = yup.object().shape({
  0: yup.object().shape({
    minSpeed: yup
      .number()
      .typeError("Must be a number")
      .required("Required")
      .min(1, "Must be at least 1"),
  }),
});

export const BrakesComponent: React.FC<BrakesProps> = ({
  asset,
  assetMergedSensorProfile,
}) => {
  const pressureUnit = useGetPressureUnitPreference();
  const [minAirTankConverted, maxAirTankConverted] = getConvertedPressureValues(
    [MinValuesBySensorType.tankPressure, MaxValuesBySensorType.tankPressure],
    pressureUnit
  );

  const airTankPressure =
    assetMergedSensorProfile?.configuration?.psiAirSupply?.tankPressure;

  const initialPressure = getConvertedPressureValues(
    extractRules(airTankPressure?.match?.thresholds as SensorThresholdShort) ||
      airTankPressureDefaultValues,
    pressureUnit
  );

  const initialMinSpeed = airTankPressure?.minSpeed ?? 0;

  // State
  const [airTank, setAirTank] = useState(initialPressure);
  const [minSpeed, setMinSpeed] = useState<number>(initialMinSpeed);
  const [minSpeedError, setMinSpeedError] = useState<string>("");

  const queryClientRef = useQueryClient();
  const isUserAllowed = useUserPermission("assetManagement.editSettings");

  const {
    dispatch,
    state: { theme },
  } = useAppContext();

  const isAnyValueDirty =
    minSpeed !== initialMinSpeed ||
    airTank.toString() !== initialPressure.toString();

  // GraphQL hooks
  const { mutate, isLoading } = useSetAssetSensorProfilesMutation({
    onSuccess: async (data: SetAssetSensorProfilesMutation) => {
      const response = analyzeResponse(
        data?.setAssetSensorProfiles as SetAssetSensorProfilesResponse[],
        AssetSensorProfilesResponseStatus.Success
      );
      handleAnalyzedResponse(
        response,
        dispatchErrorMessage,
        dispatchSuccessMessage,
        queryClientRef
      );
      queryClientRef.invalidateQueries({
        queryKey: ["getMergedProfileForAsset"],
      });
    },
  });

  // Handlers
  const handleFormSubmit = async () => {
    if (asset && asset.imei && !minSpeedError && isAnyValueDirty) {
      const input: SetAssetSensorProfilesInput = {
        selectedImeis: [asset.imei],
        orgId: asset.customer_orgs_id ?? "",
        sensors: {
          psiAirSupply: {
            tankPressure: {
              sensorType: SensorValueType.Pressure,
              minSpeed: convertMilesToKm(minSpeed),
              ...prepareRulesPayload(
                undefined,
                prepareConvertedMbarPressuresPayload(airTank, pressureUnit),
                MinValuesBySensorType.tankPressure,
                MaxValuesBySensorType.tankPressure,
                1
              ),
            },
          },
        },
      };

      mutate({ input });
    }
  };

  const dispatchSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success",
        text: "Brakes Settings Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Settings Uploading Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleCancelClick = () => {
    setAirTank(initialPressure);
    setMinSpeed(initialMinSpeed);
    setMinSpeedError("");
  };

  const onMinSpeedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (Number.isNaN(value)) return;
    setMinSpeed(value);
    if (value < airTankMinSpeed || value > airTankMaxSpeed) {
      setMinSpeedError(
        `Number must be between ${airTankMinSpeed} and ${airTankMaxSpeed}.`
      );
    } else {
      setMinSpeedError("");
    }
  };

  const isLightTheme = theme?.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
  };

  return (
    <>
      <Grid container className="mb-12">
        <Grid item xs={12} data-testid="asset-settings-brakes-airtank">
          <Typography className="assetSettingsSectionTitle">
            Air Tank Settings
          </Typography>
          <Box className="pb-6" data-testid="input-min-speed">
            <FormControl sx={{ width: "100%" }}>
              <WithAsterisk>
                <Label htmlFor="Min Speed">Min Speed</Label>
              </WithAsterisk>
              <Input
                value={minSpeed}
                onChange={onMinSpeedChange}
                data-testid="airtank-settings-min-speed-input"
                disabled={isLoading}
                sx={{ width: "100%", mb: "1px" }}
              />
              {!!minSpeedError && (
                <FormHelperText
                  data-testid="airtank-settings-min-speed-error-text"
                  error={true}
                >
                  {minSpeedError}
                </FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box className="mb-16">
            <Typography className="assetSettingsSectionTitle">
              Tank Pressure Settings
            </Typography>
            {isLightTheme ? (
              <AirtankSensorDark style={svgIconSettings} />
            ) : (
              <AirtankSensorLight style={svgIconSettings} />
            )}
          </Box>
          <SensorSlider
            values={airTank}
            min={minAirTankConverted}
            max={maxAirTankConverted}
            marks={sliderMarks(
              minAirTankConverted,
              maxAirTankConverted,
              getPressureUnitLabel(pressureUnit)
            )}
            disabled={!isUserAllowed || isLoading}
            onChange={setAirTank}
          />
        </Grid>
      </Grid>
      {isUserAllowed && (
        <SaveCancelButtons
          handleCancelClick={handleCancelClick}
          handleSubmitClick={handleFormSubmit}
          isLoading={isLoading}
          areButtonsDisabled={!isAnyValueDirty || isLoading}
          isUserAllowed={isUserAllowed}
        />
      )}
      <Spinner counter={Number(isLoading)} />
    </>
  );
};
