import React from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Box } from "@mui/material";
import { AutomationActions } from "./AutomationActions";
import { AutomationQueryBuilder } from "./AutomationQueryBuilder";
import { AutomationTrigger } from "./AutomationTrigger";
import { AutomationInitialValues } from "./hooks/useAutomationForm";

interface AutomationFormProps {
  form: UseFormReturn<Partial<AutomationInitialValues>>;
  onSubmit?: (data: FieldValues) => void;
  isTemplate: boolean;
  disabled?: boolean;
}

export const AutomationForm = ({
  onSubmit,
  form,
  isTemplate,
  disabled = false,
}: AutomationFormProps) => {
  return (
    <form
      autoComplete="off"
      onSubmit={onSubmit && !disabled ? form.handleSubmit(onSubmit) : undefined}
    >
      <Box className="pb-4 px-16">
        <AutomationTrigger
          form={form}
          isTemplate={isTemplate}
          disabled={disabled}
        />
        <AutomationQueryBuilder form={form} disabled={disabled} />
        <AutomationActions form={form} disabled={disabled} />
      </Box>
    </form>
  );
};
