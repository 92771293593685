import React, { FC, memo } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { FormControl, Typography } from "@mui/material";
import { EmailsSelect, EmailsSelectProps } from "../../EmailsSelect";
import { RequiredFlag } from "../../RequiredFlag";

export type EmailsSelectElementProps = Pick<EmailsSelectProps, "loading"> & {
  label?: string;
  rules?: ControllerProps["rules"];
  name: string;
  control: Control<any>;
  labelClassName?: string;
  required?: boolean;
  compact?: boolean;
  disabled?: boolean;
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
};

export const EmailsSelectElement: FC<EmailsSelectElementProps> = memo(
  ({
    label,
    rules,
    name,
    control,
    labelClassName = "!mb-2 !text-sm !font-bold",
    required,
    compact = false,
    disabled = false,
    handleUpdateField,
    fieldPath,
    loading,
    ...rest
  }: EmailsSelectElementProps) => {
    return (
      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({
          field: { value, onChange },
          fieldState: { error, invalid },
        }) => (
          <FormControl
            fullWidth
            required={required}
            variant="standard"
            error={invalid}
          >
            {label && (
              <Typography
                className={`${labelClassName} ${
                  disabled ? "text-light-charcoal" : ""
                }`}
                data-testid="subscribers-select-element-label"
              >
                {label}
                {rules?.required && <RequiredFlag />}
              </Typography>
            )}
            <EmailsSelect
              {...rest}
              values={value}
              onChange={onChange}
              loading={loading}
              compact={compact}
              disabled={disabled}
              handleUpdateField={handleUpdateField}
              fieldPath={fieldPath}
              error={error?.message}
            />
          </FormControl>
        )}
      />
    );
  }
);
