import axios, { AxiosError } from "axios";
import { env } from "../../../env";

export type SSOProviders = {
  CreationDate: string;
  LastModifiedDate: string;
  ProviderName: string;
  ProviderType: string;
};

export type SSOProvidersResponseType = {
  $metadata: {
    httpStatusCode: number;
    requestId: string;
    attempts: number;
    totalRetryDelay: number;
  };
  Providers: SSOProviders[];
};

// request Cognito OAuth end point to exchange the authorization code
// returned by SSO the identity provider for access token
export const fetchTokensForSSO = async (authorizationCode: string) => {
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("client_id", env.REACT_APP_COGNITO_CLIENT_ID);
  params.append(
    "redirect_uri",
    env.REACT_APP_SSO_SUCCESSFUL_LOGIN_REDIRECT_URI
  );
  params.append("code", authorizationCode);

  const response = await fetch(`${env.REACT_APP_COGNITO_DOMAIN}/oauth2/token`, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: params.toString(),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch tokens");
  }

  return await response.json(); // Contains id_token, access_token, refresh_token
};

// get all available configured Cognito SSO Providers
export const getSSOProviders = async (): Promise<SSOProvidersResponseType> => {
  let ssoProvidersData: SSOProvidersResponseType =
    {} as SSOProvidersResponseType;
  try {
    const response = await axios.get(
      `https://${env.REACT_APP_CONNECT1_PUBLIC_API_ID}.execute-api.${env.REACT_APP_REGION}.amazonaws.com/public/ssoProviders`,
      { withCredentials: false }
    );

    ssoProvidersData = response.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      console.error(`Error getting SSO Providers data.`);
      console.error(e.toJSON(), null, 2);
    } else if (e instanceof Error) {
      console.error(e.message);
    }
  }

  return ssoProvidersData;
};

// When the user try to login through SSO, he will be redirected to a Cognito URL which
// will redierct him to the SSO Provider Login page
// where should login with his credentials
export const redirectToSSOIdentityProviderLogin = (
  identityProviderName: string
) => {
  const loginUrl = `${
    env.REACT_APP_COGNITO_DOMAIN
  }/oauth2/authorize?response_type=code&client_id=${
    env.REACT_APP_COGNITO_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(
    env.REACT_APP_SSO_SUCCESSFUL_LOGIN_REDIRECT_URI
  )}&scope=email+openid+profile&identity_provider=${identityProviderName}`;

  window.location.href = loginUrl;
};
