import { UseMutateAsyncFunction } from "@tanstack/react-query";
import {
  CreateAssetInput,
  CreateAssetOsMutation,
  Exact,
  useCreateAssetOsMutation,
} from "../../../graphql/operations";

interface CreateAssetHook {
  isLoading: boolean;
  mutateAsync: CreateAssetOSMutationInput;
}

type CreateAssetOSMutationInput = UseMutateAsyncFunction<
  CreateAssetOsMutation,
  unknown,
  Exact<{ asset: CreateAssetInput }>,
  unknown
>;

export const useCreateAsset = ({
  onSuccessCallback = () => undefined,
  onErrorCallback = () => undefined,
}: {
  onSuccessCallback?: (
    data: CreateAssetOsMutation,
    variables: Exact<{ asset: CreateAssetInput }>,
    context: unknown
  ) => Promise<unknown> | undefined | void;
  onErrorCallback?: (
    error: unknown,
    variables: Exact<{ asset: CreateAssetInput }>,
    context: unknown
  ) => Promise<unknown> | undefined | void;
}): CreateAssetHook => {
  const mutation = useCreateAssetOsMutation({
    onSuccess: onSuccessCallback,
    onError: onErrorCallback,
  });

  return {
    isLoading: mutation.isLoading,
    mutateAsync: mutation.mutateAsync,
  };
};
