import { useState, useEffect, useMemo, FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as CameraSensorDark } from "../../../../../../assets/svgs/cameraSensorDark.svg";
import { ReactComponent as CameraSensorLight } from "../../../../../../assets/svgs/cameraSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useGetSensorProfileNamesWithConfigurationQuery,
  SensorProfileType,
  useAssignOrgProfileToAssetsMutation,
  useSetAssetSensorProfilesMutation,
  SetAssetSensorProfilesMutation,
  AssetSensorProfilesResponseStatus,
  SetAssetSensorProfilesInput,
  SensorProfileConfigType,
  AssignOrgProfileToAssetsMutation,
  AssignOrgProfileToAssetsInput,
  AssignOrgProfileToAssetsStatus,
  AssetWithSensors,
  SetAssetSensorProfilesResponse,
  AssignOrgProfileToAssetsResponse,
  ProfileConfigProperty,
  SensorThresholdShort,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import SensorSlider from "../../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractReversedRules,
  prepareReversedPayload,
  cargoCameraDefaultValues,
} from "../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../../shared/helpers/battery";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import {
  analyzeResponse,
  handleAnalyzedResponse,
  SensorProfileDropdownItem,
  sortSensorProfileNameDropdownItems,
} from "../../sensorsUtils";
import SelectedRecordInfo from "../../shared/SelectedRecordsInfo";
import ConfigurationDialogAdditionalElement from "../Components/CheckboxComponent";
import SensorProfileAssignmentForm from "../Components/SensorProfileAssignmentForm";
import {
  CARGO_CAMERA_FORM_FIELDS,
  cargoCameraSensorSchema,
} from "./CargoCameraDrawerUtils";

interface CargoCameraSensorDrawerProps {
  currentOrgId?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRecordsData?: AssetWithSensors[];
}

const CargoCameraSensorDrawer: FC<CargoCameraSensorDrawerProps> = ({
  open,
  setOpen,
  selectedRecordsData,
  currentOrgId,
}) => {
  const queryClient = useQueryClient();
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };

  let defaultCameraSensorProfile = undefined;
  let defaultTypeOfAssignment = "custom";
  const camera =
    selectedRecordsData?.[0]?.sensorProfile?.configuration?.cargoCamera;
  const cameraThresholds = selectedRecordsData?.[0]?.cargoCameraSensors;
  const isPresetProfile = camera?.type !== SensorProfileType.Asset;
  if (camera?.profileName && isPresetProfile) {
    defaultTypeOfAssignment = "profile";
    defaultCameraSensorProfile = camera?.profileId;
  }

  // extract values for sliders
  const min = MinValuesBySensorType.cargoCamera;
  const max = MaxValuesBySensorType.cargoCamera;
  const initialFloorSpace =
    extractReversedRules(cameraThresholds?.floorUsagePercentage) ||
    cargoCameraDefaultValues;
  const initialCubeSpace =
    extractReversedRules(cameraThresholds?.cubeUsagePercentage) ||
    cargoCameraDefaultValues;

  // state
  const [cubeSpace, setCubeSpace] = useState(initialCubeSpace);
  const [floorSpace, setFloorSpace] = useState(initialFloorSpace);

  const [
    isConfirmProfileAssignmentVisible,
    setIsConfirmProfileAssignmentVisible,
  ] = useState(false);
  const [isTypeOfAssignmentProfile, setIsTypeOfAssignmentProfile] =
    useState(false);
  const [profileInput, setProfileInput] = useState<
    AssignOrgProfileToAssetsInput | undefined
  >(undefined);
  const [dontShowConfirmationDialog, setDontShowConfirmationDialog] =
    useState(false);
  const [selectedProfileLabel, setSelectedProfileLabel] = useState<string>("");

  const [selectedAssetsImeis, setSelectedAssetsImeis] = useState<string[]>([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState<string[]>([]);
  const [selectedAssetsIds, setSelectedAssetsIds] = useState<string[]>([]);

  // effects
  useEffect(() => {
    if (isPresetProfile) {
      setIsTypeOfAssignmentProfile(true);
    }
    const isDontShowAgain =
      localStorage.getItem("setProfileConformation") === "true";
    setDontShowConfirmationDialog(isDontShowAgain);
  }, [selectedRecordsData, isPresetProfile]);

  useEffect(() => {
    const newSelectedAssetsImeis = (selectedRecordsData ?? [])
      .map((obj) => obj?.imei)
      .filter((imei): imei is string => !!imei);

    const newSelectedRowsIds = (selectedRecordsData ?? [])
      .map((obj) => obj?._id)
      .filter((id): id is string => !!id);

    const newSelectedAssetsIds = (selectedRecordsData ?? [])
      .map((obj) => obj?.asset_id)
      .filter((id): id is string => !!id);

    setSelectedAssetsImeis(newSelectedAssetsImeis);
    setSelectedRowsIds(newSelectedRowsIds);
    setSelectedAssetsIds(newSelectedAssetsIds);
  }, [selectedRecordsData]);

  // GraphQL
  const { data: sensorProfilesData, isLoading: isSensorProfileNamesLoading } =
    useGetSensorProfileNamesWithConfigurationQuery(
      {
        input: {
          orgId: currentOrgId ?? "",
          type: SensorProfileType.Organization,
          includeDefaultPctProfile: true,
          mergeProfilesWithDefaultPctProfile: true,
          sensorType: SensorProfileConfigType.CargoCamera,
        },
      },
      {
        enabled: Boolean(currentOrgId),
      }
    );

  const { mutate: mutateProfile, isLoading: isLoadingProfile } =
    useAssignOrgProfileToAssetsMutation({
      onSuccess: async (data: AssignOrgProfileToAssetsMutation) => {
        const response = analyzeResponse(
          data?.assignOrgProfileToAssets as AssignOrgProfileToAssetsResponse[],
          AssignOrgProfileToAssetsStatus.Fulfilled
        );
        handleAnalyzedResponse(
          response,
          dispatchErrorMessage,
          dispatchSuccessMessage,
          queryClient
        );
        setOpen(false);
      },
    });

  const { mutate: mutateCustomProfile, isLoading: isLoadingCustomProfile } =
    useSetAssetSensorProfilesMutation({
      onSuccess: async (data: SetAssetSensorProfilesMutation) => {
        const response = analyzeResponse(
          data?.setAssetSensorProfiles as SetAssetSensorProfilesResponse[],
          AssetSensorProfilesResponseStatus.Success
        );
        handleAnalyzedResponse(
          response,
          dispatchErrorMessage,
          dispatchSuccessMessage,
          queryClient
        );
        setOpen(false);
      },
    });

  const handleConfirmDialog = (confirmed: boolean) => {
    setIsConfirmProfileAssignmentVisible((prev) => !prev);
    if (!confirmed) {
      return;
    }
    if (dontShowConfirmationDialog) {
      localStorage.setItem("setProfileConformation", "true");
    }
    if (profileInput) {
      mutateProfile({ input: profileInput });
    }
  };

  const sensorProfilesForOrganization = useMemo(
    () => sensorProfilesData?.getSensorProfilesForOrganization ?? [],
    [sensorProfilesData]
  );

  const cameraSensorProfiles: SensorProfileDropdownItem[] =
    sensorProfilesForOrganization.map((item: any) => ({
      label: item.name,
      value: item._id,
      id: item._id,
      configuration: item.configuration,
      type: item.type,
    }));

  // set default values and resolver to the main form
  const mainForm = useForm({
    resolver: yupResolver(cargoCameraSensorSchema),
    defaultValues: {
      typeOfAssignment: defaultTypeOfAssignment,
      cameraSensorProfile: defaultCameraSensorProfile,
    },
  });

  CARGO_CAMERA_FORM_FIELDS[1].options =
    sortSensorProfileNameDropdownItems(cameraSensorProfiles);

  const dispatchSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        text: "Sensor(s) Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Settings Uploading Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const onSubmit = async () => {
    // type of Assignment is not profile submit else ask for confirmation
    if (!isTypeOfAssignmentProfile) {
      const input: SetAssetSensorProfilesInput = {
        selectedImeis: selectedAssetsImeis,
        orgId: currentOrgId ?? "",
        sensors: {
          cargoCamera: {
            floorUsagePercentage: prepareReversedPayload(
              ProfileConfigProperty.SupplyPressure,
              floorSpace,
              min,
              max
            ),
            cubeUsagePercentage: prepareReversedPayload(
              ProfileConfigProperty.SupplyPressure,
              cubeSpace,
              min,
              max
            ),
          },
        },
      };

      mutateCustomProfile({ input });
    } else {
      const selectedProfile = mainForm.getValues().cameraSensorProfile;
      const input: AssignOrgProfileToAssetsInput = {
        assetIds: selectedRowsIds,
        profileId: selectedProfile ?? "",
      };
      // if we have dontShowConfirmationDialog again clicked and submitted to local storage,
      // don't show confirmation dialog
      if (dontShowConfirmationDialog) {
        mutateProfile({ input });
      } else {
        setProfileInput(input);
        setIsConfirmProfileAssignmentVisible((prev) => !prev);
      }
    }
  };

  const handleSensorProfileInputChange = (selectedProfile: {
    configuration: {
      cargoCamera: {
        floorUsagePercentage: SensorThresholdShort;
        cubeUsagePercentage: SensorThresholdShort;
      };
    };
    label: string;
  }) => {
    const floorSettings =
      extractReversedRules(
        selectedProfile?.configuration?.cargoCamera?.floorUsagePercentage
      ) || cargoCameraDefaultValues;
    const cubeSettings =
      extractReversedRules(
        selectedProfile?.configuration?.cargoCamera?.cubeUsagePercentage
      ) || cargoCameraDefaultValues;

    setFloorSpace(floorSettings);
    setCubeSpace(cubeSettings);
    setSelectedProfileLabel(selectedProfile?.label ?? "");
  };

  // handle change of Type of Assignment - show/hide of Camera Sensor question
  const handleAssignmentTypeInputChange = (e: {
    name: string;
    value: string;
  }) => {
    const value = e?.value ?? undefined;
    if (!value) return;

    setIsTypeOfAssignmentProfile(value === "profile");

    // reset to default values if custom is selected after being preselected
    if (!isPresetProfile && value === "custom") {
      setFloorSpace(initialFloorSpace);
      setCubeSpace(initialCubeSpace);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxClick = () => {
    setDontShowConfirmationDialog((prev) => !prev);
  };

  const isAnySliderDirty =
    floorSpace.toString() !== initialFloorSpace.toString() ||
    cubeSpace.toString() !== initialCubeSpace.toString();
  const { isDirty, isValid } = mainForm.formState;
  const isLoading = isLoadingProfile || isLoadingCustomProfile;
  const isCustomAndPristine =
    !isPresetProfile && !isTypeOfAssignmentProfile && !isAnySliderDirty;
  const isProfileAndPristine = isPresetProfile && !isDirty;
  const isProfileAndInvalid = isTypeOfAssignmentProfile && !isValid;

  const additionalCheckboxComponent = (
    <ConfigurationDialogAdditionalElement
      checked={dontShowConfirmationDialog}
      onChange={handleCheckboxClick}
    />
  );

  return (
    <Drawer
      testId="camera-sensor-drawer"
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DrawerHeader text="Edit Sensor" onClose={handleClose} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box className="mb-9" data-testid="sensors-drawer-cargo-camera">
            {selectedRecordsData && (
              <SelectedRecordInfo selectedRecordsData={selectedRecordsData} />
            )}

            <Box className="pt-6">
              <Grid
                container
                spacing={{ xs: 1, md: 2 }}
                className="justify-left px-6"
              ></Grid>
              <SensorProfileAssignmentForm
                isTypeOfAssignmentProfile={isTypeOfAssignmentProfile}
                form={mainForm}
                SENSOR_FORM_FIELDS={CARGO_CAMERA_FORM_FIELDS}
                handleAssignmentTypeInputChange={
                  handleAssignmentTypeInputChange
                }
                handleSensorProfileInputChange={handleSensorProfileInputChange}
                isProfileDataLoading={isSensorProfileNamesLoading}
              />

              <Box className="px-6" data-testid="sensors-camera-floor">
                <Box className="mb-16">
                  <Typography sx={{ fontWeight: "bold" }}>
                    Floor Space Settings
                  </Typography>
                  {isLightTheme ? (
                    <CameraSensorDark style={svgIconSettings} />
                  ) : (
                    <CameraSensorLight style={svgIconSettings} />
                  )}
                </Box>
                <SensorSlider
                  values={floorSpace}
                  min={min}
                  max={max}
                  marks={sliderMarks(min, max, "%")}
                  disabled={isTypeOfAssignmentProfile || isLoading}
                  reversed
                  onChange={setFloorSpace}
                />
              </Box>

              <Box className="mt-12 px-6" data-testid="sensors-camera-cube">
                <Box className="mb-16">
                  <Typography sx={{ fontWeight: "bold" }}>
                    Volume Settings
                  </Typography>
                  {isLightTheme ? (
                    <CameraSensorDark
                      style={svgIconSettings}
                      data-testid="temperature-profile-drawer-thermostat"
                    />
                  ) : (
                    <CameraSensorLight
                      style={svgIconSettings}
                      data-testid="temperature-profile-drawer-thermostat"
                    />
                  )}
                </Box>
                <SensorSlider
                  values={cubeSpace}
                  min={min}
                  max={max}
                  marks={sliderMarks(min, max, "%")}
                  disabled={isTypeOfAssignmentProfile || isLoading}
                  reversed
                  onChange={setCubeSpace}
                />
              </Box>
            </Box>
          </Box>

          <DrawerActions disabled={isLoading} onCancel={handleClose} />
        </Box>

        {isTypeOfAssignmentProfile && isConfirmProfileAssignmentVisible && (
          <ConfirmationDialog
            title="Profile Assignment"
            message={`Warning, all of the sensor settings of profile ${selectedProfileLabel}
             will be applied to asset(s) ${selectedAssetsIds.join(
               ", "
             )}. Any custom sensor settings will be lost!`}
            open={isConfirmProfileAssignmentVisible}
            confirmButtonText={"Confirm"}
            cancelButtonText="Cancel"
            handleConfirmationResult={handleConfirmDialog}
            additionalContent={additionalCheckboxComponent}
          />
        )}
      </DrawerContent>

      <DrawerFooter
        text={isLoading ? "Saving..." : "Apply to Selected"}
        disabled={
          isLoading ||
          isCustomAndPristine ||
          isProfileAndPristine ||
          isProfileAndInvalid
        }
        testId="btn-camera-sensor-form-submit"
        submit={() => onSubmit()}
      />
    </Drawer>
  );
};

export default CargoCameraSensorDrawer;
