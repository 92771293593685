import { Link, Typography } from "@mui/material";
import { Box, ThemeProvider } from "@mui/system";
import { useAuthContext } from "../../../context/AuthContext";
import { Button } from "../../../shared/components/Button";
import { AuthenticationBox } from "../components/AuthenticationBox";
import { useAuthFormTheme } from "../hooks/useAuthFormTheme";
import { useAuthenticationViewBrand } from "../hooks/useAuthenticationViewBrand";
import { redirectToSSOIdentityProviderLogin } from "./utils";

const SSOSelection: React.FC = () => {
  const { ssoProviders } = useAuthContext();
  const formTheme = useAuthFormTheme();
  const { brand } = useAuthenticationViewBrand();

  return (
    <>
      <AuthenticationBox>
        <ThemeProvider theme={formTheme}>
          <Box
            sx={{
              fontWeight: 500,
              minWidth: "235px",
              textAlign: "center",
            }}
          >
            Supported SSOs
          </Box>
          <Box
            sx={{
              padding: "10px",
            }}
          >
            {ssoProviders.map((provider, index) => (
              <Box
                sx={{
                  paddingTop: "10px",
                  minWidth: "235px",
                }}
                key={index}
              >
                <Button
                  dataTestid={`sso-provider-button-${provider.ProviderName}`}
                  text={provider.ProviderName}
                  type="button"
                  onClick={() => {
                    redirectToSSOIdentityProviderLogin(provider.ProviderName);
                  }}
                  sx={{
                    width: "100%",
                    minWidth: "235px",
                    fontSize: "14px !important",
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "10px",
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >
              Customer Support: {brand?.supportPhoneNumber}
            </Typography>
            <Link
              href={`mailto: ${brand?.supportEmail}`}
              sx={{
                textAlign: "center",
                fontSize: "10px",
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >
              {brand?.supportEmail}
            </Link>
          </Box>
        </ThemeProvider>
      </AuthenticationBox>
    </>
  );
};
export default SSOSelection;
