import React, { FC, memo } from "react";
import {
  Control,
  Controller,
  ControllerProps,
  FieldValues,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { CheckboxElement } from "react-hook-form-mui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Select,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { UserData } from "../../../../graphql/operations";
import { ChipsList } from "../../ChipsList";
import { CustomTooltip } from "../../CustomTooltip/CustomTooltip";
import { RequiredFlag } from "../../RequiredFlag";
import { UsersSelect, UsersSelectProps } from "../../UsersSelect";
import { ChipOption } from "../HierarchySelectElement";

export type UsersSelectElementProps = Pick<UsersSelectProps, "loading"> & {
  label?: string;
  rules?: ControllerProps["rules"];
  name: string;
  control: Control<any>;
  labelClassName?: string;
  required?: boolean;
  usersData: UserData[];
  form: UseFormReturn<FieldValues>;
  compact?: boolean;
  disabled?: boolean;
  users: ChipOption[];
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
  isEscalation?: boolean;
  checkboxName?: string;
  checkboxLabel?: React.ReactNode;
};

export const UsersSelectElement: FC<UsersSelectElementProps> = memo(
  ({
    label,
    rules,
    name,
    control,
    labelClassName = "!mb-2 !text-sm !font-bold",
    required,
    usersData,
    form,
    users,
    compact = false,
    disabled = false,
    handleUpdateField,
    fieldPath,
    isEscalation,
    checkboxName,
    checkboxLabel,
    ...rest
  }: UsersSelectElementProps) => {
    const usersList = useWatch({
      name: name,
      control: form.control,
    });

    const handleDelete = (chip: string) => {
      const filteredUsers = usersList.filter((user: string) => user !== chip);
      handleUpdateField?.(filteredUsers, fieldPath);
      form.setValue(name, filteredUsers);
    };

    return (
      <>
        <Controller
          rules={rules}
          name={name}
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error, invalid },
          }) => (
            <FormControl
              fullWidth
              required={required}
              variant="standard"
              error={invalid}
            >
              {label && (
                <Typography
                  className={`${labelClassName} ${
                    disabled ? "text-light-charcoal" : ""
                  }`}
                  data-testid="users-select-element-label"
                >
                  {label}
                  {rules?.required && <RequiredFlag />}
                  {isEscalation && (
                    <span
                      className="inline-block ml-2"
                      data-testid="users-select-element-info"
                    >
                      <CustomTooltip
                        title={
                          <Typography variant="caption">
                            E-mail is the default notification method.
                            <br />
                            Ticking the SMS box will enable both e-mails and SMS
                            notifications
                          </Typography>
                        }
                        arrow
                      >
                        <InfoOutlinedIcon
                          fontSize="small"
                          className="text-light-charcoal"
                        />
                      </CustomTooltip>
                    </span>
                  )}
                </Typography>
              )}
              {!rest.loading ? (
                <UsersSelect
                  {...rest}
                  values={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  invalid={invalid}
                  required={required}
                  usersData={usersData}
                  disabled={disabled}
                  handleUpdateField={handleUpdateField}
                  fieldPath={fieldPath}
                />
              ) : (
                <Box>
                  <Box
                    className="absolute bottom-0 right-6"
                    data-testid="users-select-loader"
                  >
                    {rest.loading && <CircularProgress size={20} />}
                  </Box>
                  <Select fullWidth disabled value={""} />
                </Box>
              )}

              {error?.message && (
                <FormHelperText error={true}>{error?.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
        {!!checkboxName && (
          <Box>
            <CheckboxElement
              data-testid="users-select-element-checkbox"
              name={checkboxName}
              control={form.control}
              label={checkboxLabel}
              disabled={disabled}
            />
          </Box>
        )}
        <ChipsList
          selectedChips={usersList ?? []}
          chips={users}
          handleDelete={handleDelete}
          loading={rest.loading}
          compact={compact}
          icon={
            isEscalation ? (
              <Tooltip title="This user didn’t allow to send SMS.">
                <InfoOutlinedIcon fontSize="small" className="!text-error" />
              </Tooltip>
            ) : undefined
          }
        />
      </>
    );
  }
);
