import { FC } from "react";
import { Paper } from "@mui/material";
import { useInternalCameraSensorContext } from "../context";
import { useUsedSpaceIndicatorMode } from "../hooks/useUsedSpaceIndicatorMode";
import { MediaActivityItem } from "../interface";
import UsedSpaceIndicator from "./UsedSpaceIndicator";

export interface InternalCameraStatusCarouselItemImageProps {
  item: MediaActivityItem;
}

const CarouselItemImage: FC<InternalCameraStatusCarouselItemImageProps> = ({
  item,
}) => {
  const { setMediaActivityModalIsOpen, setMediaActivityItem, activeView } =
    useInternalCameraSensorContext();
  const { usedSpaceIndicatorMode } = useUsedSpaceIndicatorMode(activeView);

  const handleMediaActivityItemClick = () => {
    setMediaActivityItem(item);
    setMediaActivityModalIsOpen(true);
  };

  return (
    <Paper
      className="relative w-full h-full !rounded-lg"
      data-testid="internal-camera-status--item-image"
      onClick={handleMediaActivityItemClick}
      sx={{
        boxShadow: "none",
        backgroundColor: "var(--asset-image-fallback-background)",
      }}
    >
      <img
        src={item.imageUrl}
        alt={item.address}
        className="w-full h-full object-cover !rounded-lg cursor-pointer"
        style={{ minHeight: "120px" }}
      />

      <div className="absolute top-4 right-4">
        <UsedSpaceIndicator
          title="Volume"
          value={item.cubeUsagePercentage}
          mode={usedSpaceIndicatorMode}
        />
        <UsedSpaceIndicator
          title="Floor Space"
          value={item.floorUsagePercentage}
          mode={usedSpaceIndicatorMode}
        />
      </div>
    </Paper>
  );
};

export default CarouselItemImage;
